import React, {useState} from "react";
import {
    InputBase,
    MenuItem,
    Select,
    makeStyles,
    withStyles,
    ListSubheader,
    InputAdornment
} from "@material-ui/core";
import { searchIcon } from "./AddDealIcons";

const useStyles = makeStyles((theme) => ({
    menuDropdown: {
        "& .MuiListSubheader-root": {
            paddingTop: "12px",
            backgroundColor: "#fff",
        },
        "& .MuiMenuItem-root": {
            padding: "10px 20px",
        },
        "& .MuiList-root": {
            padding: "5px 0px",
        },
    },
    menuItem: {
        "&:hover": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
        "&.Mui-selected": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
    },
    menuItemLineThrough: {
        "&.MuiListItem-root": {
            textDecoration: "line-through",
            color: "#ee1b54",
        },
    },
    deleted: {
        fontSize: "9px"
    },
    emptySearchResults: {
        textAlign: "center",
        fontSize:"16px",
        width:"100%",
        padding:"30px 20px !important",
        pointerEvents: "none !important",
    },

}));
const BasicInputForSearch = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "&.MuiInputBase-multiline": {
            padding: "0 !important",
        },
        "& .MuiInputAdornment-positionEnd": {
            position: "absolute",
            right: "12px"
        },
        "& .MuiInputAdornment-positionStart": {
            position: "absolute",
            left: "-10px"
        },
      
    },
    input: {
        position: "relative",
        backgroundColor: "#f2f2ff !important",
        fontSize: "15px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        padding: "10px 12px  !important",
        borderRadius: "4px !important",
        border: "1px solid #3e50f7 !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
        },

        "&.MuiSelect-select": {
            height: "1rem !important",
        },
        "&::placeholder": {
            color: "#082852 !important",
        },
    },
}))(InputBase);

const BasicInputSelect = withStyles((theme) => ({
    root: {
        Width:"100%",
    },
    input: {
        backgroundColor: "white !important",
        position: "relative",
        fontSize: "15px !important",
        margin: "0 !important",
        padding: " 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
            backgroundColor: "#fff !important"
        },
        "&::placeholder": {
            color: "#082852 !important",
        },

    },
}))(InputBase);

const BasicSelectWithSearch = ({ options, onChange, value = "",placeholderText="Search...", disabled = false, selectedOption, defaultText = "Select an option", mapping = { label: "label", value: "value",minWidth : "500px" } }) => {
    const styles = useStyles();
    const placeholderColor = "rgb(8 40 82 / 44%)";
    const activeColor = "#36454F";
    const [search, setSearch] = useState("");

    const handleSelectChange = (event) => {
        onChange(event);

        if (selectedOption) {
            const selected = options.find((option) => (option[mapping.value] === event.target.value));
            if (selected) {
                selectedOption(selected);
            }
        }
    };

    const filter = () => {
        if (search === "") {
            return options;
        }

        const regex = new RegExp(search, "i");

        const results = [];

        options.forEach(dItem => {
            if (regex.test(dItem[mapping.label])) {
                results.push(dItem);
            }
        });

        return results;
    }


    const menuItems = () => {
        let searchedOptions = filter();
        
        if (searchedOptions.length === 0) {
            return (
                <MenuItem disable className={styles.emptySearchResults}>
                    No data matches
                </MenuItem>
            );
        }

        return searchedOptions.map((subOption, subIndex) => {
            return (
                <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} key={`sub-${subIndex}`} value={subOption[mapping.value]}>
                    {subOption[mapping.label]} 
                </MenuItem>
            );
        });
    };

    return (
        <Select
            fullWidth={true}
            defaultValue={value}
            value={value}
            onChange={handleSelectChange}
            displayEmpty
            disabled={disabled}
            onClose={()=>{ setTimeout(()=>{setSearch("");}, 100) }}
            input={<BasicInputSelect style={{ color: `${!value ? placeholderColor : activeColor}` }} selectedOption={selectedOption} />}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                PaperProps: {
                    style: {
                        maxHeight: 300,
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 transparent",
                    },
                    className: styles.menuDropdown
                },
                getContentAnchorEl: null,
            }}
        >
            <ListSubheader
                onClick={(event) => {
                    event.stopPropagation();
                }}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            >
                <BasicInputForSearch
                    size="small"
                    placeholder={placeholderText}
                    fullWidth
                    value={search}
                    endAdornment={
                        <InputAdornment position='end' style={{ paddingRight: "5px" }}>
                          <span>{searchIcon({ width: 20, height: 20 })}</span>
                        </InputAdornment>
                      }
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    onChange={(event)=>{
                        setSearch(event.target.value);
                    }}
                />
            </ListSubheader>
             <MenuItem style={{opacity:0,width:"0px",position:"absolute"}}className={`${styles.menuItem} dropdownhelper-menuitem-class`} value="" disabled>{defaultText}</MenuItem>
            {menuItems()}
        </Select>
    );
};

export default BasicSelectWithSearch;