import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {BasicInput} from "../../contact/leftSideV2/LeftSideV2Styles";
import BasicSelect from "../../common/BasicSelect";
import {useDispatch, useSelector} from "react-redux";
import {
    selectImportSpreadsheetContactState
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import useDebounce from "../../../hooks/useDebounce";
import useDidUpdateEffect from "../../../hooks/useDidUpdateEffect";
import {
    loadWorksheetsStart,
    updateDetails, updateMappedFields
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import importSpreadsheetContactService
    from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactService";
import mapCSVImportHelper from "../mapCSVImportHelper";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {HelpRounded} from "@material-ui/icons";
import HelpModal from "../../common/helpModal";
import SpreadsheetLinkHelperModalBody from "./SpreadsheetLinkHelperModalBody";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2),
    },
    headerTitle: {
        fontWeight: 600,
    },
    buttonGroup: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1.5),
    },
    scrollContainer: {
        maxHeight: "calc(100vh - 299px)",
        overflow: "auto",
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        position: "relative",
        "& .MuiBackdrop-root": {
            position: "absolute",
            borderRadius: "4px",
            zIndex: 1
        }
    },
    section: {
        padding: theme.spacing(4),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    sectionTitle: {
        marginBottom: "10px",
        textAlign: "left",
        fontWeight: "bold"
    },
    connectionPolicy: {
        marginTop: "30px"
    },
    sheetSelector: {
        marginTop: "15px"
    }
}));

export const extractFileId = (url) => {
    const regex = /\/d\/([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
};

const Configuration = ({ moveToNextStep, moveToPreviousStep }) => {
    const dispatch = useDispatch();
    const {
        loadingWorksheets,
        worksheets,
        details: {
            spreadsheetLink,
            email,
            worksheet,
            ignoreFirstRow
        }
    } = useSelector(selectImportSpreadsheetContactState);
    const classes = useStyles();
    const debouncedLink = useDebounce(spreadsheetLink, 500);

    useDidUpdateEffect(() => {
        if (extractFileId(spreadsheetLink)){
            dispatch(loadWorksheetsStart({ email: email, spreadsheetId: extractFileId(spreadsheetLink) }));
        }
    }, [debouncedLink]);

    const handleReadSpreadsheet = async () => {
        try{
            const spreadsheetInfosResp = await importSpreadsheetContactService.readSpreadsheet({ email: email, spreadsheetId: extractFileId(spreadsheetLink), worksheetName: worksheet });

            if (spreadsheetInfosResp?.success){
                const { mappedRows, unmappedRows, selectedRows } = mapCSVImportHelper.mapData(spreadsheetInfosResp?.data);

                dispatch(updateMappedFields({
                    mappedRows: mappedRows,
                    unmappedRows: unmappedRows,
                    mappedFields: selectedRows,
                    alreadyMappedFields: Object.values(selectedRows)
                }));
                moveToNextStep();
            }else {
                window.showNotification("error", "Failed to read spreadsheet");
            }
        }catch (err){
            console.log(err);
            window.showNotification("error", "Failed to read spreadsheet");
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography  style={{ height: "unset !important" }} variant="h5" color="textPrimary" className={classes.headerTitle}>
                    Now let’s connect to your Google sheet.
                </Typography>

                <div className={classes.buttonGroup}>
                    <Button style={{color:"#000"}}  variant="outlined" disableElevation onClick={moveToPreviousStep}>
                        Back
                    </Button>
                    <Button variant="contained" color="primary" disableElevation onClick={handleReadSpreadsheet}>
                        Next
                    </Button>
                </div>
            </div>

            <div className={classes.contentWrapper}>
                <div className={classes.section}>
                    <Typography  style={{ height: "unset !important", display: "flex", alignItems: "center", gap: "8px" }} variant="h6" className={classes.sectionTitle}>
                        For this step, you’ll need to provide the “link” associated with your Google sheet.

                        <HelpModal
                            HelpIcon={(props) => {
                                return (
                                    <HelpRounded
                                        {...props}
                                        style={{
                                            color: 'var(--gray)',
                                            cursor: 'pointer'
                                        }}
                                    />
                                )
                            }}
                            modalHeaderIcon={
                                <img
                                    src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/contacts/1.0.0/link_icon.png`}
                                    alt='logo'
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            }
                            modalHeaderTitle={"Where do you find the link?"}
                            backButtonText={"Exit"}
                        >
                            <SpreadsheetLinkHelperModalBody/>
                        </HelpModal>
                    </Typography>

                    <BasicInput
                        fullWidth
                        name="spreadsheetLink"
                        placeholder="Add your Google sheet “share” link here"
                        value={spreadsheetLink}
                        onChange={(event) => {
                            dispatch(updateDetails({ spreadsheetLink: event.target.value }));
                        }}
                    />

                    <BasicSelect
                        fullWidth
                        className={classes.sheetSelector}
                        name="spreadsheetId"
                        defaultText="Select a worksheet"
                        options={worksheets}
                        disabled={!worksheets[0]}
                        mapping={{ label: "title", value: "title" }}
                        value={worksheet}
                        onChange={(event)=>{
                            dispatch(updateDetails({ worksheet: event.target.value }));
                        }}
                        selectedOption={(data)=>{
                            dispatch(updateDetails({ workSheetInfo: data }))
                        }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                size='small'
                                color='primary'
                                sx={{ padding: 0 }}
                                checked={ignoreFirstRow}
                            />
                        }
                        label={
                            <Typography variant='subtitle2' ml={1} color={'textPrimary'} fontWeight={'bold'}>
                                Ignore first row
                            </Typography>
                        }
                        onChange={(_event, checked) => {
                            dispatch(updateDetails({ ignoreFirstRow: checked }));
                        }}
                    />
                </div>
                <Backdrop open={loadingWorksheets}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
        </div>
    )
};

export default Configuration;