import React, { useEffect, useState } from "react";
import { Divider, Drawer, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CustomScrollbar } from "../../importContactsV2/CustomScrollbar";
import {getImportSettingsDetails, getImportSpreadsheetSettingsDetails} from "../../../api/contactApi";
import Utils from "../../../helpers/Utils";
import {
  CONTACT_IMPORT_RULES,
  CONTACT_IMPORT_RULES_V2,
  DRIP_PROCESS_TYPE,
  VIEW_CSV_LABEL
} from "../../../constants/CoreConstants";
import DynamicSkeleton from "../../common/DynamicSkeleton";
import {DrawerContent, DrawerHeader} from "./styles";

const userTimezone = Utils.getAccountData("userTimezoneRaw");

const SettingDetailsDrawer = ({ userConnectedFileId, open, onClose }) => {
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSettingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettingDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getImportSpreadsheetSettingsDetails({ userConnectedFileId });

      if (response.success && response.data) {
        setSettings(response.data);
      } else {
        setSettings(null);
      }
    } catch (error) {
      console.error("Failed to load Setting Details:", error);
      setSettings(null);
      if (window.showNotification !== undefined) window.showNotification("ERROR", "Please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  const uploadInstruction = CONTACT_IMPORT_RULES_V2.find((rules) => rules.value === settings?.uploadInstruction)?.label;

  const tags = settings?.tags?.map((tag) => tag.name).join(", ");

  const date =
    settings?.batchStartDate &&
    window.globalTimezoneConversionToDifferentTimezone(
      settings.batchStartDate,
      "UTC",
      userTimezone,
      "MM-DD-YYYY",
      false
    );
  const time =
    settings?.batchStartDate &&
    window.globalTimezoneConversionToDifferentTimezone(settings.batchStartDate, "UTC", userTimezone, "h:mm A", false);

  const activeWeekDays = Object.keys(settings?.batchWeekends || {})
    .filter((day) => settings.batchWeekends[day])
    .join(", ");

  const batchProcessType = DRIP_PROCESS_TYPE[settings?.batchProcessType];

  const mappedEntries = Object.entries(settings?.mapping || {});

  const mappedColumn =
    mappedEntries.length > 0 ? (
      mappedEntries.map(([key, value]) => {
        const label = VIEW_CSV_LABEL[key] || key || "N/A";
        return <div key={key}>{label}</div>;
      })
    ) : (
      <div>N/A</div>
    );

  const renderContent = () => {
    if (isLoading) {
      return <DynamicSkeleton count={13} width='565px' />;
    } else if (!isLoading && settings === null) {
      return <div style={{ textAlign: "center", paddingBlock: "20px" }}>No data available</div>;
    } else if (!isLoading && settings !== null) {
      return (
        <DrawerContent>
          <CustomScrollbar className='scrollContainer'>
            <div className='contentWrapper'>
              <div className='contentItem'>
                <div className='contentTitle'>File Name:</div>
                <div className='contentDescription'>{settings.fileName}</div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Upload Instructions:</div>
                <div className='contentDescription'>{uploadInstruction || "N/A"}</div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Lead Source:</div>
                <div className='contentDescription'>{settings.leadSource || "N/A"}</div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Lead Owner:</div>
                <div className='contentDescription'>{settings.leadOwner || "N/A"}</div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Tag:</div>
                <div className='contentDescription'>{tags || "N/A"}</div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Smart List:</div>
                <div className='contentDescription'>{settings.smartList || "N/A"}</div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Deals Pipeline:</div>
                <div className='contentDescription'>
                  <div>Pipeline: {settings?.pipeline || "N/A"}</div>
                  <div>Stage: {settings?.stage || "N/A"}</div>
                </div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Campaign:</div>
                <div className='contentDescription'>{settings.campaign || settings.batchCampaign || "N/A"}</div>
              </div>
              <div className='contentItem'>
                <div className='contentTitle'>Process Mode:</div>
                <div className='contentDescription'>
                  {settings?.batchQuantity ? (
                    <>
                      <div>Drip</div>
                      <div>Start Date: {date || "N/A"}</div>
                      <div>Time: {time || "N/A"}</div>
                      <div>Batch Quantity: {settings.batchQuantity || "N/A"}</div>

                      {settings.batchRepeatAfter && (
                        <div>
                          Repeat After {settings.batchRepeatAfter} {batchProcessType}
                        </div>
                      )}

                      <div>Weekdays: {activeWeekDays || "N/A"}</div>
                    </>
                  ) : (
                    <div>Process all at once</div>
                  )}
                </div>
              </div>

              <div className='contentItem mapping'>
                <div className='contentTitle'>Mapped Column:</div>
                <div className='contentDescription'>
                  {mappedColumn}
                </div>
              </div>
            </div>
          </CustomScrollbar>
        </DrawerContent>
      );
    }
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <div style={{ width: 600 }}>
        <DrawerHeader>
          <div className='headerTitle'>Setting Details</div>
          <IconButton size={"small"} color={"error"} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider light />

        {renderContent()}
      </div>
    </Drawer>
  );
};

export default SettingDetailsDrawer;
