import AddListModal from "./AddListModal";
import { saveContactList } from "../../../../api/contactApi";

const CreateListModal = ({ open, onClose, contactListSelectedContactIds, contactListSelectedOperationList, contactListSelectedColumnFilterData, listAddCallback }) => {

  const handleConfirm = async (listTitle, listDescription) => {
    const params = {
      title: listTitle,
      description: listDescription,
      contactIds: contactListSelectedContactIds,
      operation: JSON.stringify(contactListSelectedOperationList),
      columnFilterData: JSON.stringify(contactListSelectedColumnFilterData),
      type: "new",
    };

    if (listTitle === "") {
      window.showNotification("ERROR", "No List Name");
      return;
    }
    try {
      const response = await saveContactList(params);
      if (response.data.status === "success") {
        window.showNotification("SUCCESS", "Create List successfully");
        listAddCallback(response.data.data);
        onClose();
      } else if (response.data.status === "validation-error") {
        window.showNotification("ERROR", response.data.message.title[0]);
      } else {
        window.showNotification("ERROR", "Something went wrong");
      }
    } catch (error) {
      console.error("API Error:", error);
      window.showNotification("ERROR", "Something went wrong");
    } finally {
    }
  };

  return (
    <AddListModal
      open={open}
      onClose={onClose}
      handleConfirm={handleConfirm}
      title="Create New Smart List"
      description="Selected Contacts will add in this Smart List."
      imgSrc={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/smart_list/1.0.0/smart_lists_icon.png`}
      closeButtonText="Cancel"
      confirmButtonText="Create List"
    />
  );
};

export default CreateListModal;