import {Box, styled} from "@material-ui/core";

export const DrawerHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",

    "& .headerTitle": {
        fontSize: "1.25rem",
        fontWeight: 500,
        lineHeight: 1.6,
    },
});

export const DrawerContent = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    //   padding: "16px 0",

    "& .contentItem": {
        padding: "8px 16px 8px 24px",
        display: "flex",

        color: "#757F8E",
        fontSize: 14,
        fontWeight: 500,
        borderBottom: "1px solid #1D29391A",

        "& .contentTitle": {
            width: 250,
        },

        "& .contentDescription": {
            width: "100%",
        },

        "&.mapping": {
            "& .contentDescription": {
                display: "flex",
                flexDirection: "column",
                gap: "4px",
            },
        },
    },

    "& .scrollContainer": {
        maxHeight: "calc(100vh - 65px)",
        overflowY: "auto",
    },
});