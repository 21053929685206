import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled
} from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CONTACT_IMPORT_RULES,
  HISTORY_STATE,
  PROCESS_MODE_VALUE,
  processModeOptions
} from "../../constants/CoreConstants";
import { selectImportContactState } from "../../reducers/importContact/importContactSelector";
import importContactService from "../../reducers/importContact/importContactService";
import {
  loadCampaignDataStart,
  loadInitialDataStart,
  loadPipelineDataStart,
  loadStageDataStart,
  updateAccordion,
  updateDetails,
  updateProcessMode, updateSummary
} from "../../reducers/importContact/importContactSlice";
import BasicSelect from "../common/BasicSelect";
import SwitchC from "../common/subComponents/Switch";
import { BasicInput } from "../contact/leftSideV2/LeftSideV2Styles";
import { CustomScrollbar } from "./CustomScrollbar";
import ListOrTagCreateModal from "./ListOrTagCreateModal";
import ProcessModeHelpModal from "./ProcessModeHelpModal";
import { importContactCsvStyle } from "./importContactCsvStyle";

const CustomRadio = styled(Radio)(() => ({
  '& .MuiSvgIcon-root': {
    fill: '#0000008a',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: '#3f51b5',
    },
  },
  '&:hover': {
    '& .MuiSvgIcon-root': {
      opacity: 1,
    },
  },
}));
const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiTypography-root': {
    fontSize: '14px',
    color: '#000000',
  },
}));

const Details = ({ moveToNextStep, moveToPreviousStep }) => {
  const [startOption, setStartOption] = useState('now');
  const dispatch = useDispatch();
  const {
    leadSources,
    teamUsers,
    tags,
    smartLists,
    pipelines,
    stages,
    campaigns,
    mappedFields,
    accordion: {
      isPipelineExpand,
      isCampaignExpand
    },
    details: {
      pipelineId,
      stageId,
      tagIds,
      leadOwnerId,
      smartListId,
      campaignId,
      powerDialerListId,
      leadSourceId,
      existingContactRule,
      fileName,
      userConsent,
      file,
      fileUrl
    },
    processMode: {
      dripMode,
      startDate,
      startTime,
      batchQuantity,
      repeatAfter,
      processType,
      weekDays
    },
    referer
  } = useSelector(selectImportContactState);
  const classes = importContactCsvStyle({ isPipelineExpand, isCampaignExpand });
  const [openProcessHelpModal, setOpenProcessHelpModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [openListCreateModal, setOpenListCreateModal] = useState(false);
  const [openTagCreateModal, setOpenTagCreateModal] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if ((dripMode === PROCESS_MODE_VALUE.DRIP || campaignId) && scrollContainerRef) {
      scrollContainerRef?.current?.scrollTo({ top: scrollContainerRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [dripMode, campaignId]);

  const handlePipelineExpand = (status) => {
    if (status) {
      dispatch(loadPipelineDataStart({ userId: leadOwnerId }));
    }
    dispatch(updateAccordion({ isPipelineExpand: status }));
  };

  const handleCampaignExpand = (status) => {
    if (status) {
      dispatch(loadCampaignDataStart({ userId: leadOwnerId }));
    }
    dispatch(updateAccordion({ isCampaignExpand: status }));
  };

  const handleUpdateFormData = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "tagIds") {
      value = value.filter(Boolean);
    }

    if (name === "pipelineId") {
      dispatch(loadStageDataStart({ pipelineId: value }));
    }

    if (name === "userConsent") {
      value = !!event.target.checked;
    }

    if (name === "leadOwnerId") {
      dispatch(loadInitialDataStart({ userId: value }));

      if (isCampaignExpand) {
        dispatch(loadCampaignDataStart({ userId: value }));
      }

      if (isPipelineExpand) {
        dispatch(loadPipelineDataStart({ userId: value }));
      }
    }

    dispatch(updateDetails({ [name]: value }));
  };

  const handleUpdateProcessMode = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    dispatch(updateProcessMode({ [name]: value }));
  };

  const handleWeekDays = (event) => {
    let name = event.target.name;

    dispatch(updateProcessMode({ weekDays: { ...weekDays, [name]: !weekDays[name] } }));
  };

  const handleSubmit = async () => {
    try {
      if (!userConsent) {
        window.showNotification("error", "User consent is required.");
        return;
      }

      if (!file && !fileUrl) {
        window.showNotification("error", "Either a file or fileUrl must be provided.");
        return;
      }

      if (!fileName) {
        window.showNotification("error", "File name is required.");
        return;
      }

      if (!existingContactRule) {
        window.showNotification("error", "Existing contact rule is required.");
        return;
      }

      if (!leadSourceId) {
        window.showNotification("error", "Source is required.");
        return;
      }

      if (isPipelineExpand) {
        if (!stageId) {
          window.showNotification("error", "Stage is required for pipeline expansion.");
          return;
        }
      }

      if (campaignId && (isCampaignExpand || referer === HISTORY_STATE.CSV_CAMPAIGN_REFERER)) {
        if (dripMode === "drip") {
          if (!startDate || !startTime) {
            window.showNotification("error", "Start date and time are required for drip mode.");
            return;
          }

          if (!batchQuantity) {
            window.showNotification("error", "Batch quantity is required for drip mode.");
            return;
          }

          if (!repeatAfter || !weekDays || !processType) {
            window.showNotification("error", "Drip mode requires repeatAfter, weekDays, and processType.");
            return;
          }
        }
      }

      let formData = new FormData();

      let additionalInfos = { userConsent: userConsent };

      if (!fileUrl) {
        formData.append("file", file);
      } else {
        formData.append("fileUrl", fileUrl);
      }

      if (leadOwnerId) {
        additionalInfos.leadOwnerId = leadOwnerId;
      }

      if (smartListId) {
        additionalInfos.smartListId = smartListId;
      }

      formData.append("fileName", fileName);
      formData.append("existingContactRule", existingContactRule);
      formData.append("sourceId", leadSourceId);
      formData.append("additionalInfos", JSON.stringify(additionalInfos));

      if (tagIds[0] !== "") {
        let selectedTags = tags.filter((tag) => tagIds.includes(tag.id));

        selectedTags.forEach((selectedTag, index) => {
          formData.append(`tags[${index}][id]`, selectedTag.id);
          formData.append(`tags[${index}][name]`, selectedTag.title);
        });
      }

      for (const [key, value] of Object.entries(mappedFields)) {
        if (value.split("_")[0] === "custom") {
          formData.append(`custom[${key}]`, value.split("_")[1]);
        } else {
          formData.append(`data[${key}]`, `${value}`);
        }
      }

      if (pipelineId && isPipelineExpand) {
        formData.append("pipelineId", pipelineId);
        formData.append("stageId", stageId);
        formData.append("doCreateDeal", "1");
      }

      if (powerDialerListId) {
        formData.append("powerDialerListId", powerDialerListId);
      }

      if (campaignId && (isCampaignExpand || referer === HISTORY_STATE.CSV_CAMPAIGN_REFERER)) {
        formData.append("campaignId", campaignId);

        if (dripMode === "drip") {
          formData.append(
            "dripBatch",
            JSON.stringify({
              start_from: startDate + " " + startTime,
              quantity: batchQuantity,
              repeat_after_days: repeatAfter,
              send_on: weekDays,
              batch_process_type: processType,
              campaign_id: campaignId,
            })
          );
        }
      }
      setUploading(true);
      let resp = await importContactService.uploadCsvFileData(formData);

      if (resp && resp.success) {
        dispatch(updateSummary(resp.data));
        window.showNotification("success", "Csv file uploaded successfully");
        moveToNextStep();
      } else {
        window.showNotification("error", "Csv file failed to upload");
      }
    } catch (err) {
      console.error("Error during form submission:", err);
    } finally {
      setUploading(false);
    }
  };
  const handleStartOptionChange = (event) => {
    setStartOption(event.target.value);
  };  
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span style={{ height: "unset !important" }} className={classes.headerTitle}>
          Details
        </span>

        <div className={classes.buttonGroup}>
          <Button style={{color:"#000"}}variant='outlined' disableElevation onClick={moveToPreviousStep}>
            Back
          </Button>
          <Button variant='contained' color='primary' disabled={uploading} disableElevation onClick={handleSubmit}>
            {!uploading ? "Upload" : "Uploading..."}
          </Button>
        </div>
      </div>

      <CustomScrollbar className={classes.scrollContainer} ref={scrollContainerRef}>
        <div className={classes.contentWrapper}>
          {/* File Name & Instruction Section */}
          <div className={classes.section}>
            <span style={{ height: "unset !important" }} variant='h6' className={classes.sectionTitle}>
              File Name
            </span>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  Give your file a name.
                </span>
                <BasicInput
                  fullWidth
                  name='fileName'
                  placeholder='Give your file a name'
                  value={fileName}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  Upload instructions for the contacts in your file
                </span>
                <BasicSelect
                  fullWidth
                  name='existingContactRule'
                  defaultText='Select an Existing Contact Rule'
                  options={CONTACT_IMPORT_RULES}
                  mapping={{ label: "label", value: "value" }}
                  value={existingContactRule}
                  onChange={handleUpdateFormData}
                />
              </Grid>
            </Grid>
          </div>

          {/* Contacts Management Section */}
          <div className={classes.section}>
            <span style={{ height: "unset !important" }} variant='h6' className={classes.sectionTitle}>
              Contacts Management
            </span>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  Lead Source
                </span>
                <BasicSelect
                  fullWidth
                  name='leadSourceId'
                  defaultText='Select a Lead Source'
                  options={leadSources}
                  mapping={{ label: "title", value: "id" }}
                  value={leadSourceId}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  Lead Owner
                </span>
                <BasicSelect
                  fullWidth
                  name='leadOwnerId'
                  defaultText='Select a Lead Owner'
                  options={teamUsers}
                  mapping={{ label: "title", value: "id" }}
                  value={leadOwnerId}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                    Tags
                  </span>
                  <Button
                    size='small'
                    startIcon={<AddIcon />}
                    style={{}}
                    className={classes.addNewButton}
                    onClick={() => setOpenTagCreateModal(true)}
                  >
                    Add New
                  </Button>
                  {openTagCreateModal && (
                    <ListOrTagCreateModal
                      open={openTagCreateModal}
                      onClose={() => setOpenTagCreateModal(false)}
                      heading={"Add New Tag"}
                      labelText={"Tag Title"}
                      isForTag={true}
                    />
                  )}
                </div>
                <BasicSelect
                  fullWidth
                  multiple={true}
                  name='tagIds'
                  defaultText='Select Tags'
                  options={tags}
                  mapping={{ label: "title", value: "id" }}
                  value={tagIds}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                    Add to Smart List
                  </span>
                  <Button
                    size='small'
                    startIcon={<AddIcon />}
                    className={classes.addNewButton}
                    onClick={() => setOpenListCreateModal(true)}
                  >
                    Add New
                  </Button>
                  {openListCreateModal && (
                    <ListOrTagCreateModal
                      open={openListCreateModal}
                      onClose={() => setOpenListCreateModal(false)}
                      heading={"Add New Smart List"}
                      labelText={"Smart List Title"}
                    />
                  )}
                </div>

                <BasicSelect
                  fullWidth
                  name='smartListId'
                  defaultText='Select a Smart List'
                  options={smartLists}
                  mapping={{ label: "title", value: "id" }}
                  value={smartListId}
                  onChange={handleUpdateFormData}
                />
              </Grid>
            </Grid>
          </div>

          {/* Deals Pipeline Section */}
          <div className={classes.section}>
            <div className={`${classes.sectionHeader} pipelineHeader_xOsa`}>
              <span
                style={{ height: "unset !important" }}
                variant='h6'
                className={`${classes.sectionTitle} pipelineTitle_xOsa`}
              >
                Add contacts to a deals pipeline?
              </span>
              <SwitchC
                offText=''
                onText=''
                status={isPipelineExpand}
                onChange={handlePipelineExpand}
                brandColor='#006DF5'
              />
            </div>

            {isPipelineExpand && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                    Pipeline
                  </span>
                  <BasicSelect
                    fullWidth
                    name='pipelineId'
                    defaultText='Select a Pipeline'
                    options={pipelines}
                    mapping={{ label: "title", value: "id" }}
                    value={pipelineId}
                    onChange={handleUpdateFormData}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                    Stage
                  </span>
                  <BasicSelect
                    fullWidth
                    name='stageId'
                    defaultText='Select a Stage'
                    options={stages}
                    mapping={{ label: "title", value: "id" }}
                    value={stageId}
                    onChange={handleUpdateFormData}
                  />
                </Grid>
              </Grid>
            )}
          </div>

          {/* Campaign Section */}

          {referer !== HISTORY_STATE.CSV_CAMPAIGN_REFERER && (
            <div className={classes.section}>
              <div className={`${classes.sectionHeader} campaignHeader_xMkd`}>
                <span variant='h6' className={`${classes.sectionTitle} campaignTitle_xMkd`}>
                  Add contacts to a campaign?
                </span>
                <SwitchC
                  offText=''
                  onText=''
                  status={isCampaignExpand}
                  onChange={handleCampaignExpand}
                  brandColor='#006DF5'
                />
              </div>

              {isCampaignExpand && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <span variant='body1' className={classes.fieldLabel}>
                      Campaign
                    </span>
                    <BasicSelect
                      fullWidth
                      name='campaignId'
                      defaultText='Select a Campaign'
                      options={campaigns}
                      mapping={{ label: "title", value: "id" }}
                      value={campaignId}
                      onChange={handleUpdateFormData}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          )}

          {/* Process Mode Section */}
          {campaignId && (isCampaignExpand || referer === HISTORY_STATE.CSV_CAMPAIGN_REFERER) && (
            <div className={classes.section}>
              <div style={{ marginBottom: "8px" }}>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12} md={8}>
                    <div className={classes.gridContainerDrip}>
                      <span variant='h6' className={classes.sectionTitle}>
                        How would you like us to process the contacts?
                      </span>

                      <HelpRounded
                        style={{ color: "var(--gray)", cursor: "pointer" }}
                        onClick={() => setOpenProcessHelpModal(true)}
                      />
                      {openProcessHelpModal && (
                        <ProcessModeHelpModal
                          open={openProcessHelpModal}
                          onClose={() => setOpenProcessHelpModal(false)}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <BasicSelect
                      fullWidth
                      name='dripMode'
                      defaultText='Select Process Mode'
                      options={processModeOptions}
                      mapping={{ label: "label", value: "value" }}
                      value={dripMode}
                      onChange={handleUpdateProcessMode}
                    />
                  </Grid>
                </Grid>
              </div>

              {dripMode === PROCESS_MODE_VALUE.DRIP && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} className={classes.gridContainerDrip}>
                      <span className={classes.fieldLabel}>Start time:</span>
                      <RadioGroup row value={startOption} onChange={handleStartOptionChange}>
                        <CustomFormControlLabel value='now' control={<CustomRadio />} label='Now'/>
                        <CustomFormControlLabel value='later' control={<CustomRadio />} label='Later'/>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction='row'>
                    <Grid item xs={12} md={6} lg={3}>
                      <BasicInput
                        type='date'
                        fullWidth
                        value={startDate}
                        name='startDate'
                        onChange={handleUpdateProcessMode}
                        disabled={startOption === "now"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <div className={classes.gridContainerDrip}>
                        <span variant='body1' className={classes.fieldLabel}>
                          at
                        </span>
                        <BasicInput
                          type='time'
                          fullWidth
                          value={startTime}
                          name='startTime'
                          onChange={handleUpdateProcessMode}
                          disabled={startOption === "now"}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <div className={classes.gridContainerDrip}>
                        <span variant='body1' className={classes.fieldLabel}>
                          Process
                        </span>
                        <BasicInput
                          fullWidth
                          placeholder='# of contacts'
                          value={batchQuantity }
                          name='batchQuantity'
                          onChange={handleUpdateProcessMode}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <div className={classes.gridContainerDrip}>
                        <span variant='body1' className={classes.fieldLabel}>
                          every
                        </span>
                        <BasicInput
                          placeholder='Enter a number'
                          value={repeatAfter}
                          fullWidth
                          name='repeatAfter'
                          onChange={handleUpdateProcessMode}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <BasicSelect
                        fullWidth
                        name='processType'
                        defaultText='Select an option'
                        options={[
                          {
                            id: 1,
                            title: "Minute(s)",
                          },
                          {
                            id: 2,
                            title: "Hour(s)",
                          },
                          {
                            id: 3,
                            title: "Day(s)",
                          },
                        ]}
                        mapping={{ label: "title", value: "id" }}
                        value={processType}
                        onChange={handleUpdateProcessMode}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div variant='body1' className={classes.fieldLabel}>
                        Process contacts on the following days:
                      </div>
                      <FormControl className={classes.weekdaysGroup} onChange={handleWeekDays}>
                        <FormControlLabel
                          value='Sun'
                          checked={weekDays.Sun}
                          control={<Checkbox size='small' color='primary' />}
                          label='Sunday'
                          name='Sun'
                        />
                        <FormControlLabel
                          value='Mon'
                          checked={weekDays.Mon}
                          control={<Checkbox size='small' color='primary' />}
                          label='Monday'
                          name='Mon'
                        />
                        <FormControlLabel
                          value='Tue'
                          checked={weekDays.Tue}
                          control={<Checkbox size='small' color='primary' />}
                          label='Tuesday'
                          name='Tue'
                        />
                        <FormControlLabel
                          value='Wed'
                          checked={weekDays.Wed}
                          control={<Checkbox size='small' color='primary' />}
                          label='Wednesday'
                          name='Wed'
                        />
                        <FormControlLabel
                          value='Thu'
                          checked={weekDays.Thu}
                          control={<Checkbox size='small' color='primary' />}
                          label='Thursday'
                          name='Thu'
                        />
                        <FormControlLabel
                          value='Fri'
                          checked={weekDays.Fri}
                          control={<Checkbox size='small' color='primary' />}
                          label='Friday'
                          name='Fri'
                        />
                        <FormControlLabel
                          value='Sat'
                          checked={weekDays.Sat}
                          control={<Checkbox size='small' color='primary' />}
                          label='Saturday'
                          name='Sat'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          )}

          {/* Confirmation Section */}
          <div className={classes.confirmationSection}>
            <FormControlLabel
              value='userConsent'
              name='userConsent'
              checked={userConsent}
              onChange={handleUpdateFormData}
              control={<Checkbox size='large' color='primary' />}
              label={
              <span style={{color:"#000000",fontSize:"14px  "}}>
                I confirm that all contacts in this import have consented to receive messages from us. I’ve had communications with them within the last year and this list is not from a third party.
              </span>
              }
            />
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default Details;
