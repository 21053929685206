import {makeStyles} from "@material-ui/core";

const modalStyles = makeStyles((theme) => ({
    wrapperMain: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .openButton": {
            fontWeight: "600 !important",
            minWidth: "fit-content !important",
            textTransform: "capitalize",
            "&:hover, &:focus": {
                backgroundColor: "var(--accent-color) !important",
                color: "white !important",
            },
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],
    },
    modalHeader: {
        padding: "16px",
        color: "#fff",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #d3d3d3",

        "& .title": {
            height: "unset !important",
            color: "white",
            fontWeight: 600,
            fontSize: 16,
        },
    },
    modalBody: {
        padding: "16px",
        paddingLeft: "30px",
    },
    modalFooter: {
        borderEndEndRadius: "8px",
        borderEndStartRadius: "8px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "8px",
        borderTop: "1px solid #d3d3d3",
        paddingRight: "16px",
        height: "70px",
    },
    backButton: {
        color: "#fff !important",
        width: "100px !important",
        fontWeight: 600,
        textTransform: "capitalize !important",
        backgroundColor: "#3C7EF3 !important",
    },
}));

export default modalStyles;