import {createSlice} from '@reduxjs/toolkit';
import {
    AGENCY_BUSINESS_TYPE, IMPORT_CONTACT_INSURANCE_STANDARD,
    IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT,
    IMPORT_CONTACT_V2_COMMUNICATION_EMAIL,
    IMPORT_CONTACT_V2_CONTACT_ADDRESS,
    IMPORT_CONTACT_V2_CONTACT_DETAILS,
    IMPORT_CONTACT_V2_PRIMARY_PHONE,
    IMPORT_CONTACT_V2_RELATED_CONTACT1,
    IMPORT_CONTACT_V2_RELATED_CONTACT2,
    IMPORT_CONTACT_V2_RELATED_CONTACT3,
    IMPORT_CONTACT_V2_RELATED_CONTACT4,
    IMPORT_CONTACT_V2_RELATED_CONTACT5,
    IMPORT_CONTACT_V2_SECONDARY_PHONE1,
    IMPORT_CONTACT_V2_SECONDARY_PHONE2,
    IMPORT_CONTACT_V2_SECONDARY_PHONE3,
    IMPORT_CONTACT_V2_SECONDARY_PHONE4,
    PROCESS_MODE_VALUE,
} from '../../constants/CoreConstants';
import Utils from "../../helpers/Utils";
import moment from "moment";

let userTimeNow = window.globalTimezoneConversionToDifferentTimezone ? window.globalTimezoneConversionToDifferentTimezone(new Date()) : new Date(moment.tz(Utils.getAccountData("userTimezoneRaw")));

const reformatDate = (inputDate) => {
    const [month, day, year] = inputDate.split("-");
    return `${year}-${month}-${day}`;
};

const appendToArrayAtIndex = (array, index, item) => {
    return [...array.slice(0, index), item, ...array.slice(index)];
};

const initialMappingFields = [
    {
        label: "Details",
        options: IMPORT_CONTACT_V2_CONTACT_DETAILS,
    },
    {
        label: "Emails",
        options: IMPORT_CONTACT_V2_COMMUNICATION_EMAIL
    },
    {
        label: "Primary Phone",
        options: IMPORT_CONTACT_V2_PRIMARY_PHONE
    },
    {
        label: "Phone (1)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE1
    },
    {
        label: "Phone (2)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE2
    },
    {
        label: "Phone (3)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE3
    },
    {
        label: "Phone (4)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE4
    },
    {
        label: "Address",
        options: IMPORT_CONTACT_V2_CONTACT_ADDRESS,
    },
    {
        label: "Related Contacts (1)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT1
    },
    {
        label: "Related Contacts (2)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT2
    },
    {
        label: "Related Contacts (3)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT3
    },
    {
        label: "Related Contacts (4)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT4
    },
    {
        label: "Related Contacts (5)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT5
    }
];

const initialDetails = {
    leadSourceId: "",
    pipelineId: "",
    stageId: "",
    tagIds: [""],
    leadOwnerId: Utils.getAccountData("userId"),
    campaignId: "",
    smartListId: "",
    email: "",
    spreadsheetLink: "",
    spreadSheetName: "",
    worksheet: "",
    workSheetInfo: null,
    ignoreFirstRow: true,
    userConsent:true,
    spreadSheetId:'',
    existingContactRule: 'ADD_NEW_AND_UPDATE'
};

const initialAccordion = {
    isPipelineExpand: false,
    isCampaignExpand: false,
};


const initialProcessMode = {
    dripMode: PROCESS_MODE_VALUE.ONCE,
    startDate: reformatDate(userTimeNow.split(" ")[0]),
    startTime: userTimeNow.split(" ")[1],
    batchQuantity:"",
    repeatAfter:"",
    processType: 1,
    weekDays: { Sun: true, Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true }
};

const initialState = {
    activeTab: IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT,
    completedSteps: [],

    mappingFields: initialMappingFields,
    mappedFields: {},
    alreadyMappedFields: [],
    mappedRows: [],
    unmappedRows: [],

    error: null,

    loadingInitialData: false,
    leadSources: [],
    teamUsers: [],
    smartLists: [],
    tags: [],

    accordion: initialAccordion,

    loadingPipelines: false,
    pipelines: [],
    loadingStages: false,
    stages: [],

    loadingCampaigns: false,
    campaigns: [],

    loadingWorksheets: false,
    worksheets: [],

    details: initialDetails,

    processMode: initialProcessMode,

    spreadSheetFilesDetails:{},

    referer: null
};

const importSpreadsheetContactSlice = createSlice({
    name: 'importSpreadsheetContact',
    initialState,
    reducers: {
        loadInitialDataStart: (state, _action) => {
            state.loadingInitialData = true;
        },
        loadInitialDataSuccess: (state, action) => {
            state.loadingInitialData = false;
            if (action.payload.leadSources){
                state.leadSources = action.payload.leadSources;
                if (action.payload.leadSources[0]){
                    state.details.leadSourceId = action.payload.leadSources[0].id;
                }
            }

            if (action.payload.tags){
                state.tags = action.payload.tags;
                state.details.tagIds = [""];
            }

            if (action.payload.teamUsers){
                state.teamUsers = action.payload.teamUsers;
            }

            if (action.payload.smartLists){
                state.smartLists = action.payload.smartLists;
                state.details.smartListId = "";
            }

            if (Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE){
                state.mappingFields = appendToArrayAtIndex(state.mappingFields, 1,{
                    label: "Standard Fields",
                    options: IMPORT_CONTACT_INSURANCE_STANDARD
                });
            }

            if (action.payload.customFields){
                state.mappingFields = [ ...state.mappingFields, { label: "Custom Fields", options: action.payload.customFields } ];
            }
        },
        loadInitialDataFail: (state) => {
            state.loadingInitialData = false;
        },
        loadPipelineDataStart: (state, _action) => {
            state.loadingPipelines = true;
            state.details.pipelineId = "";
        },
        loadPipelineDataSuccess: (state, action) => {
            state.loadingPipelines = false;
            state.pipelines = action.payload;
            state.stages = [];
        },
        loadPipelineDataFail: (state) => {
            state.loadingPipelines = false;
        },
        loadStageDataStart: (state) => {
            state.stages = [];
            state.loadingStages = true;
            state.details.stageId = "";
        },
        loadStageDataSuccess: (state, action) => {
            state.loadingStages = false;
            state.stages = action.payload;
        },
        loadStageDataFail: (state) => {
            state.loadingStages = false;
        },
        loadCampaignDataStart: (state, _action) => {
            state.loadingCampaigns = true;
            state.details.campaignId = "";
        },
        loadCampaignDataSuccess: (state, action) => {
            state.loadingCampaigns = false;
            state.campaigns = action.payload;
        },
        loadCampaignDataFail: (state) => {
            state.loadingCampaigns = false;
        },
        updateActiveStepAndCompletedStep: (state, action) => {
            state.activeTab = action.payload.activeTab;
            state.completedSteps = action.payload.completedSteps;
        },
        updateMappedFields: (state, action) => {
            if (action.payload.mappedFields) state.mappedFields = action.payload.mappedFields;
            if (action.payload.alreadyMappedFields) state.alreadyMappedFields = action.payload.alreadyMappedFields;
            if (action.payload.mappedRows) state.mappedRows = action.payload.mappedRows;
            if (action.payload.unmappedRows) state.unmappedRows = action.payload.unmappedRows;
        },
        updateAccordion: (state, action) => {
            state.accordion = { ...state.accordion, ...action.payload };
        },
        updateDetails: (state, action) => {
            state.details = { ...state.details, ...action.payload };
        },
        updateProcessMode: (state, action) => {
            state.processMode = { ...state.processMode, ...action.payload };
        },
        resetToInitial: (state) => {
            state.activeTab = IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT;
            state.completedSteps = [];
            state.mappingFields = initialMappingFields;
            state.mappedFields = {};
            state.alreadyMappedFields = [];
            state.mappedRows = [];
            state.unmappedRows = [];
            state.details = initialDetails;
            state.referer = null
        },
        updateTags : (state, action) => {
            state.tags = [action.payload, ...state.tags]
            state.details.tagIds = state.details.tagIds[0] ? [action.payload.id, ...state.details.tagIds] : [action.payload.id]
        },
        updateSmartList : (state, action) => {
            state.smartLists = [action.payload, ...state.smartLists]
            state.details.smartListId = action.payload.id

        },
        updateImportFrom: (state, action) => {
            state.importFrom = action.payload;
        },
        loadWorksheetsStart: (state, _action) => {
            state.loadingWorksheets = true;
        },
        loadWorksheetsSuccess: (state, action) => {
            state.loadingWorksheets = false;
            state.worksheets = action.payload.sheets.map((sheet)=>({ sheetId: sheet?.properties?.sheetId, title: sheet?.properties?.title }));
            state.details.spreadSheetName = action.payload.properties?.title;
        },
        loadWorksheetsFail: (state) => {
          state.loadingWorksheets = false;
        },
        loadSpreadSheetFileDetails: (state, action) => {
            state.spreadSheetFilesDetails = action.payload
        },
        updateImportReferer: (state, action) => {
            state.referer = action.payload;
        },
    },
});

export const {
    loadInitialDataStart,
    loadInitialDataSuccess,
    loadInitialDataFail,
    updateActiveStepAndCompletedStep,
    updateMappedFields,
    loadPipelineDataStart,
    loadPipelineDataSuccess,
    loadPipelineDataFail,
    loadStageDataStart,
    loadStageDataSuccess,
    loadStageDataFail,
    loadCampaignDataStart,
    loadCampaignDataSuccess,
    loadCampaignDataFail,
    updateDetails,
    updateProcessMode,
    updateAccordion,
    resetToInitial,
    updateTags,
    updateSmartList,
    loadWorksheetsStart,
    loadWorksheetsSuccess,
    loadWorksheetsFail,
    loadSpreadSheetFileDetails,
    updateImportReferer,
} = importSpreadsheetContactSlice.actions;

export default importSpreadsheetContactSlice.reducer;
