
const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const marketingModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const dealModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const profileModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`
const calendarModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar`
const inboxModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const backendeUrlV1 = `${process.env.REACT_APP_JAVAEND_URL}/v1`
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`
const formApi17 = `${process.env.REACT_APP_FORM_API_17}/v1`
const carrierLookupBackendApi = `${process.env.REACT_APP_CARRIER_LOOKUP_BACKEND_SERVER}/v1`
const bulkEmailBackendApi = `${process.env.REACT_APP_BULK_EMAIL_BACKEND_SERVER}/api`
const pyproV1 = `${process.env.REACT_APP_BASE_URL}/api/v1`
const java17CoreApiV1 = `${process.env.REACT_APP_JAVA17_CORE_URL}/v1`;

// http://app.pypeprov1.test/api/v1/contacts/update-contact-lead-type
const Api = {
    details: `${rootUrl}/details`,
    detailsNew: `${rootUrl}/details-new`,
    getCustomTypes: `${rootUrl}/user-custom-fields`,
    getContactFiles: `${rootUrl}/get-contact-saved-files`,
    getContactStages: `${rootUrl}/get-contact-stages`,
    changeLeadOwner: `${rootUrl}/change/lead-owner`,
    getAllTeamUsers: `${rootUrl}/get-all-team-users`,
    getSearchContacts: `${rootUrl}/search-contacts`,
    getCollaborators: `${rootUrl}/get-collaborators`,
    deleteCollaborator: `${rootUrl}/delete-collaborators`,
    saveCollaborator: `${rootUrl}/save-collaborator`,
    getUserGroup: `${rootUrl}/get-user-group`,
    removeFile: `${rootUrl}/file/remove`,
    uploadFile: `${rootUrl}/upload/file`,
    getTimelineLogs: `${rootUrl}/get/timeline-log`,
    emailContactToFriend: `${rootUrl}/email-contact-to-friend`,
    getGoogleMapResource: `${rootUrl}/get-google-map-resource`,
    editContactData: `${rootUrl}/edit-contact-data`,

    changeDealStatus: `${rootUrl}/status-change-deals`,

    addNewDeal: `${rootUrl}/add-new-deal`,
    updateDeal: `${rootUrl}/update-contact-deal`,
    stageList: `${rootUrl}/stage-list`,
    stageListV2: `${rootUrl}/stage-list-v2`,
    stageListWithPagination: `${rootUrl}/stage-list-with-paginate`,
    campaignList: `${rootUrl}/campaign/list/contact`,
    powerDialerList: `${tenantBackendV1}/power-dialer/all/lists`,
    runCampaign: `${rootUrl}/campaign/single-contact/run`,
    pauseCampaign: `${rootUrl}/campaign/single-contact/pause`,
    unsubscribeCampaign: `${rootUrl}/campaign/single-contact/unsubscribe`,
    moveToCampaign: `${rootUrl}/campaign/contact/move`,
    updateSingleCustomField: `${rootUrl}/single/custom-field/update`,
    addTags: `${rootUrl}/add-tags`,
    addTagsV2: `${rootUrl}/add-tags-v2`,
    tagList: `${rootUrl}/tag/list-optimized`,
    tagListNew: `${rootUrl}/tag/list-optimized-new-api`,
    smsTemplate: `${rootUrl}/get/sms/template`,
    emailTemplate: `${rootUrl}/get/email/template`,
    getTimeline: `${rootUrl}/get/timeline`,
    updateTimelineNote: `${rootUrl}/update-timeline-note`,
    voiceTemplate: `${rootUrl}/get/voice/template`,
    userProfile: `${rootUrl}/get/user-profile`,
    sendMail: `${rootUrl}/send-mail`,
    uploadEmailFile: `${rootUrl}/upload-email-file`,
    userEmailList: `${rootUrl}/user-email-list`,
    sendMessage: `${rootUrl}/send-message`,
    getCustomFields: `${rootUrl}/get-custom-fields`,
    getRunningCampaignListNotInCurrentCampaign: `${rootUrl}/campaign/not-in-current-contact/list`,
    changeContactSource: `${rootUrl}/contact-source-type/change/source`,
    sourceListDropdown: `${rootUrl}/contact-source-type/list/dropdown`,
    updateContactInfo: `${rootUrl}/update-contact-info`,
    updateAditionalEmailPhone: `${rootUrl}/update-aditional-email-phone`,
    getContactTagList: `${rootUrl}/get-contact-tag-list`,
    deleteContactTag: `${rootUrl}/delete-contact-tag`,
    getMailCategory: `${rootUrl}/get-card-basic-setting`,
    getMailTemplate: `${rootUrl}/get-card-templates`,
    createMailTemplate: `${rootUrl}/card-template-create`,
    getUserVirtualNumberList: `${rootUrl}/virtual-number-list`,
    addNote: `${dealModuleUrl}/note-add-submit`,
    producePDF: `${rootUrl}/generate-pdf`,
    addCardBroadcastToContact: `${inboxModuleUrl}/add-card-broadcast-to-contact`,
    getThirdPartyInfo: `${rootUrl}/get-third-party-info`,
    getContactTimeline: `${rootUrl}/contact-conversation`,
    updateContactDetailsApi: `${rootUrl}/update-contact-details-v2`,

    getActivityList:`${rootUrl}/activity-list`,
    getActivityListContactDetails:`${rootUrl}/activity-list-contact-details`,
    contactMarkAsDone:`${rootUrl}/activity-mark-as-done`,
    saveActivity:`${rootUrl}/save-activity`,

    fetchVideoFolders: `${marketingModuleUrl}/video/folders`,
    fetchVideos: `${marketingModuleUrl}/videos`,
    deleteVideo: `${marketingModuleUrl}/video/delete`,
    uploadVideo: `${marketingModuleUrl}/video/upload`,
    getMessageTemplate: `${marketingModuleUrl}/message-templates`,

    //Get All Contacts
    getAllContacts: `${rootUrl}/contact-datatable`,
    getCampaignListForDropdown: `${rootUrl}/campaign-list-dropdown`,
    sourceListDropdownForContactTable: `${rootUrl}/source-list-dropdown`,
    subUserListForContactDropdown: `${rootUrl}/sub-users-optimized`,
    fileListForDropdown: `${rootUrl}/file-list-dropdown-paginate`,
    getCampaignListForDropdownWithPaginate: `${rootUrl}/campaign-list-dropdown-with-paginate`,

    //remove contact
    removeBulkContact: `${rootUrl}/bulk-contact-delete`,

    //List
    getAllList: `${rootUrl}/contact-list-dropdown`,

    //single contact
    addContactInCampaign: `${rootUrl}/contact/move/campaign`,
    
    //multiple contacts
    addContactsInCampaign: `${rootUrl}/add-contacts-to-campaign`,

    addEnhanceContactsInCampaign: `${rootUrl}/add-enhance-contacts-to-campaign`,

    //GetUserInfo
    getUserInfo: `${rootUrl}/get-user-data`,

    //Get Custom Fields
    // getCustomFields: `${marketingModuleUrl}/get-custom-fields`,

    //submit single contact
    addSingleContact: `${marketingModuleUrl}/campaigns/add/single-contact`,

    //get pipeline list
    getPipelineList: `${dealModuleUrl}/get-all-pipeline`,

    //get stages by pipeline
    getAllStageByPipeline: `${dealModuleUrl}/select-all-stage-by-pipeline`,

    //Import contact routes
    getContactLimit: `${rootUrl}/get-limit`,
    getFileLists: `${rootUrl}/get-file-lists`,
    deleteImportFiles: `${rootUrl}/delete-import-files`,
    contactImport: `${rootUrl}/contact-import`,
    fileAssignToCampaign: `${rootUrl}/campaign-file-assign`,
    allCampaignWithVn: `${rootUrl}/campaign-all-with-vn`,
    getInvalidContacts: `${rootUrl}/get-invalid-contacts`,
    getImportedFileLists: `${tenantBackendV1}/contact/imported-file-lists`,
    getImportedInvalidContactsLogs:`${tenantBackendV1}/contact/imported-file-lists/contact-logs`,
    deleteImportContactFiles:`${tenantBackendV1}/contact/imported-file-lists`,
    getCampaignApi: `${tenantBackendV1}/campaign/dropdown-data`,
    assignToCampaign: `${tenantBackendV1}/contact/imported-file-lists/campaign`,

    //contact (List) Feature
    getContactList: `${profileModuleUrl}/contact-list/list`,
    saveContactList: `${profileModuleUrl}/contact-list/save`,

    //Assign Bulk Stage
    assignBulkStageForContacts:`${rootUrl}/assign-bulk-stage`,

    //Export csv from contact list
    // exportCSVFromContactlist : `${rootUrl}/export-csv-from-contactlist`,
    exportCSVFromContactlist : `${backendeUrlV1}/export/contacts/file`,

    addContactToList: `${rootUrl}/add-contacts-to-list`,

    //Activity list api
    getActivityCalendar: `${calendarModuleUrl}/get-activity-list-for-calendar`,
    getTaskCount: `${calendarModuleUrl}/get-task-count`,

    moveStage: `${dealModuleUrl}/reorder-deals`,

    updateMoreInfoContact: `${dealModuleUrl}/contact/update/field`,

    getContactSchedules: `${rootUrl}/schedules`,
    /* data export  api */
    getCsvFileData: `${rootUrl}/get-user-export-files`,
    deleteDataExportFile: `${rootUrl}/delete-data-export-file`,

    removeScheduleBroadcast: `${rootUrl}/remove-schedule-broadcast`,

    removeContactList: `${profileModuleUrl}/contact-list/delete`,
    contactLimitRequest: `${rootUrl}/contact-limit-increase-request`,

    /* Auto dialer */
    getDialingList: `${rootUrl}/auto-dialer/list`,
    callingSettingSubmit: `${rootUrl}/auto-dialer/setting/submit`,
    deleteList: `${rootUrl}/auto-dialer/setting/delete`,
    addContactsToAutoDialingList: `${rootUrl}/auto-dialer/contact/add`,

    /* contact limit */
    isUserPackageInTrial: `${containerUrl}/check/user/in/trial`,

    /* Get inactive contact lists */
    getInactiveContactApi: `${rootUrl}/inactive/contact/list`,

    /* Activate contact */
    activateContactApi: `${rootUrl}/activate/contact`,

    /* Email Type List Api */
    emailTypeListApi: `${rootUrl}/get/contact/email/relations`,

    /* Email Type List Api */
    addExtraEmailApi: `${rootUrl}/add/contact/extra/email`,

     /* Extra Email List Api */
     extraEmailListApi: `${rootUrl}/get/contact/extra/email`,

    /* Edit Extra Email List Api */
     editExtraEmailApi: `${rootUrl}/update/contact/extra/email`,

    /* Remove Extra Email */
    removeExtraEmail: `${rootUrl}/delete/contact/extra/email`,

    /* Get unsubscribe contact lists */
    getUnsubscribeContactApi: `${rootUrl}/get/unsubscribe-contacts`,

    dialerToken : `${containerUrl}/dialer/token`,

    getContactRequests: `${rootUrl}/contact-permission/get-contact-requests`,
    rejectContactRequest: `${rootUrl}/contact-permission/reject-contact-request`,
    acceptContactRequest: `${tenantBackendV1}/contact-permission/accept-permission-contact-request`,

    receiveBulkContactPermissionRequest: `${tenantBackendV1}/contact-permission/bulk-permission-request`,

    getContactSmartFormEntries: `${formApi17}/forms/entries`,

    formBuilderAWSPreSignedUrl: process.env.REACT_APP_FORM_BUILDER_S3_PRESIGNED,

    getContactVerificationStats: `${rootUrl}/carrier-lookup/get-verification-states`,
    getCarrierLookupCreditSettings: `${rootUrl}/carrier-lookup/get-credit-settings`,
    carrierLookupVerifyContact: `${carrierLookupBackendApi}/carrier/lookup/single`,
    carrierLookupVerifyContacts: `${carrierLookupBackendApi}/carrier/lookup/bulk`,
    getUserCampaignRegistrationStatus: `${containerUrl}/get-user-campaign-registration-status`,

    cleanDataFileImport: `${rootUrl}/clean-data-import`,

    carrierLookupCleanDataSingle: `${carrierLookupBackendApi}/carrier/clean-data/single`,
    carrierLookupCleanDataBulk: `${carrierLookupBackendApi}/carrier/clean-data/bulk`,
    cleanContactsActions: `${carrierLookupBackendApi}/clean-contacts/actions`,
    carrierLookupCleanData: `${carrierLookupBackendApi}/carrier/lookup/single/v1`,
    cleanContactsActionsV1: `${carrierLookupBackendApi}/clean-contacts/actions/v1`,

    cleanDataFileList: `${rootUrl}/enhence-data-files`,
    cleanDataFilesDetails: `${rootUrl}/clean-contact-lists`,
    cleanDataExportedFiles: `${rootUrl}/clean-data-exported-files`,

    cleanDataPriceValidation: `${rootUrl}/check-validation-status`,

    getZipCodeByPlace: `${rootUrl}/get-google-map-resource`,

    updateNumberDncStatus: `${tenantBackendV1}/contacts/dnc/update-number-dnc-status`,
    getContactLogs: `${tenantBackendV1}/contacts/get-contact-logs`,

    createAdditionalContactApi:`${rootUrl}/additional-contacts/create`,
    getAllAdditionalContactApi:`${rootUrl}/additional-contacts`,
    updateAdditionalContactApi:`${rootUrl}/additional-contacts/update`,
    deleteAdditionalContactApi:`${rootUrl}/additional-contacts/delete`,
    makeNumberDefaultAPI:`${rootUrl}/additional-contacts/make-default`,
    updateContactCustomFieldV2: `${rootUrl}/update-contact-custom-field-v2`,

    updateContactDetailsV2:`${rootUrl}/update-contact-details-v2`,
    makeDefaultContact:`${rootUrl}/additional-contacts/make-default`,

    deleteAdditionalContactNumberApi:`${rootUrl}/additional-contacts/number/delete`,
    deleteAdditionalContactEmailApi:`${rootUrl}/additional-contacts/email/delete`,

    deleteContactNumberApi:`${rootUrl}/number/delete`,
    deleteContactEmailApi:`${rootUrl}/email/delete`,

    makeDefaultAdditionalContactNumberApi:`${rootUrl}/additional-contacts/number/make-default`,
    makeDefaultAdditionalContactEmailApi:`${rootUrl}/additional-contacts/email/make-default`,

    updateContactLeadTypeApi: `${rootUrl}/update-contact-lead-type`,
    deleteCleanContacts: `${rootUrl}/delete-clean-contacts`,

    updateImportantDates: `${rootUrl}/update-important-dates`,
    updateImportantLinks: `${rootUrl}/update-important-links`,

    getBulkEmailCampaignDetails: `${bulkEmailBackendApi}/campaigns/main/campaign-details`,
    getContactStagesProducts: `${rootUrl}/user-contact-stage-products/products/{contactStageId}`,
    getUserProductList : `${dealModuleUrl}/user-products/list`,
    getContactStagesProduct: `${rootUrl}/get-contact-stages-products`,

    createUserProduct: `${dealModuleUrl}/user-products`,

    getContactSoldProducts: `${java17CoreApiV1}/dashboard/contact-sold-products`,
    userProductStatus: `${dealModuleUrl}/user-products/status`,
    getUserProductActiveList : `${dealModuleUrl}/user-products/active-list`,

    searchContactFromElastic: `${backendeUrlV1}/contacts/get-elastic-contacts`,

    contactRelationshipApi: `${tenantBackendV1}/contact-relationships`,
    getRelatedContactConversation: `${rootUrl}/related-contact-conversation`,

    getPolicyApi: `${tenantBackendV1}/contact/get-policy-data`,
    createUserPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    getContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    deleteContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    updateContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    updateContactPolicyStatusApi:`${tenantBackendV1}/contact/contact-policy/update-status`,
    getContactPolicyCategoryApi:`${tenantBackendV1}/contact/get-policy-category`,
    addContactPolicyApi:`${dealModuleUrl}/user-policies`,
    dealWinAfterPolicyCreateApi: `${dealModuleUrl}/user-contact-stage-products/win-deal-with-contact-policy`,
    contactProductApi:`${tenantBackendV1}/contact/contact-product`,
    getPolicyData:`${dealModuleUrl}/user-policies/list`,
    
    //updatePhoneNumberState Api for contact
    updatePhoneNumberState:`${tenantBackendV1}/contact/update-number-state`,
    createSmartList: `${tenantBackendV1}/contact/contact-lists`,
    createTags: `${tenantBackendV1}/contact/tags`,

    getImportedContactSummary: `${tenantBackendV1}/contact/imported-file-lists/summary`,
    getImportedContactLogs: `${tenantBackendV1}/contact/imported-file-lists/logs`,
    getImportSettingsDetails: `${tenantBackendV1}/contact/imported-file-lists/summary-details`,
    getImportedSpreadsheetContactSummary: `${tenantBackendV1}/contact/user-connected-files`,
    getImportedSpreadsheetContactLogs: `${tenantBackendV1}/contact/user-connected-files/logs`,
    getImportSpreadsheetSettingsDetails: `${tenantBackendV1}/contact/user-connected-files/setting-details`,
}

export default Api;
