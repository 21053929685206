import { Backdrop, Fade, Modal } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { importContactsV2Style } from "../importContactsV2Style";
import modalUseStyles from "../../contact/leftSideV2/common/modal/modalUseStyles";
import { useDispatch } from "react-redux";
import { resetToInitial } from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";

import Mapping from "./Mapping";
import React, {useState} from "react";
import HowItWorksModal from "../HowItWorksModal";

const ImportSpreadsheetModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [openHowItsWorksModal, setOpenHowItsWorksModal] = useState(false);

  const classes = importContactsV2Style();
  const modalClasses = modalUseStyles();

  const handleOnClose = () => {
    onClose();
    dispatch(resetToInitial());
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={modalClasses.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={modalClasses.paper}>
          <div
            className={classes.csvModalWrapper}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#949db21f",
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16 }}>
              <div style={{ display: "flex", alignItems: "center", gap: 10, cursor: "pointer" }} onClick={onClose}>
                <ArrowBackIcon />
                <h6 style={{ fontWeight: 600, margin: 0 }}>Import From Google Sheets</h6>
              </div>

              <div className={classes.helpBox} onClick={() => setOpenHowItsWorksModal(true)}>
                <p>How it works?</p>
              </div>
            </div>

            <div style={{ backgroundColor: "#fff", borderRadius: 4, height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                  <h4 style={{ fontSize: 18, margin: 0 }}>Import From Google Sheets</h4>
                  <p style={{ fontSize: 14, fontWeight: 300, margin: 0 }}>Add your contact using google sheets</p>
                </div>
              </div>

              <div
                style={{
                  margin: 8,
                  padding: 16,
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: 8,
                  height: "calc(100vh - 195px)",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-start", padding: "0px 100px 40px", gap: 8 }}>
                  <div style={{ width: "100%" }}>
                    <Mapping handleOnClose={handleOnClose}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
            {openHowItsWorksModal && (
                <HowItWorksModal open={openHowItsWorksModal} onClose={() => setOpenHowItsWorksModal(false)} />
            )}
        </div>
      </Fade>
    </Modal>
  );
};

export default ImportSpreadsheetModal;
