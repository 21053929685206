import { makeStyles } from "@material-ui/core";

export const importContactCsvStyle = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    headerTitle: {
      fontWeight: 600,
      fontSize:'22.5px',
      color: "var(--dark_blue)",
    },
    buttonGroup: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    scrollContainer: {
      maxHeight: "calc(100vh - 299px)",
      overflow: "auto",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    section: {
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    sectionHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&.pipelineHeader_xOsa": {
        marginBottom: ({ isPipelineExpand }) => isPipelineExpand ? theme.spacing(1) : "0",
      },
      "&.campaignHeader_xMkd": {
        marginBottom: ({ isCampaignExpand }) => isCampaignExpand ? theme.spacing(1) : "0",
      },
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "18px",
      "&.pipelineTitle_xOsa": {
        marginBottom: ({ isPipelineExpand }) => isPipelineExpand ? theme.spacing(1) : "0",
      },
      "&.campaignTitle_xMkd": {
        marginBottom: ({ isCampaignExpand }) => isCampaignExpand ? theme.spacing(1) : "0",
      },
    },
    fieldLabel: {
      fontSize: 14,
      marginBottom: theme.spacing(0.25),
    },
    radioGroup: {
      flexDirection: "row",
      marginBottom: theme.spacing(1),
    },
    weekdaysGroup: {
      flexDirection: "row",
      marginBottom: theme.spacing(1),
      "& .MuiFormControlLabel-root": {
        marginRight: theme.spacing(2),
      },
    },
    confirmationSection: {
      paddingInline: theme.spacing(1.5),
    },
    addNewButton: {
      color: "#346FEF",
      '&.MuiButton-textSizeSmall': {
        padding: '0px 5px !important',
      },
      "&:focus,&:hover": {
        background: "transparent",
      },
    },
    gridContainerDrip: {
      display: "flex",
      alignItems: "center",
      gap: 10
    }
  }));