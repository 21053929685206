import { makeStyles } from "@material-ui/core";

export const ImportContactStyle = makeStyles((theme) => ({
    container: {
      backgroundColor: "#fff",
      borderRadius: "5px",
      height: "calc(100vh - 100px)",
      "& .MuiButton-contained": {
        backgroundColor: "#3e50f7 !important",
        "&:focus, &:hover": {
          backgroundColor: "#3e50f7 !important",
        },
      },
      "& .MuiButton-outlined": {
        color: "#3e50f7 !important",
        borderColor: "#3e50f7 !important",
        "&:focus, &:hover": {
          color: "#3e50f7 !important",
          backgroundColor: "transparent !important",
        },
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(2.5),
      borderBottom: "1px solid lightgray",
      backgroundColor: "#fff",
    },
    headerLeft: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.25),
    },
    headerIcon: {
      height: 70,
    },
    headerText: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: 600,
      margin: theme.spacing(0, 0, 0.625, 0),
      fontSize: "20px",
    },
    subtitle: {
      color: "gray",
      margin: 0,
    },
    helpSection: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.25),
    },
    helpBox: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#ededed",
      color: "#000",
      padding: theme.spacing(0.625, 1.25),
      borderRadius: "5px",
      gap: theme.spacing(0.625),
      maxHeight: 36,
      cursor: "pointer",
    },
    content: {
      display: "flex",
      alignItems: "center",
      paddingInline: "10%",
      paddingTop: "5px",
      paddingBottom: "50px",
      justifyContent: "center",
    },
    card: {
      padding: "18px 30px",
      border: "1.8px solid #ccdbff",
      borderRadius: "12px",
      height: "100%",
      display: "flex",
      background:"rgb(255, 255, 255)",
      justifyContent: "space-between",
      gap: 10,
      boxShadow:"rgba(0, 0, 0, 0.1) 0px 1px 2px !important",
      alignItems: "center",
      maxHeight:"215px",
    },
    cardContainer: {
        display:"flex", flexDirection: "column", gap:5
    },
    cardHeader: {
        display: "flex",alignItems:"center", gap: "10px", padding: "5px 0px" 
    },
    cardTitle: {
      fontSize: 20,
      fontWeight: 600,
    },
    cardDescription: {
      fontWeight: 300,
      margin: "0px",
    },
    notes: {
      fontSize: 14,
      margin: "0px",
      marginBottom: "5px",
      fontWeight: 600,
      color:"#8c8c8c"
    },
    notesList: {
      fontSize: 14,
      paddingLeft: theme.spacing(2.25),
      "& li": {
        listStyleType: "disc !important",
      },
    },
    buttonContainer: {
        display: "flex", gap: "10px", padding: "5px 0px"
    },
    button:{
     minWidth:"167px",
     boxShadow:"rgba(0, 0, 0, 0.1) 0px 1px 2px !important",
     padding:"10px",
     borderRadius:"12px",
     fontWeight:600,
    },
  }));