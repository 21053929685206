import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '10px',
        width: '860px',
    },
    bold: {
        fontWeight: 'bold'
    },
    step1: {
        marginBottom: '20px'
    },
    step2: {
        display: 'flex',
        gap: '20px'
    }
}));

const SpreadsheetLinkHelperModalBody = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.step1}>
                <Typography style={{height: "unset !important", marginBottom: "10px"}} variant="body1">
                    <span className={classes.bold}>Step 1: </span> Click the Share button at the top of your Google
                    Sheet
                </Typography>
                <img
                    src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/spreadsheet_sample_image.png`}
                    alt='logo'
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            </div>
            <div className={classes.step2}>
                <Typography style={{height: "unset !important", marginBottom: "10px"}} variant="body1">
                    <span className={classes.bold}>Step 2: </span> Click the Copy link button on the popup that appears
                </Typography>
                <img
                    src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/spreadsheet_sample_image_2.png`}
                    alt='logo'
                    style={{
                        width: "310px",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            </div>
        </div>
    )
}

export default SpreadsheetLinkHelperModalBody;