import { MenuItem, MenuList, Paper, Popover, styled } from "@material-ui/core";
import React from "react";
import {CONTACT_SUMMARY_STATUS, SPREADSHEET_SUMMARY_STATUS} from "../../../constants/CoreConstants";

const Wrapper = styled(Paper)(({ theme }) => ({
  borderRadius: "6px",
  "& .actionList": {
    paddingInline: 8,
    "& .MuiListItem-root": {
      color: "var(--dark_blue)",
      borderRadius: "6px",
      minWidth: "150px",
    },
  },
}));

const FileSummaryStatusPopover = ({ id, open, popover, onClose, status, setStatus }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={popover}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Wrapper elevation={3}>
        <MenuList className='actionList'>
          <MenuItem
            selected={status === null}
            onClick={() => {
              setStatus(null);
              onClose();
            }}
          >
            All
          </MenuItem>
          <MenuItem
            selected={status === SPREADSHEET_SUMMARY_STATUS.QUEUE}
            onClick={() => {
              setStatus(SPREADSHEET_SUMMARY_STATUS.QUEUE);
              onClose();
            }}
          >
            Queued
          </MenuItem>
          <MenuItem
            selected={status === SPREADSHEET_SUMMARY_STATUS.PROCESSING}
            onClick={() => {
              setStatus(SPREADSHEET_SUMMARY_STATUS.PROCESSING);
              onClose();
            }}
          >
            Processing
          </MenuItem>
          <MenuItem
            selected={status === SPREADSHEET_SUMMARY_STATUS.PROCESSED}
            onClick={() => {
              setStatus(SPREADSHEET_SUMMARY_STATUS.PROCESSED);
              onClose();
            }}
          >
            Processed
          </MenuItem>
            <MenuItem
                selected={status === SPREADSHEET_SUMMARY_STATUS.NOT_PROCESSED}
                onClick={() => {
                    setStatus(SPREADSHEET_SUMMARY_STATUS.NOT_PROCESSED);
                    onClose();
                }}
            >
                Not Processed
            </MenuItem>
          <MenuItem
            selected={status === CONTACT_SUMMARY_STATUS.FAILED}
            onClick={() => {
              setStatus(SPREADSHEET_SUMMARY_STATUS.FAILED);
              onClose();
            }}
          >
            Failed
          </MenuItem>
            <MenuItem
                selected={status === SPREADSHEET_SUMMARY_STATUS.DISCONNECTED}
                onClick={() => {
                    setStatus(SPREADSHEET_SUMMARY_STATUS.DISCONNECTED);
                    onClose();
                }}
            >
                Disconnected
            </MenuItem>
        </MenuList>
      </Wrapper>
    </Popover>
  );
};

export default FileSummaryStatusPopover;
