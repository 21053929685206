import { Chip, InputAdornment, makeStyles } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { connect } from "react-redux";
import { getUserTagList, setUserTagList } from '../../../actions/contactAction';
import { addTagsV2, getUserTagListNew } from '../../../api/contactApi';
import BasicInput from '../../../components/contact/middleSide/custom/custom/BasicInput';
import { AGENCY_BUSINESS_TYPE } from '../../../constants/CoreConstants';
import useDelayCallback from '../../../hooks/useDelayCallback';
import GlobalModal from '../../globals/Modal/GlobalModal';
import Utils from '../rightSide/Helpers/Utils';
import { icons } from "./icon";
import './modal_assign_to_tag.css';
import TagSkeleton from './TagSkeleton';
const useStyles = makeStyles((theme) => ({
    chipStyle: {
      width: "fit-content",
      height: "26px",
      fontWeight: 600,
      color: "#FFFFFF",
      backgroundColor: (props) => props.color || "#346fef",
      "& .MuiChip-label": {
        color: "#ffffff",
      },
    },
  }));
const EachTag = (props) => {
    const classes = useStyles({color: props.data.color});
    const checkSelect = (id) => {
        return !!props.selectedTags.includes(id);
    }

    const renderSelectBox = (item) => {
      const flag = checkSelect(item.id);
      const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
      return (
        <div className='custom-checkbox-wrapper'>
          <div className='custom-icon-text-wrapper'>
            <span className='custom-checkbox-icon' onClick={() => props.selectTag(item.id)} key={`id_${item.id};`}>
              {flag ? icons.check : icons.unCheck}
            </span>
            <span>{props.data.name}</span>
          </div>
         {(isAgencyTypeInsurance && item.privacy_level) &&  (
             <Chip label={props.data.privacy_level} className={classes.chipStyle} />
        )}
        </div>
      );
    }

    return (
        <li>
            {renderSelectBox(props.data)}
        </li>
    )
}

const ModalAssignToTagNew = (props) => {
    const [tagInfo, setTagInfo] = useState({
        error: false,
        success: false,
        errorMsg: '',
        successMsg: '',
        allTags: [],
        allTagsToShow: [],
        storedTagList: [],
        submitingFile: false,
        submitEnable: false,
        selectedTags: [],
        submitingContact: false,
        createNew: false,
        searchKey: null,
        new: false,
        contactTags: [],
        newTagName: '',
        saveButtonText: "Assign Tag",
    });

    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [hasMore, setHasMore] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [search, setSearch] = useState('');
    const [list, setList] = useState([]);
    const [isSubmitting,setIsSubmitting] = useState(false);

    useDelayCallback(() => {
        let perPageCountCal = 50;
        if(perPage === 50){
            perPageCountCal = (Math.round((document.body.clientHeight - 300) / 40) + 5)
            setPerPage(perPageCountCal)
        }else{
            perPageCountCal = perPage
        }
        getAllTagsForDropdown(perPageCountCal);  
    }, [search])

    const getAllTagsForDropdown = (page = perPage) => {
        setIsLoading(true)
        getUserTagListNew({
            per_page: page,
            current_page:currentPage,
            search:search
        }).then((response)=>{
            let res = response.data
            if(currentPage === 1){
                if(res.length == 0){
                    setTagInfo({
                        ...tagInfo,
                        createNew:true,
                        newTagName:search
                    })
                }
                setList(res)
            }else{
                let all_data = [...list]
                all_data = [...all_data, ...res]
                setTagInfo({
                    ...tagInfo,
                    createNew:false,
                    newTagName:''
                })
                setList(all_data)
            }

            if(res.length === page){
                setCurrentPage(parseInt((currentPage + 1)))
                setHasMore(true)
            }else{
                setHasMore(false)
            }
            setLoadingMore(false)
            setIsLoading(false)
        }).catch(error => {
            setLoadingMore(false)
            setIsLoading(false)
        })
    }


   const  handleOnScrollLoad = (e) =>{
        if(hasMore && !loadingMore){
            if(Math.round((e.target.scrollTop + e.target.clientHeight), 10) >= Math.round(e.target.scrollHeight, 10) ){
                setLoadingMore(true)
                getAllTagsForDropdown(perPage);
            }
        } 
    }
    

    const submitTag = (tagName) => {
        setIsSubmitting(true);
        addTagsV2({
            contact_id: props.contact_id,
            tags: tagName,
            new: 0,
            assign: true

        }).then(res => {

            setTagInfo({
                ...tagInfo,
                submitingContact: false
            })

            if (res.data.status === 'error') {

                setTagInfo({
                    ...tagInfo,
                    error: true,
                    errorMsg: res.data.html
                })
                window.showNotification("ERROR", "An error occurred: " + res.data.html);
                setTimeout(() => {
                    setTagInfo({
                        ...tagInfo,
                        error: false,
                        errorMsg: ""
                    })
                }, 5000);
            } else if (res.data.status === 'validation-error') {
                window.showNotification("WARNING", "Validation error: " + res.data.html);
            } else if (res.data.status === 'success') {
                window.showNotification("SUCCESS", "Tag assigned successfully!");
                props.callBack(true, true, res.data.data);
            } else {
                toggle(true);
            }
        }).catch((error) => {        
            window.showNotification("ERROR", "An unexpected error occurred!");
            console.log(error)
        }).finally(() => {
            setIsSubmitting(false);
          });
    };

    const submitTagNew = (tagName) => {
        setIsSubmitting(true);
        addTagsV2({
            contact_id: props.contact_id,
            tags: tagName,
            new: tagInfo.new,
            assign: false

        }).then(res => {

            setTagInfo({
                ...tagInfo,
                submitingContact: false
            })

            if (res.data.status === 'error') {
                setTagInfo({
                    ...tagInfo,
                    error: true,
                    errorMsg: res.data.html
                })
                window.showNotification("ERROR", "An error occurred: " + res.data.html);
                setTimeout(() => {
                    setTagInfo({
                        ...tagInfo,
                        error: false,
                        errorMsg: ""
                    })
                }, 5000);
            } else if (res.data.status === 'validation-error') {
                window.showNotification("WARNING", "Validation error: " + res.data.html);
                // console.log(res.data.html);
            } else if (res.data.status === 'success') {
                window.showNotification("SUCCESS", "Tag added successfully!");
                props.callBack(true, true, res.data.data);
            } else {

                setTagInfo({
                    ...tagInfo,
                    createNew: false,
                    searchKey: null  
                })
                //this.toggle();
                // this.loadAllTags();
            }
        }).catch(error => {
            window.showNotification("ERROR", "An unexpected error occurred!");
            console.error("Error:", error);
          }).finally(() => {
            setIsSubmitting(false);
          });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (tagInfo.submitingContact){
            return false;
        }

        if ((!tagInfo.createNew && tagInfo.selectedTags.length === 0)){
            window.showNotification('WARNING', "Please select any tag", 5000)
            return false
        }

        setTagInfo({
            ...tagInfo,
            submitingContact: true
        })

        if(props.contact_id !== undefined){
            if (tagInfo.createNew) {
                submitTagNew(tagInfo.newTagName);
            } else {
                submitTag(tagInfo.selectedTags);
            }
        }else{

            if(tagInfo.createNew){
                props.callBack("newTags", tagInfo.newTagName);
                if(props.hideModal !==undefined){
                    props.hideModal();
                }
            }else{
                if(props.from !== undefined && props.from === "contact-file-upload"){
                    let allTagsFilter = list.filter((item) =>  tagInfo.selectedTags.indexOf(item.id) >= 0);                    
                    props.callBack("oldTags", allTagsFilter);
                    if(props.hideModal !==undefined){
                        props.hideModal();
                    }
                }else{
                    let allTagsFilter = list.filter(
                        function(e) {
                            return this.indexOf(e.id.toString()) >= 0;
                        },
                        tagInfo.selectedTags
                    );
                    props.callBack("oldTags", allTagsFilter);
                    if(props.hideModal !==undefined){
                        props.hideModal();
                    }
                }
            }
        }
       
    };

    const toggle = (update = false) => {

        setTagInfo({
            ...tagInfo,
            isOpen: !tagInfo.isOpen
        })

        if (update) {
            props.callBack(update);
        } else {
            props.callBack();
        }
    };

    const handleSelectCheckbox = (value) => {
        let allTags = tagInfo.selectedTags;
        if (allTags.includes(value)) {
            allTags.splice(allTags.indexOf(value), 1)
        } else {
            allTags.push(value)
        }

        setTagInfo({
            ...tagInfo,
            selectedTags: allTags,
            submitEnable: (allTags.length > 0)
        })
    }

    const changeSearch = (e) => {
        let searchKey = e.target.value.toUpperCase().trim();
        setCurrentPage(1);
        setSearch(searchKey)
        setTagInfo({
            ...tagInfo,
            createNew:false
        })
    };

    const runningList = () => {
        const ignoreTags = props.ignoreTags ?? [];
        return list
            .filter(data => !ignoreTags.includes(data.id))
            .map(data => (
                <EachTag
                    subUserList={props.subUserList}
                    selectedTags={tagInfo.selectedTags}
                    selectTag={handleSelectCheckbox}
                    data={data}
                    key={data.id}
                />
            ));
    };


    return (
        <GlobalModal {...props}
            title={"Assign Tag"}
            buttonText={isSubmitting ? "Assigning..." : tagInfo.saveButtonText}
            buttonIcon={<Save />}
            onSubmit={handleSubmit}
            hideFooter={tagInfo.createNew ? false : false}
            className={'manage-tag-modal modal-assign-campaign global-small-modal'} >
            <div className="row">
                <div className="col-md-12">
                        <form action="">
                            <label htmlFor="" className="mb-2">Type to search/create tag</label>
                            <div style={{paddingBottom:"5px"}}>
                                <BasicInput 
                                   onChange={changeSearch} 
                                   placeholder="Type and search" 
                                   fullWidth   
                                   endAdornment={
                                    <InputAdornment position="end">
                                    <SearchIcon style={{ color: "#656565" , marginRight:"13px"}} />
                                    </InputAdornment>
                                    }
                                  />
                           </div>
                        </form>
                    {(isLoading || isSubmitting) ? (
                        <TagSkeleton/>
                    ):(
                    <div className="campaign-search-list">
                        <div className="campaign-search-results">
                            {tagInfo.createNew &&
                                <div className="not_found_tag">
                                    <button className="create_new_tag" onClick={(e) => handleSubmit(e)}>Create and assign with this <strong>{search}</strong></button>
                                    <p>No tags created with <b>{search}</b></p>
                                </div>
                            }
                             <div className="all__tags__to__show__area m-nav awesome__scroll_bar update__list_item" onScroll={handleOnScrollLoad} >
                                <ul>
                                    {/* {(tagInfo.allTagsToShow && Array.isArray(tagInfo.allTagsToShow) && tagInfo.allTagsToShow.length === 0) ? '' : runningList()} */}
                                    {runningList()}
                                </ul>
                            </div>
                          
                        </div>
                    </div>)}
                </div>
            </div>
        </GlobalModal>
    )

}

const mapStateToProps = state => {
    return {
        userTagList: state.contactReducer.userTagList,
        subUserList: state.rightsideReducer.subUserList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
        storeUserTagList: (params, callback) => dispatch(setUserTagList(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignToTagNew);
