import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, InputBase, MenuItem, Modal, Select, Tooltip, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { getSubUserList, setSubUserListFromAction } from "../../../rightSide/redux/contactAction";
import { changeLeadOwner, getSubUserListForContactlist } from "../../../rightSide/redux/contactApi";
import Utils from "../../../rightSide/Helpers/Utils";
import NewConfirmAlert from "../../../../common/new-alert/NewConfirmAlert";
import { receiveBulkContactPermissionRequest } from "../../../../../api/contactApi";
import { AGENCY_BUSINESS_TYPE, leadPermission, PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../../constants/CoreConstants";
import { Skeleton } from "@material-ui/lab";
import BootstrapTooltip from "../../../../globals/BootstrapTooltip";
import Styles from "./leadstatus.module.css";
import PermissionDeniedModal from "../../../../common/PermissionDeniedModal";

export const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
  },
  input: {
    position: "relative",
    fontSize: "14px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 24px 4px 10px !important",
    borderRadius: "4px !important",
    background: "white !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&.MuiSelect-select:focus": {
      background: "white !important",
    },
    "&.MuiSelect-selectMenu .subtitle": {
      display: "none",
    },
    "&.MuiSelect-selectMenu .selectedTitle": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media(minWidth: 1300px)": {
        width: "100%",
      },
    },

    "&:focus": {
      borderRadius: 4,
      background: "white !important",
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

const LeadOwner = (props) => {
  const [show, setShow] = useState(false);
  const {loading, setLoading} = props;
  const [subUserList, setSubUserList] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState({});
  const [name, setName] = useState(ownerInfo);

  const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE; 
  const canUserEditLeadOwner =  window.hasPermission(PERMISSION_MODULES.EDIT_LEAD_OWNER,
      PERMISSION_LEVELS.WRITE);
  const [openPermissionDeniedMessageModal, setOpenPermissionDeniedMessageModal] = useState(false);

  useEffect(() => {
    if (
      props.contactDetails != null &&
      props.contactDetails !== undefined &&
      props.contactDetails.user_id !== undefined &&
      props.contactDetails.user_id != null
    ) {
      loadTeamUserList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (subUserList) {
      let newOwner = subUserList.find((user) => user.id === props.contact.user_id);

      if (newOwner) {
        setOwnerInfo(newOwner);
      }
    }
  }, [props.contact.user_id, subUserList]);

  const loadTeamUserList = () => {
    setLoading(true);
    if (props.subUserList != null) {
      setOwnerInfos(props.subUserList, props.contactDetails.user_id, true);
      setLoading(false);
    } else {
      getSubUserListForContactlist()
        .then((response) => {
          props.setSubUserList(response.data.data);
          setOwnerInfos(response.data.data, props.contactDetails.user_id, true);
        })
        .catch((error) => {
          console.log("something is wrong" + error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const setOwnerInfos = (tempSubUserList, ownerId, firstLoad = false) => {
    if (ownerId === Utils.getAccountData("userId") || Utils.getAccountData("userIsOwner")) {
      setOwnerInfo({ id: ownerId });
      setShow(true);
    } else {
      if (tempSubUserList != null) {
        for (let j = 0; j < tempSubUserList.length; j++) {
          if (tempSubUserList[j].id === ownerId) {
            setOwnerInfo({ id: ownerId, full_name: tempSubUserList[j].full_name });
            break;
          }
        }
      }
      setShow(true);
    }
    if (firstLoad) {
      setSubUserList(tempSubUserList);
    }
  };

  const handleSubmit = (user_id) => {
    const previousLeadOwner = {...ownerInfo}
    setOwnerInfos(subUserList, user_id);
    setName(user_id);
    if (user_id != "_claimLead_") {
      if (props.contactDetails.user_id === Utils.getAccountData("userId") || Utils.getAccountData("userIsOwner")) {
        changeLeadOwner({
          contact_id: props.contact.id,
          user_id: user_id,
        })
          .then((res) => {
            if (res.status === 200) {
              try {
                window.showNotification("SUCCESS", "Lead Owner Successfully Changed");
                
              } catch (e) {
                console.log(e);
              }
            } else {
              try {
                setOwnerInfo(previousLeadOwner)
                window.showNotification("ERROR", "Something was wrong, Please reload the page and try again");
              } catch (e) {
                console.log(e);
              }
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        window.showNotification("ERROR", "You cannot change lead owner");
      }
    } else {
      let message = "Your are going to send a request to lead/team owner to claim lead";
      if (Utils.getAccountData("userIsOwner")) {
        message = "As a team owner, you can claim lead ownership.";
      }

      NewConfirmAlert({
        onSubmit: () => {
          receiveBulkContactPermissionRequest({
            requestType: leadPermission.REQUEST_TYPE_CHANGE_OWNER,
            contactIds: [props.contactDetails.id],
            requestedForUserIds: [Utils.getAccountData("userId")],
            filter: ["no-filter"],
            from: "claim-lead",
          })
            .then((res) => {
              if (res.success) {
                window.showNotification("SUCCESS", res.message);
              } else {
                window.showNotification("ERROR", res.message);
              }
            })
            .catch((err) => {
              window.showNotification("ERROR", "Something went wrong. Please try again later!");
            });
        },
        title: "Are your sure?",
        description: message,
        cancelText: "No",
        submitText: "Yes",
        width: "480px",
      });
    }
  };

  const handleShowDeniedMessage = () => {
    if(isAgencyTypeInsurance && !canUserEditLeadOwner){
      setOpenPermissionDeniedMessageModal(true);
    }
  };

  return (
    <Box className={Styles.leadOwnerBoxWidth}>
      {loading ? (
        <Skeleton variant='rectangular' width={'100%'} height={'37.5px'} style={{ backgroundColor:'#fff',borderRadius:'4px'}} />        
      ) : (
        show && (
          <FormControl fullWidth sx={{ marginLeft: 2 }}>
            <Select
              value={ownerInfo?.id}
              displayEmpty
              onChange={(event) => handleSubmit(event.target.value)}
              input={<BasicInput className='basicinputbackground' />}
              inputProps={{ "aria-label": "Without label" }}
              disabled={isAgencyTypeInsurance && !canUserEditLeadOwner}
              onClick={handleShowDeniedMessage}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className='dropdownhelper-menuitem-class' value='default' disabled>
                <Typography variant='body1' color='textSecondary' style={{ lineHeight: 1 }}>
                  Lead Owner
                </Typography>
              </MenuItem>
              {subUserList?.map((subUser) => (
                <MenuItem key={subUser.id} className='dropdownhelper-menuitem-class' value={subUser.id}>
                  <BootstrapTooltip title={subUser.full_name}>
                    <Typography
                      className={`${Styles.leadOwnerTextWidth} selectedTitle`}
                      variant='body1'
                      color='textSecondary'
                      style={{ lineHeight: 1.2 }}
                    >
                      {" "}
                      {subUser.full_name}
                    </Typography>
                  </BootstrapTooltip>
                </MenuItem>
              ))}

              {props.contactDetails.user_id !== Utils.getAccountData("userId") && (
                <MenuItem style={{ flexDirection: "column", alignItems: "flex-start" }} value={"_claimLead_"}>
                  <Button fullWidth variant='contained' color='primary'>
                    Claim Lead
                  </Button>
                </MenuItem>
              )}

              {/* {US_STATE_LIST.map((state) => (
            <MenuItem key={state.value} className='dropdownhelper-menuitem-class' value={state.value}>
              {state.label}
            </MenuItem>
          ))} */}
            </Select>
          </FormControl>
        )
      )}

      {openPermissionDeniedMessageModal && (
        <PermissionDeniedModal 
          open={openPermissionDeniedMessageModal} 
          onClose={() => setOpenPermissionDeniedMessageModal(false)}
          permissionModule={"lead owner"}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    allContactList: state.rightsideReducer.allContactList,
    userInfo: state.rightsideReducer.userInfo,
    subUserList: state.rightsideReducer.subUserList,
    contact: state.rightsideReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
    getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadOwner);
