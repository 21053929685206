import { makeStyles } from "@material-ui/core";

const AddListModalStyle = makeStyles((theme) => ({
    modalOverlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContainer: {
      width: "630px",
      background: "white",
      borderRadius: "14px",
      boxShadow: theme.shadows[5],
      outline: "none",
    },
    header: {
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      gap: "5px",
    },
    logoContainer: {
      width: "60px",
      height: "60px",
    },
    body:{
      padding: "15px",
    },
    logo: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    title: {
      fontSize: "20px",
      fontWeight: 600,
      paddingLeft: "5px",
    },
    divider: {
      borderTop: "1px solid #ccc",
      margin: "0",
    },
    description: {
      padding: "5px",
      textAlign: "center",
      whiteSpace: "pre-line",
      fontWeight:600,
      fontSize: "17px",
    },
    inputContainerTitle: {
      padding: "5px",
      marginBottom: "8px",
    },
    inputContainerDescription: {
      padding: "5px",
    },
    errorMessage: {
      color: "red",
      fontSize: "12px",
      padding: "0px 5px",
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
      padding: "5px",
      marginTop: "20px",
    },
    button: {
      padding: "8px 16px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    closeButton: {
      backgroundColor: "#fff",
      color: "#000",
      border:"2px solid #000",
      "&:focus, &:hover": {
        backgroundColor: "#f3f4f6",
        transform: "translateY(-1px)",
        border:"2px solid #000"
      },
    },
    confirmButton: {
      backgroundColor: "#3e50f7",
      color: "#fff",
      border:"2px solid #3e50f7",
      "&:focus, &:hover": {
        border:"2px solid #3e50f7",
        backgroundColor: "#3e50f7",
        transform: "translateY(-1px)",
      },
    },
    text: {
      fontSize: "14px",
      padding: "0px",
      margin: "0px",
    },
    textType: {
      fontWeight: 700,
    },
  }));

export default AddListModalStyle;