import React, {useState} from 'react';
import useStyles from "./useStyles";
import {
    Backdrop,
    Button,
    Fade,
    Modal,
    Typography
} from "@material-ui/core";

const HelpModal = ({ modalHeaderTitle="Help", children, backButtonText="Back", HelpIcon, modalHeaderIcon }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.wrapperMain}>
            <HelpIcon onClick={() => setOpen(true)}/>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.modalHeader}>
                            <div style={{width: "50px", height: "50px"}}>
                                {modalHeaderIcon}
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                                <Typography
                                    style={{
                                        height: "unset !important",
                                        color: "#133159",
                                        fontWeight: 600,
                                    }}
                                    variant='h5'
                                >
                                    {modalHeaderTitle}
                                </Typography>
                            </div>
                        </div>

                        <div className={classes.modalBody}>
                            {children}
                        </div>
                        <div className={classes.modalFooter}>
                            <Button onClick={onClose} className={classes.backButton}>
                                {backButtonText}
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default HelpModal;