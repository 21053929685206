import { Add as AddIcon } from "@material-ui/icons";
import React from "react";
import { BasicButton } from "../Products/ProductStyles";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop:"10px"
  },
});
const EmptyPolicies = ({ setOpenPolicyModal }) => {
  const PolicyImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/policy/1.0.0/policy_types_icon.png`;
  const classes = useStyles();
  return (
    <div>
      <div className={classes.imgContainer}>
        <img height={150} src={PolicyImg} alt='Empty data' />
      </div>
      <div className={classes.buttonContainer}>
        <BasicButton
          startIcon={<AddIcon />}
          onClick={() => {
            setOpenPolicyModal(true);
          }}
        >
          New Policy
        </BasicButton>
      </div>
    </div>
  );
};

export default EmptyPolicies;
