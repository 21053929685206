const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`;

export default {
    uploadCsvFile: `${tenantBackendV1}/contact/upload-contact-file`,
    loadInitialData: `${tenantBackendV1}/contact/upload-dropdown-data`,
    loadPipelineData: `${tenantBackendV1}/deal/pipeline/dropdown-data`,
    loadStageData: `${tenantBackendV1}/deal/pipeline/stage-dropdown-data`,
    loadCampaignData: `${tenantBackendV1}/campaign/dropdown-data`,
    loadCustomFieldData: `${tenantBackendV1}/contact/user-custom-fields`,
    uploadCsvFileData: `${tenantBackendV1}/contact/imported-file-lists/contact-file`,
    singleImportFile: `${tenantBackendV1}/contact/imported-file-lists/single`,
    googleAuthUrl: `${tenantBackendV1}/contact/google/auth-url`,
    googleWorksheetInfo: `${tenantBackendV1}/contact/google/get-worksheet-info`,
    processRefreshToken: `${tenantBackendV1}/contact/google/process-refresh-token`,
    readSpreadsheet: `${tenantBackendV1}/contact/google/read-spreadsheet`,
    userConnectedFiles: `${tenantBackendV1}/contact/user-connected-files`,
    userConnectedFilesSingle: `${tenantBackendV1}/contact/user-connected-files/single-data`,
}