import {createSlice} from '@reduxjs/toolkit';
import {
    AGENCY_BUSINESS_TYPE,
    FILE_IMPORT_TAB, IMPORT_CONTACT_INSURANCE_STANDARD,
    IMPORT_CONTACT_STEP_UPLOAD,
    IMPORT_CONTACT_V2_COMMUNICATION_EMAIL,
    IMPORT_CONTACT_V2_CONTACT_ADDRESS,
    IMPORT_CONTACT_V2_CONTACT_DETAILS,
    IMPORT_CONTACT_V2_PRIMARY_PHONE,
    IMPORT_CONTACT_V2_RELATED_CONTACT1,
    IMPORT_CONTACT_V2_RELATED_CONTACT2,
    IMPORT_CONTACT_V2_RELATED_CONTACT3,
    IMPORT_CONTACT_V2_RELATED_CONTACT4,
    IMPORT_CONTACT_V2_RELATED_CONTACT5,
    IMPORT_CONTACT_V2_SECONDARY_PHONE1,
    IMPORT_CONTACT_V2_SECONDARY_PHONE2,
    IMPORT_CONTACT_V2_SECONDARY_PHONE3,
    IMPORT_CONTACT_V2_SECONDARY_PHONE4,
    PROCESS_MODE_VALUE
} from '../../constants/CoreConstants';
import Utils from "../../helpers/Utils";
import moment from "moment";

let userTimeNow = window.globalTimezoneConversionToDifferentTimezone ? window.globalTimezoneConversionToDifferentTimezone(new Date()) : new Date(moment.tz(Utils.getAccountData("userTimezoneRaw")));

const reformatDate = (inputDate) => {
    const [month, day, year] = inputDate.split("-");
    return `${year}-${month}-${day}`;
};

const appendToArrayAtIndex = (array, index, item) => {
    return [...array.slice(0, index), item, ...array.slice(index)];
};

const initialMappingFields = [
    {
        label: "Details",
        options: IMPORT_CONTACT_V2_CONTACT_DETAILS
    },
    {
        label: "Emails",
        options: IMPORT_CONTACT_V2_COMMUNICATION_EMAIL
    },
    {
      label: "Primary Phone",
      options: IMPORT_CONTACT_V2_PRIMARY_PHONE
    },
    {
        label: "Phone (1)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE1
    },
    {
        label: "Phone (2)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE2
    },
    {
        label: "Phone (3)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE3
    },
    {
        label: "Phone (4)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE4
    },
    {
        label: "Address",
        options: IMPORT_CONTACT_V2_CONTACT_ADDRESS
    },
    {
        label: "Related Contacts (1)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT1
    },
    {
        label: "Related Contacts (2)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT2
    },
    {
        label: "Related Contacts (3)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT3
    },
    {
        label: "Related Contacts (4)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT4
    },
    {
        label: "Related Contacts (5)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT5
    }
];

const initialDetails = {
    fileUrl: "",
    file: null,
    fileName: "",
    leadSourceId: "",
    existingContactRule: 2,
    pipelineId: "",
    stageId: "",
    tagIds: [""],
    leadOwnerId: Utils.getAccountData("userId"),
    smartListId: "",
    campaignId: "",
    userConsent: true,
    powerDialerListId: ""
};

const initialProcessMode = {
    dripMode: PROCESS_MODE_VALUE.ONCE,
    startDate: reformatDate(userTimeNow.split(" ")[0]),
    startTime: userTimeNow.split(" ")[1],
    batchQuantity: "",
    repeatAfter: "",
    processType: 1,
    weekDays: { Sun: true, Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true }
};

const initialAccordion = {
    isPipelineExpand: false,
    isCampaignExpand: false,
};

const initialState = {
    activeTab: IMPORT_CONTACT_STEP_UPLOAD,
    completedSteps: [],

    mappingFields: initialMappingFields,
    mappedFields: {},
    alreadyMappedFields: [],
    mappedRows: [],
    unmappedRows: [],

    error: null,
    fileUploadSuccess: false,
    importContactOptionTab: FILE_IMPORT_TAB.TAB_DEFAULT,

    loadingInitialData: false,
    leadSources: [],
    teamUsers: [],
    tags: [],
    smartLists: [],

    loadingPipelines: false,
    pipelines: [],
    loadingStages: false,
    stages: [],

    loadingCampaigns: false,
    campaigns: [],

    accordion: initialAccordion,

    details: initialDetails,

    processMode: initialProcessMode,

    summary: null,

    referer: null
};

const importContactSlice = createSlice({
    name: 'importContact',
    initialState,
    reducers: {
        loadInitialDataStart: (state, _action) => {
            state.loadingInitialData = true;
        },
        loadInitialDataSuccess: (state, action) => {
            state.loadingInitialData = false;
            if (action.payload.leadSources){
                state.leadSources = action.payload.leadSources;
                if (action.payload.leadSources[0]){
                    state.details.leadSourceId = action.payload.leadSources[0].id;
                }
            }

            if (action.payload.teamUsers){
                state.teamUsers = action.payload.teamUsers;
            }

            if (action.payload.tags){
                state.tags = action.payload.tags;
                state.details.tagIds = [""];
            }

            if (action.payload.smartLists){
                state.smartLists = action.payload.smartLists;
                state.details.smartListId = "";
            }

            if (Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE){
                state.mappingFields = appendToArrayAtIndex(state.mappingFields, 1,{
                    label: "Standard Fields",
                    options: IMPORT_CONTACT_INSURANCE_STANDARD
                });
            }

            if (action.payload.customFields){
                state.mappingFields = [ ...state.mappingFields, { label: "Custom Fields", options: action.payload.customFields } ];
            }
        },
        loadInitialDataFail: (state) => {
            state.loadingInitialData = false;
        },
        loadPipelineDataStart: (state, _action) => {
            state.loadingPipelines = true;
            state.details.pipelineId = "";
        },
        loadPipelineDataSuccess: (state, action) => {
            state.loadingPipelines = false;
            state.pipelines = action.payload;
            state.stages = [];
        },
        loadPipelineDataFail: (state) => {
            state.loadingPipelines = false;
        },
        loadCampaignDataStart: (state, _action) => {
            state.loadingCampaigns = true;
            state.details.campaignId = "";
        },
        loadCampaignDataSuccess: (state, action) => {
            state.loadingCampaigns = false;
            state.campaigns = action.payload;
        },
        loadCampaignDataFail: (state) => {
            state.loadingCampaigns = false;
        },
        loadStageDataStart: (state) => {
            state.stages = [];
            state.loadingStages = true;
            state.details.stageId = "";
        },
        loadStageDataSuccess: (state, action) => {
            state.loadingStages = false;
            state.stages = action.payload;
        },
        loadStageDataFail: (state) => {
            state.loadingStages = false;
        },
        updateActiveStepAndCompletedStep: (state, action) => {
            state.activeTab = action.payload.activeTab;
            state.completedSteps = action.payload.completedSteps;
        },
        updateMappedFields: (state, action) => {
            if (action.payload.mappedFields) state.mappedFields = action.payload.mappedFields;
            if (action.payload.alreadyMappedFields) state.alreadyMappedFields = action.payload.alreadyMappedFields;
            if (action.payload.mappedRows) state.mappedRows = action.payload.mappedRows;
            if (action.payload.unmappedRows) state.unmappedRows = action.payload.unmappedRows;
        },
        uploadCsvFileStart: (state, action) => {
            state.details.file = action.payload.get("file");
            state.details.fileName = action.payload.get("file").name;
        },
        uploadCsvFileSuccess: (state, action) => {
            state.fileUploadSuccess = true;
            state.details.fileUrl = action.payload.url;
        },
        uploadCsvFileFail: (state) => {
            state.fileUploadSuccess = false;
            state.details.fileUrl = "";
        },
        updateImportContactOptionTab: (state, action) => {
            state.importContactOptionTab = action.payload.importContactOptionTab;
        },
        updateDetails: (state, action) => {
            state.details = { ...state.details, ...action.payload };
        },
        updateAccordion: (state, action) => {
            state.accordion = { ...state.accordion, ...action.payload };
        },
        updateProcessMode: (state, action) => {
            state.processMode = { ...state.processMode, ...action.payload };
        },
        resetToInitial: (state) => {
            state.activeTab = IMPORT_CONTACT_STEP_UPLOAD;
            state.completedSteps = [];
            state.mappingFields = initialMappingFields;
            state.mappedFields = {};
            state.alreadyMappedFields = [];
            state.mappedRows = [];
            state.unmappedRows = [];
            state.accordion = initialAccordion;
            state.details = initialDetails;
            state.processMode = initialProcessMode;
            state.referer = null;
        },
        updateSummary: (state, action) => {
            state.summary = action.payload;
        },
        updateTags : (state, action) => {
            state.tags = [action.payload, ...state.tags]
            state.details.tagIds = state.details.tagIds[0] ? [action.payload.id, ...state.details.tagIds] : [action.payload.id]
        },
        updateSmartList : (state, action) => {
            state.smartLists = [action.payload, ...state.smartLists]
            state.details.smartListId = action.payload.id

        },
        updateImportReferer: (state, action) => {
            state.referer = action.payload;
        },
    },
});

export const {
    loadInitialDataStart,
    loadInitialDataSuccess,
    loadInitialDataFail,
    updateActiveStepAndCompletedStep,
    updateMappedFields,
    uploadCsvFileStart,
    uploadCsvFileSuccess,
    uploadCsvFileFail,
    updateImportContactOptionTab,
    loadPipelineDataStart,
    loadPipelineDataSuccess,
    loadPipelineDataFail,
    loadStageDataStart,
    loadStageDataSuccess,
    loadStageDataFail,
    loadCampaignDataStart,
    loadCampaignDataSuccess,
    loadCampaignDataFail,
    updateDetails,
    updateAccordion,
    updateProcessMode,
    resetToInitial,
    updateSummary,
    updateTags,
    updateSmartList,
    updateImportReferer
} = importContactSlice.actions;

export default importContactSlice.reducer;
