import React from 'react';
import { Button } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import AddIcon from '@material-ui/icons/Add';
import CustomCircleLoader from "../../common/Loader/CustomCircleLoader";
import { IconList } from '../../globals/IconList';

const ClearListButton = ({
  classes,
  startingSession,
  handleClickSessionStart,
  setOpenCreateListModal,
  contactListSelectedSource,
  contactListSearchTextInTop,
  contactListSelectedTag,
  contactListSelectedStage,
  contactListSelectedFile,
  contactListSelectedSubUser,
  contactListSelectedCampaign,
  contactListSelectedOperationList,
  contactListSelectedColumnFilterData,
  contactListSelectedFromDate,
  contactListSelectedEndDate,
  contactListpageSize,
  contactListDatePickerActive,
  contactListSelectedSavedContactList,
  contactListSelectedContactIds,
  clearAllFilter,
  defaultColumnFilterData
}) => {
  if (
    contactListSelectedSource !== '' ||
    contactListSearchTextInTop !== '' ||
    contactListSelectedTag !== '' ||
    contactListSelectedStage !== '' ||
    contactListSelectedFile !== '' ||
    contactListSelectedSubUser.length > 1 ||
    contactListSelectedCampaign !== '' ||
    JSON.stringify(contactListSelectedOperationList) !== JSON.stringify(defaultColumnFilterData) ||
    JSON.stringify(contactListSelectedColumnFilterData) !== "{}" ||
    contactListSelectedFromDate !== '' ||
    contactListSelectedEndDate !== '' ||
    contactListpageSize !== 50 ||
    contactListDatePickerActive !== false ||
    JSON.stringify(contactListSelectedSavedContactList) !== JSON.stringify({ status: false })
  ) {
    return (
      <>
        <div>
          <Button onClick={handleClickSessionStart} className={classes.smartListButton}>
            {startingSession ? (
              <CustomCircleLoader
                style={{ display: "inline-flex", marginRight: "5px" }}
                fill='#fff'
                size='extra-small'
              />
            ) : (
              <PhoneIcon />
            )}
            <span style={{ marginTop: "2px", marginLeft: "4px" }}>
              {startingSession ? "Starting Dialing Session" : "Start Dialing Session"}
            </span>
          </Button>
        </div>
        <div>
          <Button
            variant='contained'
            color='primary'
            className={classes.smartListButton}
            onClick={() => setOpenCreateListModal(true)}
          >
            <AddIcon />
            <span style={{ marginTop: "2px", marginLeft: "4px" }}>New Smart List</span>
          </Button>
        </div>

        <span
          className='align-items-center clear_list_contact_Button cursor-pointer d-flex'
          onClick={clearAllFilter}
        >
          {IconList.removeFilterIcon}
          {` remove Filter`}
        </span>
      </>
    );
  } else if (contactListSelectedContactIds && contactListSelectedContactIds.length > 0) {
    return (
      <>
        <div>
          <Button onClick={handleClickSessionStart} className={classes.smartListButton}>
            {startingSession ? (
              <CustomCircleLoader
                style={{ display: "inline-flex", marginRight: "5px" }}
                fill='#fff'
                size='extra-small'
              />
            ) : (
              <PhoneIcon />
            )}
            <span style={{ marginTop: "2px", marginLeft: "4px" }}>
              {startingSession ? "Starting Dialing Session" : "Start Dialing Session"}
            </span>
          </Button>
        </div>
        <div>
          <Button
            variant='contained'
            onClick={() => setOpenCreateListModal(true)}
            className={classes.smartListButton}
          >
            <AddIcon />
            <span style={{ marginTop: "2px", marginLeft: "4px" }}>New Smart List</span>
          </Button>
        </div>
      </>
    );
  }

  return null;
};

export default ClearListButton;