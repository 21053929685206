/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useHistory } from "react-router-dom";
import SummaryTableItem from "./SummaryTableItem";
import useDebounce from "../../hooks/useDebounce";
import {
  deleteImportContactFiles,
  getImportedContactSummary,
  getImportedSpreadsheetContactSummary
} from "../../api/contactApi";
import ImportedGoogleSheetTableSkeleton from "../importContactsV2/ImportGoogleSheet/ImportedGoogleSheetTableSkeleton";
import { Skeleton } from "@material-ui/lab";
import AssignToCampaignModal from "../importContactsV2/AssignToCampaignModal";
import CustomDeleteModal from "../common/CustomDeleteModal";
import FileSummaryStatusPopover from "./popover/FileSummaryStatusPopover";
import DynamicEmptyPage from "../common/DynamicEmptyPage";
import BasicInputForSearch from "../common/BasicInputForSearch";
import { searchIcon } from "./icon";
import SettingDetailsDrawer from "./drawer/SettingDetailsDrawer";

const fileImage = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/summary/1.0.0/document_icon.png`;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 20,
    "& .headerWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 8,
      "& .title": {
        fontSize: "20px",
        margin: 0,
      },
      "& .backButton": {
        "& .MuiSvgIcon-root": {
          color: "white !important",
        },
      },
    },

    "& .contentWrapper": {
      backgroundColor: "#fff",
      borderRadius: 8,

      "& .filter": {
        padding: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& .buttonWrapper": {
          display: "flex",
          alignItems: "center",
          gap: 8,

          "& .MuiButtonBase-root": {
            "&:hover, &:focus": {
              backgroundColor: "#e0e0e0",
            },
          },
        },
      },

      "& .notFoundWrapper": {
        height: "calc(100vh - 340px)",
        fontWeight: 500,
        fontSize: 22,
        display: "grid",
        placeContent: "center",
      },
    },
  },
  tablePagination: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: 16,
    marginBottom: 16,
    borderBlock: "1px solid #1D29391A",

    "& .contactCount": {
      color: "#475467",
    },

    "& .MuiTablePagination-actions": {
      display: "flex",

      "& .MuiIconButton-root": {
        padding: "6px !important",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  tableWrapper: {
    padding: "0 16px 16px 16px",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 360px)",
    overflow: "auto",
    maxWidth: "100%",
    scrollbarWidth: "thin",
    scrollbarColor: "#CBD5E1 transparent", // Change color as needed
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CBD5E1", // Change color as needed
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Change color as needed
    },
  },
  tableCellHeader: {
    minWidth: "220px",
    border: "1px solid #ddd",
    backgroundColor: "#F5F5F6",
    fontWeight: 600,
    fontSize: "14px",
    padding: "10px 20px",
    color: "#757F8E",
    textTransform: "uppercase",
  },
  tableCellActionHeader: {
    minWidth: "360px",
    border: "1px solid #ddd",
    backgroundColor: "#F5F5F6",
    fontWeight: 600,
    fontSize: "14px",
    padding: "10px 20px",
    color: "#757F8E",
    textTransform: "uppercase",
  },
  stickyFirstColumn: {
    position: "sticky",
    left: 0,
    border: "1px solid #ddd",
    backgroundColor: "#F5F5F6",
    fontWeight: 600,
    fontSize: "16px",
    padding: "10px 20px",
    color: "#757F8E",
    textTransform: "uppercase",
  },
}));

const FileSummary = () => {
  const classes = useStyles();
  const history = useHistory();
  const [fileList, setFileList] = useState([]);

  const [apiParams, setApiParams] = useState({
    page: 1,
    limit: 10,
    search: null,
    status: null,
  });

  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState(null);
  const [popover, setPopover] = useState(null);
  const [showCampaignAssignModal, setShowCampaignAssignModal] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [openSettingDrawer, setOpenSettingDrawer] = useState(false);

  const search = useDebounce(searchInput, 500);

  useEffect(() => {
    getSummaryList();
  }, [apiParams]);

  useEffect(() => {
    if (search !== null) {
      setApiParams((prev) => ({ ...prev, search, page: 1 })); // Reset page to 1 on search change
    }
  }, [search]);

  const getSummaryList = async () => {
    try {
      setIsLoading(true);
      const response = await getImportedSpreadsheetContactSummary(apiParams);

      if (response.success && response.data && response.data[0]) {
        setFileList(response.data);
        if (apiParams.page === 1) {
          setTotal(response.total_count);
        }
      } else {
        setFileList([]);
        setTotal(0);
      }
    } catch (error) {
      console.error("Failed to load File List:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setApiParams((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    setApiParams((prev) => ({ ...prev, limit: event.target.value, page: 1 })); // Reset page to 1 on row count change
  };

  const handlePopover = (event) => {
    event.stopPropagation();
    setPopover(popover ? null : event.currentTarget);
  };
  const handleClosePopover = () => {
    setPopover(null);
  };
  const openPopover = Boolean(popover);
  const openId = openPopover ? "simple-action-popper" : undefined;

  const setStatus = (value) => {
    setApiParams((prev) => ({ ...prev, status: value, page: 1 })); // Reset page to 1 on status change
  };

  const refreshPage = (event) => {
    if (apiParams.page === 1) {
      setApiParams((prev) => ({ ...prev }));
    } else {
      setApiParams((prev) => ({ ...prev, page: 1 })); // Reset page to 1 on refresh
    }
  };

  const handleShowCampaignAssignModal = (fileId) => {
    setShowCampaignAssignModal(true);
    setFileId(fileId);
  };

  const handleConfirmDeletion = async () => {
    try {
      const response = await deleteImportContactFiles({ fileListId: deleteItem.id });
      if (response.success) {
        window.showNotification("SUCCESS", deleteItem.title + " " + response.message);
        refreshPage();
        setDeleteItem(null);
      }
    } catch (error) {
      window?.showNotification("ERROR", "Something went wrong!");
    }
  };

  return (
    <div className={classes.root}>
      <div className='headerWrapper'>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <img src={fileImage} height={70} alt='' />
          <h4 className='title'>Google Sheets</h4>
        </div>
        <Button
          variant='contained'
          color='primary'
          startIcon={<ArrowBackIcon />}
          disableElevation
          onClick={() => history.push("/import-contacts")}
          className='backButton'
        >
          Back
        </Button>
      </div>

      <div className='contentWrapper'>
        <div className='filter'>
          <BasicInputForSearch
            placeholder='Search a file'
            endAdornment={
              <InputAdornment position='start' style={{ paddingLeft: "18px" }}>
                <span>{searchIcon({ width: 20, height: 20 })}</span>
              </InputAdornment>
            }
            onChange={(e) => setSearchInput(e.target.value)}
          />

          <div className='buttonWrapper'>
            <Button
              variant='contained'
              color='inherit'
              startIcon={<RefreshIcon />}
              disableElevation
              onClick={refreshPage}
            >
              Refresh
            </Button>
            <Button
              variant='contained'
              color='inherit'
              endIcon={<ExpandMoreIcon />}
              disableElevation
              onClick={handlePopover}
            >
              Filter By Status
            </Button>

            <FileSummaryStatusPopover
              id={openId}
              open={openPopover}
              popover={popover}
              onClose={handleClosePopover}
              status={apiParams.status}
              setStatus={setStatus}
            />
          </div>
        </div>

        <div className={classes.tablePagination}>
          {!isLoading ? (
            <span className='contactCount'>
              Total {total} File{total > 1 && "s"}
            </span>
          ) : (
            <Skeleton variant='text' width={120} />
          )}

          <TablePagination
            component='div'
            count={total}
            page={apiParams.page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={apiParams.limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        {isLoading && (
          <div style={{ padding: 16 }}>
            <ImportedGoogleSheetTableSkeleton />
          </div>
        )}

        {!isLoading && !fileList[0] && (
          <div style={{ height: "100%" }}>
            <DynamicEmptyPage />
          </div>
        )}

        {!isLoading && fileList[0] && (
          <div className={classes.tableWrapper}>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table stickyHeader aria-label='scrollable table'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={`${classes.tableCellHeader} ${classes.stickyFirstColumn}`}
                      style={{ fontSize: 14, minWidth: 300, zIndex: 3 }}
                    >
                      File Name
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      Status
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      Total Contacts
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      New Contacts
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      Updated Contacts
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      Related Contacts
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      Duplicate Email
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      Duplicate Phone{" "}
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align='center'>
                      Invalid Contact
                    </TableCell>
                    <TableCell className={classes.tableCellActionHeader} align='center'>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {fileList.map((row) => (
                    <SummaryTableItem
                      key={row.id}
                      item={row}
                      handleAssignCampaign={handleShowCampaignAssignModal}
                      handleSettingDetails={setOpenSettingDrawer}
                      handleDelete={setDeleteItem}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>

      {showCampaignAssignModal && (
        <AssignToCampaignModal
          open={showCampaignAssignModal}
          onClose={() => setShowCampaignAssignModal(false)}
          fileId={fileId}
        />
      )}

      <Dialog
        open={deleteItem}
        PaperProps={{
          style: {
            borderRadius: "14px",
          },
        }}
      >
        <CustomDeleteModal
          title='Notice'
          onClose={() => setDeleteItem(null)}
          handleConfirm={handleConfirmDeletion}
          closeButtonText='Close'
          confirmButtonText='Delete'
          showConfirmButton={true}
          showCloseButton={true}
          placeholder='Type File name'
          imgSrc={fileImage}
          descriptionItem={deleteItem?.title || "file"}
          descriptionAction='Delete'
          descriptionActionColor='#ef244a'
          description
          // confirmationText={"DELETE FILE"}
        />
      </Dialog>

      {openSettingDrawer && (
          <SettingDetailsDrawer
              userConnectedFileId={openSettingDrawer}
              open={openSettingDrawer}
              onClose={() => setOpenSettingDrawer(false)}
          />
      )}
    </div>
  );
};

export default FileSummary;
