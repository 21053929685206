import React from "react";
import { Typography } from "@material-ui/core";
import { EMPTY_SCREEN_IMG } from "../../constants/CoreConstants";

const EmptyReportLog = ({ setOpenProductModal }) => {

    return(
        <div style={{display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center',marginTop:'12px'}}>
        <div>
          <img
            height={250}
            src={EMPTY_SCREEN_IMG}
            alt="Empty data"
          />
        </div>
        <div style={{display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Typography style={{ height: "unset !important",textAlign:'center' }} variant="h5">
            {/* No Invalid Data Available */}
           
We're updating this page to provide a better report. Thank you for your patience. <br/>
New reports will be available soon!
            </Typography>
        </div>
      </div>
    );
};

export default EmptyReportLog;