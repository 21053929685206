import React, { useState, useEffect } from "react";
import { makeStyles, Modal } from "@material-ui/core";
import { BasicInput } from "../../leftSideV2/LeftSideV2Styles";
import AddListModalStyle from "./AddListModalStyle";

const AddListModal = ({
  onClose,
  handleConfirm,
  closeButtonText = "Close",
  confirmButtonText = "Add List",
  showConfirmButton = true,
  showCloseButton = true,
  inputLabelFieldTitle = "Enter Name",
  inputDescriptionFieldTitle="Enter Description",
  description = "",
  imgSrc = "",
  title = "",
  open,
}) => {
  const classes = AddListModalStyle();
  const [listTitle, setListTitle] = useState("");
  const [listDescription, setListDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (open) {
      setListTitle("");
      setListDescription("");
      setErrorMessage("");
    }
  }, [open]);

  const handleTitleChange =(e) => {
    setListTitle(e.target.value);
    setErrorMessage("");
  }

  const handleDescriptionChange = (e) => {
    setListDescription(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = () => {
    if (!listTitle || !listDescription) {
      setErrorMessage("Please fill in both title and description fields.");
    } else {
      onClose();
      handleConfirm(listTitle, listDescription);
    }
  };

  const handleClose =() => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalOverlay}
    >
      <div className={classes.modalContainer}>
        <div className={classes.header}>
          {imgSrc && (
            <div className={classes.logoContainer}>
              <img src={imgSrc} alt="logo" className={classes.logo} />
            </div>
          )}
          {title && <span className={classes.title}>{title}</span>}
        </div>
        <hr className={classes.divider} />
        <div className={classes.body}>
          {description && (
            <div className={classes.description}>{description}</div>
          )}
          <div className={classes.inputContainerTitle}>
            <div>{inputLabelFieldTitle}</div>
            <BasicInput
              value={listTitle}
              onChange={handleTitleChange}
              placeholder="Enter list title"
              fullWidth
            />
          </div>
          <div className={classes.inputContainerDescription}>
            <div>{inputDescriptionFieldTitle}</div>
            <BasicInput
              value={listDescription}
              onChange={handleDescriptionChange}
              placeholder="Enter list description"
              fullWidth
              multiline
              rows={4}
            />
          </div>
          {errorMessage && (
            <div className={classes.errorMessage}>{errorMessage}</div>
          )}
          <div className={classes.actions}>
            {showCloseButton && (
              <button
                onClick={handleClose}
                className={`${classes.button} ${classes.closeButton}`}
              >
                {closeButtonText}
              </button>
            )}
            {showConfirmButton && (
              <button
                onClick={handleSubmit}
                className={`${classes.button} ${classes.confirmButton}`}
              >
                {confirmButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddListModal;