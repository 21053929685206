import { MenuItem, MenuList, Paper, Popover, styled } from "@material-ui/core";
import React from "react";
import { CONTACT_SUMMARY_STATUS } from "../../../constants/CoreConstants";

const Wrapper = styled(Paper)(({ theme }) => ({
  borderRadius: "6px",
  "& .actionList": {
    paddingInline: 8,
    "& .MuiListItem-root": {
      color: "var(--dark_blue)",
      borderRadius: "6px",
      minWidth: "150px",
    },
  },
}));

const FileSummaryStatusPopover = ({ id, open, popover, onClose, status, setStatus }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={popover}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Wrapper elevation={3}>
        <MenuList className='actionList'>
          <MenuItem
            selected={status === null}
            onClick={() => {
              setStatus(null);
              onClose();
            }}
          >
            All
          </MenuItem>
          <MenuItem
            selected={status === CONTACT_SUMMARY_STATUS.PENDING}
            onClick={() => {
              setStatus(CONTACT_SUMMARY_STATUS.PENDING);
              onClose();
            }}
          >
            Pending
          </MenuItem>
          <MenuItem
            selected={status === CONTACT_SUMMARY_STATUS.QUEUED}
            onClick={() => {
              setStatus(CONTACT_SUMMARY_STATUS.QUEUED);
              onClose();
            }}
          >
            Queued
          </MenuItem>
          <MenuItem
            selected={status === CONTACT_SUMMARY_STATUS.PROCESSING}
            onClick={() => {
              setStatus(CONTACT_SUMMARY_STATUS.PROCESSING);
              onClose();
            }}
          >
            Processing
          </MenuItem>
          <MenuItem
            selected={status === CONTACT_SUMMARY_STATUS.COMPLETE}
            onClick={() => {
              setStatus(CONTACT_SUMMARY_STATUS.COMPLETE);
              onClose();
            }}
          >
            Complete
          </MenuItem>
          <MenuItem
            selected={status === CONTACT_SUMMARY_STATUS.FAILED}
            onClick={() => {
              setStatus(CONTACT_SUMMARY_STATUS.FAILED);
              onClose();
            }}
          >
            Failed
          </MenuItem>
        </MenuList>
      </Wrapper>
    </Popover>
  );
};

export default FileSummaryStatusPopover;
