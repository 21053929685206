import React from "react";
import { Button, Chip, IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CONTACT_SUMMARY_STATUS } from "../../constants/CoreConstants";
import Utils from "../../helpers/Utils";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import { icons } from "./icon";
const userTimezone = Utils.getAccountData("userTimezoneRaw");

const useStyles = makeStyles((theme) => ({
  tableCellBody: {
    border: "1px solid #ddd",
    fontWeight: 500,
    fontSize: "12px",
    padding: "10px 20px",
  },
  tableCellActionBody: {
    border: "1px solid #ddd",
    padding: "10px 20px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    gap: 5,
  },
  customButton: {
    borderRadius: 20,
    borderColor: "#346FEF",
    padding: "5px 10px",
    lineHeight: " 1px",
    height: "25px",
    "&:hover": {
      borderColor: "#346FEF",
      backgroundColor: "white",
    },
    "&:focus": {
      borderColor: "#346FEF",
      backgroundColor: "white",
    },
  },
  chip: {
    color: "#ffffff",
    height: "25px",
  },
  customIconButton: {
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      backgroundColor: "white",
    },
  },
  stickyFirstColumn: {
    position: "sticky",
    left: 0,
    backgroundColor: "#ffffff",
    border: "1px solid #ddd",
    fontWeight: 500,
    fontSize: "16px",
    padding: "10px 20px",
    textTransform: "uppercase",
    "& .titleWrapper": {
      maxWidth: 290,
      fontSize: 13,
      fontWeight: 600,
      color: "#133159",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      cursor: "pointer"
    },
    "& .dateWrapper": { color: "#546376", fontSize: 12 },
  },
}));

const FILE_LOG_CONFIG = {
  START_FROM_FILE_ID: 74905
};

const SummaryTableItem = ({ item, refreshPage, handleAssignCampaign, handleDelete, handleSettingDetails }) => {
  const history = useHistory();
  const classes = useStyles();

  const renderStatusChip = () => {
    switch (item.status) {
      case CONTACT_SUMMARY_STATUS.PENDING:
        return <Chip label={"Pending"} className={classes.chip} style={{ backgroundColor: "#F2994A" }} />;
      case CONTACT_SUMMARY_STATUS.QUEUED:
        return <Chip label={"Queued"} className={classes.chip} style={{ backgroundColor: "#005F37" }} />;
      case CONTACT_SUMMARY_STATUS.PROCESSING:
        return <Chip label={"Processing"} className={classes.chip} style={{ backgroundColor: "#00969C" }} />;
      case CONTACT_SUMMARY_STATUS.COMPLETE:
        return <Chip label={"Completed"} className={classes.chip} style={{ backgroundColor: "#808080" }} />;
      case CONTACT_SUMMARY_STATUS.FAILED:
        return <Chip label={"Failed"} className={classes.chip} style={{ backgroundColor: "#A9000F" }} />;
      default:
        return "Status not found";
    }
  };

  const renderFileUrl = (item) => {
    if (item.awsInfo && item.awsInfo.fileUrl) {
      return item.awsInfo.fileUrl;
    }

    return null;
  };

  return (
    <TableRow key={item.id}>
      <TableCell className={`${classes.stickyFirstColumn}`} style={{ minWidth: 300, zIndex: 1 }}>
        <div className='titleWrapper' onClick={() => {
          if (FILE_LOG_CONFIG.START_FROM_FILE_ID <= item.id){
            history.push(`/import-contact-details?fileListId=${item.id}`)
          }
        }}>
          {item?.title}
        </div>

        <div className='dateWrapper'>
          {window.globalTimezoneConversionToDifferentTimezone(
            item.createdAt,
            "UTC",
            userTimezone,
            "MMM D, YYYY h:mm A",
            false
          )}
        </div>
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {renderStatusChip()}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item.summary?.totalContact || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item.summary?.totalContactUploaded || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item.summary?.totalContactUpdated || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item.summary?.relatedContact || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item.summary?.emailDuplicateCount || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item.summary?.numberDuplicateCount || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item.summary?.invalidCount || 0}
      </TableCell>
      <TableCell className={classes.tableCellActionBody}>
        <div className={classes.actionContainer}>
          {
            FILE_LOG_CONFIG.START_FROM_FILE_ID <= item.id ? (
                <Button
                    className={classes.customButton}
                    variant='outlined'
                    size='small'
                    onClick={() => history.push(`/import-contact-details?fileListId=${item.id}`)}
                >
                  <span style={{ color: "#346FEF" }}>View Log</span>
                </Button>
            ) :
                <div style={{ width: "82.52px" }}></div>
          }


          <Button
            className={classes.customButton}
            variant='outlined'
            color='primary'
            size='small'
            onClick={() => handleSettingDetails(item.id)}
          >
            <span style={{ color: "#346FEF" }}>Settings Details</span>
          </Button>

          {renderFileUrl(item) ? (
            <BootstrapTooltip title='Download File' placement='top' arrow>
              <IconButton
                className={classes.customIconButton}
                variant='outlined'
                size='small'
                href={renderFileUrl(item)}
                target='_blank'
              >
                {icons.download}
              </IconButton>
            </BootstrapTooltip>
          ) : (
            <></>
          )}

          <BootstrapTooltip title='Assign To Campaign' placement='top' arrow>
            <IconButton
              className={classes.customIconButton}
              variant='outlined'
              size='small'
              onClick={() => handleAssignCampaign(item.id)}
            >
              {icons.add}
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip title='Delete File' placement='top' arrow>
            <IconButton
              className={classes.customIconButton}
              variant='outlined'
              size='small'
              onClick={() => handleDelete(item)}
            >
              {icons.delete}
            </IconButton>
          </BootstrapTooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SummaryTableItem;
