import HttpRequest from "../../api/HttpRequest";
import importContactV2Api from "../../api/importContactV2Api";

class importSpreadsheetContactService {
    loadInitialData (params) {
        return HttpRequest.get(importContactV2Api.loadInitialData, params);
    }

    loadPipelineData (params) {
        return HttpRequest.get(importContactV2Api.loadPipelineData, params);
    }

    loadStageData (params) {
        return HttpRequest.get(importContactV2Api.loadStageData, params);
    }

    loadCampaignData (params) {
        return HttpRequest.get(importContactV2Api.loadCampaignData, params);
    }

    loadCustomFieldData () {
        return HttpRequest.get(importContactV2Api.loadCustomFieldData);
    }

    googleAuthUrl(params) {
        return HttpRequest.get(importContactV2Api.googleAuthUrl, params);
    };

    googleWorksheetInfo(params) {
      return HttpRequest.get(importContactV2Api.googleWorksheetInfo, params);
    };

    processRefreshToken(body) {
        return HttpRequest.post(importContactV2Api.processRefreshToken, body);
    }

    readSpreadsheet(params) {
        return HttpRequest.get(importContactV2Api.readSpreadsheet, params);
    }

    userConnectedFiles(body) {
        return HttpRequest.post(importContactV2Api.userConnectedFiles, body);
    }
    getUserConnectFiles(payload){
        return HttpRequest.get(importContactV2Api.userConnectedFiles, payload);
    }

    updateUserConnectedFiles(payload){
        return HttpRequest.put(`${importContactV2Api.userConnectedFiles}/${payload.id}`, payload);
    }

    singleImportFile (params) {
        return HttpRequest.get(importContactV2Api.userConnectedFilesSingle, params);
    }
}

const importSpreadsheetContactServiceInst = new importSpreadsheetContactService();

export default importSpreadsheetContactServiceInst;