import React, {useEffect, useState} from "react";
import {
  Button,
  Chip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {icons} from "./icon";
import {useHistory, useLocation} from "react-router-dom";
import {getImportedContactLogs, getImportedSpreadsheetContactLogs} from "../../api/contactApi";
import {CONTACT_LOG_CONTACT_DETAILS_STATUS, CONTACT_LOG_STATUS} from "../../constants/CoreConstants";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import DynamicEmptyPage from "../common/DynamicEmptyPage";
import LogsTableSkeleton from "./LogsTableSkeleton";

const ValidationIconComponent = ({ contact, type }) => {
  const classes = useStyles();

  const iconSet = {
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.VALIDATION_STATUS_CREATED]: icons.greenTick,
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.VALIDATION_STATUS_VALID]: icons.greenTick,
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.VALIDATION_STATUS_UPDATED]: icons.greenTick,
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.VALIDATION_STATUS_DUPLICATE]: icons.redTickExclamation,
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.VALIDATION_STATUS_INVALID]: icons.redTickCross,
  };

  let status = null;

  if (contact) {
    if (type === "email") {
      if (contact.emailValidationStatus === CONTACT_LOG_CONTACT_DETAILS_STATUS.VALIDATION_STATUS_VALID) {
        status = contact.validationStatus;
      }else {
        status = contact.emailValidationStatus;
      }
    }else if (type === "number") {
      if (contact.numberValidationStatus === CONTACT_LOG_CONTACT_DETAILS_STATUS.VALIDATION_STATUS_VALID) {
        status = contact.validationStatus;
      }else {
        status = contact.numberValidationStatus;
      }
    }else if (type === "fullName") {
      status = contact.validationStatus;
    }
  }

  if (!iconSet[status] || !status) {
    return <div className={classes.iconWrapper}>{icons.transparentIcon}</div>
  }

  let tooltipTitle = status.replace(/_/g, " ").toLowerCase();

  return (<BootstrapTooltip arrow title={tooltipTitle} placement="top">
    <div className={classes.iconWrapper}>{iconSet[status]}</div>
  </BootstrapTooltip>);
};

const DncIconComponent = ({status}) => {
  const iconSet = {
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.DNC_VERIFIED_OK]: icons.correctIcon,
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.DNC_DO_NOT_CALL]: icons.doNotCallIcon,
  };

  if (!iconSet[status]) {
    return <div>{icons.transparentIcon}</div>
  }

  return (<BootstrapTooltip arrow title={status.replace(/_/g, " ")} placement="top">
            <div>{iconSet[status]}</div>
          </BootstrapTooltip>);
};

const TagIconComponent = ({status}) => {
  const iconSet = {
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.TAG_ADDED]: icons.greenTick,
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.TAG_INVALID]: icons.redTickCross,
  };

  const labelSet = {
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.TAG_ADDED]: "ADDED",
    [CONTACT_LOG_CONTACT_DETAILS_STATUS.TAG_INVALID]: "NOT ADDED",
  };

  if (!iconSet[status]) {
    return <div></div>;
  }

  return (<BootstrapTooltip arrow title={labelSet[status]} placement="top">
    {iconSet[status]}
  </BootstrapTooltip>);
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 20px 0px 0px",
    "& .headerWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 16,
      "& .title": {
        fontSize: "20px",
        margin: 0,
      },
    },

    "& .contentWrapper": {
      backgroundColor: "#fff",
      borderRadius: 8,

      "& .filter": {
        padding: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& .buttonWrapper": {
          display: "flex",
          alignItems: "center",
          gap: 8,

          "& .MuiButtonBase-root": {
            "&:hover, &:focus": {
              backgroundColor: "#e0e0e0",
            },
          },
        },
      },
    },
  },

  tablePagination: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: 16,
    marginBottom: 16,
    borderBlock: "1px solid #1D29391A",

    "& .contactCount": {
      color: "#475467",
    },

    "& .MuiTablePagination-actions": {
      display: "flex",

      "& .MuiIconButton-root": {
        padding: "6px !important",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
  },

  tableWrapper: {
    padding: "0 16px 16px 16px",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 360px)",
    overflow: "auto",
    maxWidth: "100%",
    scrollbarWidth: "thin",
    scrollbarColor: "#CBD5E1 transparent", // Change color as needed
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CBD5E1", // Change color as needed
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Change color as needed
    },
  },
  tableCellHeader: {
    minWidth: "220px",
    border: "1px solid #ddd",
    backgroundColor: "#F5F5F6",
    fontWeight: 600,
    fontSize: "16px",
    padding: "10px 20px",
    color: "#757F8E",
    textTransform: "uppercase",
  },
  tableCellActionHeader: {
    minWidth: "360px",
    border: "1px solid #ddd",
    backgroundColor: "#F5F5F6",
    fontWeight: 600,
    fontSize: "16px",
    padding: "10px 20px",
    color: "#757F8E",
    textTransform: "uppercase",
  },
  tableCellBody: {
    border: "1px solid #ddd",
    fontWeight: 500,
    fontSize: "16px",
    padding: "10px 20px",
  },
  tableCellActionBody: {
    border: "1px solid #ddd",
    padding: "10px 20px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    gap: 5,
  },
  customButton: {
    borderRadius: 20,
    borderColor: "#346FEF",
    padding: "5px 10px",
    lineHeight: " 1px",
    height: "25px",
    "&:hover": {
      borderColor: "#346FEF",
      backgroundColor: "white",
    },
    "&:focus": {
      borderColor: "#346FEF",
      backgroundColor: "white",
    },
  },
  chip: {
    backgroundColor: "#005F37",
    color: "#ffffff",
    height: "25px",
    "& svg": {
      marginLeft: "5px"
    }
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
  },
  tableContainerWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  customIconButton: {
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      backgroundColor: "white",
    },
  },
  tags: {
    display: "flex",
    gap: "5px",
    flexWrap: "wrap",
  }
}));

const FileDetails = () => {
  const statusColors = {
    [CONTACT_LOG_STATUS.CREATED]: "#005F37",
    [CONTACT_LOG_STATUS.INVALID]: "#808080",
    [CONTACT_LOG_STATUS.UPDATED]: "#00969C",
    pending: "#FFA500",
    deleted: "#A9000F",
    default: "#808080",
    tag: "#3C7EF3"
  };

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const [fileLogs, setFileLogs] = useState([]);

  const [apiParams, setApiParams] = useState({
    page: 1,
    limit: 10
  });

  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (queryParams.has('userConnectedFileId')){
      getImportedContactLogsList();
    }else {
      window.showNotification("error", "File list id missing");
      history.push("/import-spreadsheet-contact-summary");
    }
  }, [apiParams]);

  const getImportedContactLogsList = async () => {
    try {
      setIsLoading(true);
      const response = await getImportedSpreadsheetContactLogs({ ...apiParams, userConnectedFileId: queryParams.get("userConnectedFileId") });

      if (response.success && response.data && response.data[0]) {
        setFileLogs(response.data);
        if (apiParams.page === 1) {
          setTotal(response.total_count);
        }
      } else {
        setFileLogs([]);
        setTotal(0);
      }
    } catch (error) {
      console.error("Failed to load File List:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setApiParams((prev) => ({ ...prev, page: newPage + 1 }));
  }

  const handleChangeRowsPerPage = (event) => {
    setApiParams((prev) => ({ ...prev, limit: event.target.value, page: 1 })); // Reset page to 1 on row count change
  };

  const makeContactAddress = (contact) => {
    const fields = [contact.address, contact.city, contact.state, contact.country];
    const address = fields.filter(Boolean).join(", ");
    return (contact.zip ? `${address} ${contact.zip}` : address) || "N/A";
  };

  return (
    <div className={classes.root}>
      <div className='headerWrapper'>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <img
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/summary/1.0.0/table_icon.png`}
            height={70}
            alt=''
          />
          <h4 className='title'>Import Details</h4>
        </div>

        <Button
          variant='contained'
          color='primary'
          startIcon={<ArrowBackIcon />}
          disableElevation
          onClick={() => history.push("/import-spreadsheet-contact-summary")}
        >
          Back
        </Button>
      </div>

      <div className='contentWrapper'>
        <div className={classes.tablePagination}>
          <span className='contactCount'>Total {total} Contacts</span>

          <TablePagination
            component='div'
            count={total}
            page={apiParams.page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={apiParams.limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <div className={classes.tableWrapper}>
          {
            isLoading ?
                <LogsTableSkeleton/> :
            fileLogs[0] ?
                <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                >
                  <Table stickyHeader aria-label='scrollable table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCellHeader} style={{ minWidth: "fit-content" }}>Status</TableCell>
                        <TableCell className={classes.tableCellHeader}>Email</TableCell>
                        <TableCell className={classes.tableCellHeader}>Phone</TableCell>
                        <TableCell className={classes.tableCellHeader}>Full Name</TableCell>
                        <TableCell className={classes.tableCellHeader} style={{ textAlign: "center" }}>Tag</TableCell>
                        <TableCell className={classes.tableCellHeader}>Address</TableCell>
                        <TableCell className={classes.tableCellHeader}>Company Name</TableCell>
                        <TableCell className={classes.tableCellHeader}>Custom Fields</TableCell>
                        <TableCell className={classes.tableCellHeader}>Related Contact</TableCell>
                        <TableCell className={classes.tableCellActionHeader}>Related Contact Email</TableCell>
                        <TableCell className={classes.tableCellActionHeader}>Related Contact Phone</TableCell>
                        <TableCell className={classes.tableCellActionHeader}>Related Contact Address</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fileLogs.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: "center" }}>
                              <Chip
                                  label={row.status || "INVALID"}
                                  className={classes.chip}
                                  style={{ backgroundColor: statusColors[row.status] || statusColors.default }}
                              />
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {row.contactDetails.map((contact, index) => (
                                  contact.email ?
                                      <div key={index} className={classes.tableContainerWithIcon}>
                                        <ValidationIconComponent type="email" contact={contact} />
                                        <span className='email-address'>{contact.email}</span>
                                      </div> : null
                              ))}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {row.contactDetails.map((contact, index) => (
                                  contact.number ?
                                      <div key={index} className={classes.tableContainerWithIcon}>
                                        <ValidationIconComponent type="number" contact={contact}/>
                                        <span>{contact.number}</span>
                                        <DncIconComponent status={contact.dncValidationStatus}/>
                                      </div> : null
                              ))}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>{row.fullName}</TableCell>
                            <TableCell className={classes.tableCellBody}>
                              <div className={classes.tags}>
                                {
                                  row.tags.map((tag)=>(
                                      <Chip
                                          icon={<TagIconComponent status={tag.status}/>}
                                          label={tag.title}
                                          className={classes.chip}
                                          style={{ backgroundColor: statusColors.tag }}
                                      />
                                  ))
                                }
                              </div>
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {makeContactAddress(row)}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>{row.companyName}</TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {row.customFields.map((field, index) => (
                                  <div key={index} className={classes.tableContainerWithIcon}>
                                    <span>{field.title}: </span>
                                    <span>{field.value}</span>
                                  </div>
                              ))}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {row.relatedContacts.map((contact, index) => (
                                  contact.fullName ?
                                  <div key={index} className={classes.tableContainerWithIcon}>
                                    <ValidationIconComponent type="fullName" contact={contact} />
                                    <span className='name'>{contact.fullName}</span>
                                  </div> :
                                      <div key={index} style={{ height: "22.88px" }} className={classes.tableContainerWithIcon}>
                                        <ValidationIconComponent contact={null} />
                                        <span className='address'>N/A</span>
                                      </div>
                              ))}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {row.relatedContacts.map((contact, index) => (
                                  contact.email ?
                                      <div key={index} className={classes.tableContainerWithIcon}>
                                        <ValidationIconComponent type="email" contact={contact} />
                                        <span className='address'>{contact.email}</span>
                                      </div> :
                                      <div key={index} style={{ height: "22.88px" }} className={classes.tableContainerWithIcon}>
                                        <ValidationIconComponent contact={null} />
                                        <span className='address'>N/A</span>
                                      </div>
                              ))}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {row.relatedContacts.map((contact, index) => (
                                  contact.number ?
                                      <div key={index} className={classes.tableContainerWithIcon}>
                                        <ValidationIconComponent type="number" contact={contact}/>
                                        <span>{contact.number}</span>
                                        <DncIconComponent status={contact.dncValidationStatus}/>
                                      </div> :
                                      <div key={index} style={{height: "22.88px"}} className={classes.tableContainerWithIcon}>
                                        <ValidationIconComponent contact={null}/>
                                        <span className='address'>N/A</span>
                                      </div>
                              ))}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                              {row.relatedContacts.map((contact, index) => (
                                  <div key={index}>
                                    <span className='address'>
                                      {makeContactAddress(contact)}
                                    </span>
                                  </div>
                              ))}
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> :
                <DynamicEmptyPage/>
          }
        </div>
      </div>
    </div>
  );
};

export default FileDetails;
