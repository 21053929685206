import { Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, styled, Typography } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CONTACT_IMPORT_RULES_V2,
  HISTORY_STATE,
  PROCESS_MODE_VALUE,
  processModeOptions
} from "../../../constants/CoreConstants";
import {
  selectImportSpreadsheetContactState
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import importSpreadsheetContactService from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactService";
import {
  loadCampaignDataStart,
  loadInitialDataStart,
  loadPipelineDataStart,
  loadSpreadSheetFileDetails,
  loadStageDataStart,
  updateAccordion,
  updateDetails,
  updateProcessMode
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import BasicSelect from "../../common/BasicSelect";
import SwitchC from "../../common/subComponents/Switch";
import { BasicInput } from "../../contact/leftSideV2/LeftSideV2Styles";
import { CustomScrollbar } from "../CustomScrollbar";
import ProcessModeHelpModal from "../ProcessModeHelpModal";
import { extractFileId } from "./Configuration";
import { importContactSpreedSheetStyle } from "./importContactSpreedSheetStyle";
import ListOrTagCreateModal from "./ListOrTagCreateModal";
const CustomRadio = styled(Radio)(() => ({
  '& .MuiSvgIcon-root': {
    fill: '#0000008a',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: '#3f51b5',
    },
  },
  '&:hover': {
    '& .MuiSvgIcon-root': {
      opacity: 1,
    },
  },
}));
const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiTypography-root': {
    fontSize: '14px',
    color: '#000000',
  },
}));
export const reformatMapping = (input) => {
  const output = {};

  for (const [key, value] of Object.entries(input)) {
    const numericKey = parseInt(key);
    if (!value.startsWith("custom_")) {
      output[value] = numericKey;
    }
  }

  return output;
};

export const reformatCustomMapping = (input) => {
  const output = {};

  for (const [key, value] of Object.entries(input)) {
    const numericKey = parseInt(key);
    if (value.startsWith("custom_")) {
      output[value.split("_")[1]] = numericKey;
    }
  }

  return Object.keys(output).length > 0 ? output : null;
};

const reformatTags = (tags, tagIds) => {
  return tags.map(tag => tagIds.includes(tag.id) ? { id: tag.id, name: tag.title } : null).filter(Boolean);
};

const Details = ({  moveToPreviousStep,moveToNextStep }) => {
  const dispatch = useDispatch();
  const {
    leadSources,
    tags,
    pipelines,
    stages,
    teamUsers,
    smartLists,
    campaigns,
    mappedFields,
    accordion: {
      isPipelineExpand,
      isCampaignExpand
    },
    details: {
      pipelineId,
      stageId,
      tagIds,
      leadSourceId,
      leadOwnerId,
      smartListId,
      campaignId,
      userConsent,
      ignoreFirstRow,
      workSheetInfo,
      email,
      spreadsheetLink,
      spreadSheetName,
      existingContactRule
    },
    processMode: {
      dripMode,
      startDate,
      startTime,
      batchQuantity,
      repeatAfter,
      processType,
      weekDays
    },
    referer
  } = useSelector(selectImportSpreadsheetContactState);
  const classes = importContactSpreedSheetStyle({ isPipelineExpand });
  const [uploading,setUploading] = useState(false);
  const [openTagCreateModal, setOpenTagCreateModal] = useState(false);
  const [openListCreateModal, setOpenListCreateModal] = useState(false);
  const [openProcessHelpModal, setOpenProcessHelpModal] = useState(false);
  const scrollContainerRef = useRef(null);
  const [startOption, setStartOption] = useState('now');

  useEffect(() => {
    if ((dripMode === PROCESS_MODE_VALUE.DRIP || campaignId) && scrollContainerRef) {
      scrollContainerRef?.current?.scrollTo({ top: scrollContainerRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [dripMode, campaignId]);

  const handlePipelineExpand = (status) => {
    if (status){
      dispatch(loadPipelineDataStart({ userId: leadOwnerId }));
    }

    dispatch(updateAccordion({ isPipelineExpand: status }));
  };

  const handleCampaignExpand = (status) => {
    if (status){
      dispatch(loadCampaignDataStart({ userId: leadOwnerId }));
    }
    dispatch(updateAccordion({ isCampaignExpand: status }));
  };

  const handleUpdateFormData = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "tagIds"){
      value = value.filter(Boolean);
    }

    if (name === "pipelineId"){
      dispatch(loadStageDataStart({ pipelineId: value }));
    }

    if (name === "userConsent"){
      value = !!event.target.checked;
    }

    if (name === "leadOwnerId") {
      dispatch(loadInitialDataStart({ userId: value }));

      if (isCampaignExpand) {
        dispatch(loadCampaignDataStart({ userId: value }));
      }

      if (isPipelineExpand) {
        dispatch(loadPipelineDataStart({ userId: value }));
      }
    }

    dispatch(updateDetails({ [name]: value }));
  };

  const handleUpdateProcessMode = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    dispatch(updateProcessMode({ [name]: value }));
  };

  const handleWeekDays = (event) => {
    let name = event.target.name;

    dispatch(updateProcessMode({ weekDays: { ...weekDays, [name]: !weekDays[name] } }));
  };

  const handleSubmit = async () => {
    try {
      if (!userConsent) {
        window.showNotification("error", "User consent is required.");
        return;
      }

      if (isPipelineExpand) {
        if (!stageId) {
          window.showNotification("error", "Stage is required for pipeline expansion.");
          return;
        }
      }

      if (campaignId && (isCampaignExpand || referer === HISTORY_STATE.SPREADSHEET_CAMPAIGN_REFERER)) {
        if (dripMode === "drip") {
          if (!startDate || !startTime) {
            window.showNotification("error", "Start date and time are required for drip mode.");
            return;
          }

          if (!batchQuantity) {
            window.showNotification("error", "Batch quantity is required for drip mode.");
            return;
          }

          if (!repeatAfter || !weekDays || !processType) {
            window.showNotification("error", "Drip mode requires repeatAfter, weekDays, and processType.");
            return;
          }
        }
      }

      let payload = {
        email: email,
        providerFileId: extractFileId(spreadsheetLink),
        providerFileName: spreadSheetName,
        providerFileUrl: spreadsheetLink,
        worksheetInfo: workSheetInfo,
        mapping: reformatMapping(mappedFields),
        customMapping: reformatCustomMapping(mappedFields),
        existingContactRule: existingContactRule,
      };

      let additionalInfos = { user_consent: userConsent, ignore_first_row: ignoreFirstRow };

      if (isPipelineExpand && pipelineId && stageId){
        additionalInfos.pipline_id = pipelineId;
        additionalInfos.stage_id = stageId;
      }

      if (campaignId && (isCampaignExpand || referer === HISTORY_STATE.SPREADSHEET_CAMPAIGN_REFERER)){
        payload.campaignId = campaignId;

        if (dripMode === "drip") {
          payload.dripBatch = {
            start_from: startDate + " " + startTime,
            quantity: batchQuantity,
            repeat_after_days: repeatAfter,
            send_on: weekDays,
            batch_process_type: processType,
            campaign_id: campaignId,
          };
        }
      }

      if (leadSourceId){
        additionalInfos.source_id = leadSourceId;
      }

      if (leadOwnerId){
        additionalInfos.lead_owner_id = leadOwnerId;
      }

      if (smartListId){
        additionalInfos.smart_list_id = smartListId;
      }

      if (tagIds && tagIds[0] !== ""){

        additionalInfos.tag_ids = reformatTags(tags, tagIds);
      }

      payload.additionalInfos = additionalInfos;

      setUploading(true);
      let resp = await importSpreadsheetContactService.userConnectedFiles(payload);

      if (resp && resp.success){
        window.showNotification("success", "Spreadsheet file uploaded successfully");
        dispatch(loadSpreadSheetFileDetails(resp.data));
        moveToNextStep();
      }else {
        window.showNotification("error", "Spreadsheet file failed to upload");
      }
    } catch (err) {
      console.error("Error during form submission:", err);
    }finally {
      setUploading(false);
    }
  };
  const handleStartOptionChange = (event) => {
    setStartOption(event.target.value);
  };  

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography
          style={{ height: "unset !important" }}
          variant='h5'
          color='textPrimary'
          className={classes.headerTitle}
        >
          Details
        </Typography>

        <div className={classes.buttonGroup}>
          <Button style={{color:"#000"}} variant='outlined' disableElevation onClick={moveToPreviousStep}>
            Back
          </Button>
          <Button variant='contained' color='primary' disabled={uploading} disableElevation onClick={handleSubmit}>
            {!uploading ? "Upload" : "Uploading..."}
          </Button>
        </div>
      </div>

      <CustomScrollbar className={classes.scrollContainer} ref={scrollContainerRef}>
        <div className={classes.contentWrapper}>
          {/* Instruction Section */}
          <div className={classes.section}>
            <span style={{ height: "unset !important" }} variant='h6' className={classes.sectionTitle}>
              Instruction
            </span>

            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  File Name
                </span>
                <BasicInput
                  fullWidth
                  name='fileName'
                  placeholder='Give your file a name'
                  value={spreadSheetName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  Upload instructions for the contacts in your file
                </span>
                <BasicSelect
                  fullWidth
                  name='existingContactRule'
                  defaultText='Select an Existing Contact Rule'
                  options={CONTACT_IMPORT_RULES_V2}
                  mapping={{ label: "label", value: "value" }}
                  value={existingContactRule}
                  onChange={handleUpdateFormData}
                />
              </Grid>
            </Grid>
          </div>
          {/* Contacts Management Section */}
          <div className={classes.section}>
            <Typography
              style={{ height: "unset !important", marginBottom: "5px" }}
              variant='h6'
              className={classes.sectionTitle}
            >
              Contacts Management
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  Lead Source
                </Typography>
                <BasicSelect
                  fullWidth
                  name='leadSourceId'
                  defaultText='Select a Lead Source'
                  options={leadSources}
                  mapping={{ label: "title", value: "id" }}
                  value={leadSourceId}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                  Lead Owner
                </span>
                <BasicSelect
                  fullWidth
                  name='leadOwnerId'
                  defaultText='Select a Lead Owner'
                  options={teamUsers}
                  mapping={{ label: "title", value: "id" }}
                  value={leadOwnerId}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                    Tags
                  </Typography>
                  <Button
                    size='small'
                    startIcon={<AddIcon />}
                    style={{}}
                    className={classes.addNewButton}
                    onClick={() => setOpenTagCreateModal(true)}
                  >
                    Add New
                  </Button>
                  {openTagCreateModal && (
                    <ListOrTagCreateModal
                      open={openTagCreateModal}
                      onClose={() => setOpenTagCreateModal(false)}
                      heading={"Add New Tag"}
                      labelText={"Tag Title"}
                      isForTag={true}
                    />
                  )}
                </div>
                <BasicSelect
                  fullWidth
                  multiple={true}
                  name='tagIds'
                  defaultText='Select Tags'
                  options={tags}
                  mapping={{ label: "title", value: "id" }}
                  value={tagIds}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span style={{ height: "unset !important" }} variant='body1' className={classes.fieldLabel}>
                    Add to Smart List
                  </span>
                  <Button
                    size='small'
                    startIcon={<AddIcon />}
                    className={classes.addNewButton}
                    onClick={() => setOpenListCreateModal(true)}
                  >
                    Add New
                  </Button>
                  {openListCreateModal && (
                    <ListOrTagCreateModal
                      open={openListCreateModal}
                      onClose={() => setOpenListCreateModal(false)}
                      heading={"Add New Smart List"}
                      labelText={"Smart List Title"}
                    />
                  )}
                </div>

                <BasicSelect
                  fullWidth
                  name='smartListId'
                  defaultText='Select a Smart List'
                  options={smartLists}
                  mapping={{ label: "title", value: "id" }}
                  value={smartListId}
                  onChange={handleUpdateFormData}
                />
              </Grid>
            </Grid>
          </div>

          {/* Deals Pipeline Section */}
          <div className={classes.section}>
            <div className={`${classes.sectionHeader} pipelineHeader_xOsa`}>
              <Typography variant='h6' className={`${classes.sectionTitle} pipelineTitle_xOsa`}>
                Add contacts to a pipeline?
              </Typography>
              <SwitchC
                offText=''
                onText=''
                status={isPipelineExpand}
                onChange={handlePipelineExpand}
                brandColor='#006DF5'
              />
            </div>

            {isPipelineExpand && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant='body1' className={classes.fieldLabel}>
                    Pipeline
                  </Typography>
                  <BasicSelect
                    fullWidth
                    name='pipelineId'
                    defaultText='Select a Pipeline'
                    options={pipelines}
                    mapping={{ label: "title", value: "id" }}
                    value={pipelineId}
                    onChange={handleUpdateFormData}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='body1' className={classes.fieldLabel}>
                    Stage
                  </Typography>
                  <BasicSelect
                    fullWidth
                    name='stageId'
                    defaultText='Select a Stage'
                    options={stages}
                    mapping={{ label: "title", value: "id" }}
                    value={stageId}
                    onChange={handleUpdateFormData}
                  />
                </Grid>
              </Grid>
            )}
          </div>

          {/* Campaign Section */}

          {referer !== HISTORY_STATE.SPREADSHEET_CAMPAIGN_REFERER && (
            <div className={classes.section}>
              <div className={`${classes.sectionHeader} campaignHeader_xMkd`}>
                <Typography variant='h6' className={`${classes.sectionTitle} campaignTitle_xMkd`}>
                  Add contacts to a campaign?
                </Typography>
                <SwitchC
                  offText=''
                  onText=''
                  status={isCampaignExpand}
                  onChange={handleCampaignExpand}
                  brandColor='#006DF5'
                />
              </div>

              {isCampaignExpand && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.fieldLabel}>
                      Campaign
                    </div>
                    <BasicSelect
                      fullWidth
                      name='campaignId'
                      defaultText='Select a Campaign'
                      options={campaigns}
                      mapping={{ label: "title", value: "id" }}
                      value={campaignId}
                      onChange={handleUpdateFormData}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          )}

          {/* Process Mode Section */}
          {campaignId && (isCampaignExpand || referer === HISTORY_STATE.SPREADSHEET_CAMPAIGN_REFERER) && (
            <div className={classes.section}>
              <div style={{ marginBottom: "8px" }}>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12} md={8}>
                    <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                      <span variant='h6' className={classes.sectionTitle}>
                        How would you like us to process the contacts?
                      </span>

                      <HelpRounded
                        style={{ color: "var(--gray)", cursor: "pointer" }}
                        onClick={() => setOpenProcessHelpModal(true)}
                      />
                      {openProcessHelpModal && (
                        <ProcessModeHelpModal
                          open={openProcessHelpModal}
                          onClose={() => setOpenProcessHelpModal(false)}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <BasicSelect
                      fullWidth
                      name='dripMode'
                      defaultText='Select Process Mode'
                      options={processModeOptions}
                      mapping={{ label: "label", value: "value" }}
                      value={dripMode}
                      onChange={handleUpdateProcessMode}
                    />
                  </Grid>
                </Grid>
              </div>

              {dripMode === PROCESS_MODE_VALUE.DRIP && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} className={classes.gridContainerDrip}>
                      <span className={classes.fieldLabel}>Start time:</span>
                      <RadioGroup row value={startOption} onChange={handleStartOptionChange}>
                        <CustomFormControlLabel value='now' control={<CustomRadio />} label='Now' />
                        <CustomFormControlLabel value='later' control={<CustomRadio />} label='Later' />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <BasicInput
                        type='date'
                        fullWidth
                        value={startDate}
                        name='startDate'
                        onChange={handleUpdateProcessMode}
                        disabled={startOption === "now"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <div className={classes.gridContainerDrip}>
                        <span variant='body1' className={classes.fieldLabel}>
                          at
                        </span>
                        <BasicInput
                          type='time'
                          fullWidth
                          value={startTime}
                          name='startTime'
                          onChange={handleUpdateProcessMode}
                          disabled={startOption === "now"}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <div className={classes.gridContainerDrip}>
                        <span variant='body1' className={classes.fieldLabel}>
                          Process
                        </span>
                        <BasicInput
                          fullWidth
                          placeholder='# of contacts'
                          value={batchQuantity}
                          name='batchQuantity'
                          onChange={handleUpdateProcessMode}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <div className={classes.gridContainerDrip}>
                        <span variant='body1' className={classes.fieldLabel}>
                          every
                        </span>
                        <BasicInput
                          placeholder='Enter a number'
                          value={repeatAfter}
                          fullWidth
                          name='repeatAfter'
                          onChange={handleUpdateProcessMode}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <BasicSelect
                        fullWidth
                        name='processType'
                        defaultText='Select an option'
                        options={[
                          {
                            id: 1,
                            title:"Minute(s)",
                          },
                          {
                            id: 2,
                            title: "Hour(s)",
                          },
                          {
                            id: 3,
                            title: "Day(s)",
                          },
                        ]}
                        mapping={{ label: "title", value: "id" }}
                        value={processType}
                        onChange={handleUpdateProcessMode}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div variant='body1' className={classes.fieldLabel}>
                       Process contacts on the following days:
                      </div>
                      <FormControl className={classes.weekdaysGroup} onChange={handleWeekDays}>
                        <FormControlLabel
                          value='Sun'
                          checked={weekDays.Sun}
                          control={<Checkbox size='small' color='primary' />}
                          label='Sunday'
                          name='Sun'
                        />
                        <FormControlLabel
                          value='Mon'
                          checked={weekDays.Mon}
                          control={<Checkbox size='small' color='primary' />}
                          label='Monday'
                          name='Mon'
                        />
                        <FormControlLabel
                          value='Tue'
                          checked={weekDays.Tue}
                          control={<Checkbox size='small' color='primary' />}
                          label='Tuesday'
                          name='Tue'
                        />
                        <FormControlLabel
                          value='Wed'
                          checked={weekDays.Wed}
                          control={<Checkbox size='small' color='primary' />}
                          label='Wednesday'
                          name='Wed'
                        />
                        <FormControlLabel
                          value='Thu'
                          checked={weekDays.Thu}
                          control={<Checkbox size='small' color='primary' />}
                          label='Thursday'
                          name='Thu'
                        />
                        <FormControlLabel
                          value='Fri'
                          checked={weekDays.Fri}
                          control={<Checkbox size='small' color='primary' />}
                          label='Friday'
                          name='Fri'
                        />
                        <FormControlLabel
                          value='Sat'
                          checked={weekDays.Sat}
                          control={<Checkbox size='small' color='primary' />}
                          label='Saturday'
                          name='Sat'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          )}

          {/* Confirmation Section */}
          <div className={classes.confirmationSection}>
            <FormControlLabel
              value='userConsent'
              name='userConsent'
              checked={userConsent}
              onChange={handleUpdateFormData}
              control={<Checkbox size='large' color='primary' />}
              label={
                <sapn style={{fontSize:"14px",color:"#000000"}}>
                  I Confirm that all contacts in this import have consented to receive messages from us. I've had communications with them within the last year, and this list is not from a third party.
                </sapn>
              }
            />
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default Details;
