import { Button, Grid } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HistoryProvider } from "../../App";
import {
  HISTORY_STATE,
  IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE,
  IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT,
} from "../../constants/CoreConstants";
import useQueryParam from "../../hooks/useQueryParam";
import { resetToInitial, updateDetails, updateImportReferer } from "../../reducers/importContact/importContactSlice";
import {
  resetToInitial as resetToInitialSpreadsheet,
  updateActiveStepAndCompletedStep as updateActiveStepAndCompletedStepSpreadsheet,
  updateDetails as updateDetailsSpreadsheet,
  updateImportReferer as updateImportSpreadsheetReferer,
} from "../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import { CleanDataIcon, ImportIcon, ImportSpreadsheetIcon } from "../contact/Icons";
import HowItWorksModal from "./HowItWorksModal";
import { ImportContactStyle } from "./ImportContactStyle";
import ImportCsvModal from "./ImportCsvModal";
import ImportSpreadsheetModal from "./spreadsheet/ImportSpreadsheetModal";

const ImportContactsDefaultTab = () => {
  const history = useContext(HistoryProvider);
  const classes = ImportContactStyle();
  const dispatch = useDispatch();
  const [openImportCsvModal, setOpenImportCsvModal] = useState(false);
  const [openImportSpreadsheetModal, setOpenImportSpreadsheetModal] = useState(false);
  const [openHowItsWorksModal, setOpenHowItsWorksModal] = useState(false);
  const [importSpreadsheetFromCampaign] = useQueryParam("import-spreadsheet-from-campaign");
  const [spreadsheetOwner] = useQueryParam("spreadsheet-owner");

  useEffect(() => {
    if (history.location.state) {
      let state = history.location.state;

      if (state.popup === HISTORY_STATE.CSV_POPUP) {
        if (state.referer === HISTORY_STATE.CSV_CAMPAIGN_REFERER && state.campaignId) {
          dispatch(
            updateDetails({
              campaignId: state.campaignId,
            })
          );
        }

        if (state.referer === HISTORY_STATE.CSV_POWER_DIALER_REFERER && state.powerDialerListId) {
          dispatch(
            updateDetails({
              powerDialerListId: state.powerDialerListId,
            })
          );
        }

        dispatch(updateImportReferer(state.referer));
        setOpenImportCsvModal(true);
      }

      if (state.popup === HISTORY_STATE.SPREADSHEET_POPUP) {
        if (state.referer === HISTORY_STATE.SPREADSHEET_CAMPAIGN_REFERER && state.campaignId) {
          dispatch(
            updateDetailsSpreadsheet({
              campaignId: state.campaignId,
            })
          );
        }

        dispatch(updateImportSpreadsheetReferer(state.referer));
        setOpenImportSpreadsheetModal(true);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (importSpreadsheetFromCampaign || spreadsheetOwner) {
      const email = spreadsheetOwner || "";

      setOpenImportSpreadsheetModal(true);

      if (importSpreadsheetFromCampaign) {
        dispatch(updateImportSpreadsheetReferer(HISTORY_STATE.SPREADSHEET_CAMPAIGN_REFERER));
      }

      dispatch(
        updateDetailsSpreadsheet({
          email: email,
          campaignId: importSpreadsheetFromCampaign,
        })
      );

      if (spreadsheetOwner) {
        dispatch(
          updateActiveStepAndCompletedStepSpreadsheet({
            activeTab: IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE,
            completedSteps: [IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT],
          })
        );
      }
    } else {
      setOpenImportSpreadsheetModal(false);
    }
  }, [importSpreadsheetFromCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <img
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/upload_4052950.svg`}
            alt=''
            className={classes.headerIcon}
          />
          <div className={classes.headerText}>
            <h5 className={classes.title}>Import Contacts</h5>
            <p className={classes.subtitle}>Import contacts by uploading a csv file or a Google sheet</p>
          </div>
        </div>

        <div className={classes.helpSection}>
          <div onClick={() => setOpenHowItsWorksModal(true)} className={classes.helpBox}>
            <ErrorIcon />
            <p>How it works?</p>
          </div>
          {openHowItsWorksModal && (
            <HowItWorksModal open={openHowItsWorksModal} onClose={() => setOpenHowItsWorksModal(false)} />
          )}
        </div>
      </div>

      <div className={classes.content}>
        <Grid container style={{ padding: "10px 16px 10px 16px" }}>
          <Grid item md={12} xs={12} style={{ paddingBottom: "10px" }}>
            <div className={classes.card} style={{ minHeight: "171px" }}>
              <div className={classes.cardContainer}>
                <div className={classes.cardHeader}>
                  <div>
                    <CleanDataIcon />
                  </div>
                  <div>
                    <div className={classes.cardTitle}>Clean My Data</div>
                    <p className={classes.cardDescription}>
                      To improve deliverability and campaign performance,
                      <br /> we recommend that you clean your data prior to uploading your file.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={classes.notes}>Important Notes:</div>
                  <div className={classes.notesList}>
                    <ul style={{ margin: "0px", color: "#8c8c8c" }}>
                      <li>Validate Emails & Phone Numbers | Check DNC | Skiptrace</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      setOpenHowItsWorksModal(true);
                    }}
                  >
                    Learn More
                  </Button>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    onClick={() => history.push("contacts/clean-list")}
                  >
                    Clean my Data
                  </Button>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item md={12} xs={12} style={{ paddingBottom: "10px" }}>
            <div className={classes.card}>
              <div className={classes.cardContainer}>
                <div className={classes.cardHeader}>
                  <ImportSpreadsheetIcon />
                  <div>
                    <div className={classes.cardTitle}>Import CSV File</div>
                    <p className={classes.cardDescription}>Add contacts to your CRM by uploading a csv file.</p>
                  </div>
                </div>
                <div>
                  <div className={classes.notes}>Important Notes:</div>
                  <div className={classes.notesList}>
                    <ul style={{ margin: "0px", color: "#8c8c8c" }}>
                      <li>Your file must be in the .csv format</li>
                      <li>We recommend that you validate all emails and phone numbers on your list prior to upload</li>
                      <li>
                        In order to map over all the fields from your file, a standard or custom field must exist in the
                        CRM prior to upload
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  onClick={() => history.push("/import-contact-summary")}
                  variant='outlined'
                  color='primary'
                >
                  View Past Imports
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenImportCsvModal(true)}
                >
                  Import
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className={classes.card}>
              <div className={classes.cardContainer}>
                <div className={classes.cardHeader}>
                  <ImportIcon />
                  <div>
                    <div className={classes.cardTitle}>Import From Google Sheets</div>
                    <p className={classes.cardDescription}>Add contacts to your CRM by uploading a Google sheet.</p>
                  </div>
                </div>
                <div>
                  <div className={classes.notes}>Important Notes:</div>
                  <div className={classes.notesList}>
                    <ul style={{ margin: "0px", color: "#8c8c8c" }}>
                      <li>We recommend that you validate all emails and phone numbers on your list prior to upload</li>
                      <li>
                        In order to map over all the fields from your file, a standard or custom field must exist in the
                        CRM prior to upload
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={classes.buttonContainer}>
                <Button
                  onClick={() => history.push("/import-spreadsheet-contact-summary")}
                  variant='outlined'
                  color='primary'
                  className={classes.button}
                >
                  View Past Imports
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenImportSpreadsheetModal(true)}
                >
                  Import
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {openImportCsvModal && (
        <ImportCsvModal
          open={openImportCsvModal}
          onClose={() => {
            dispatch(resetToInitial());
            setOpenImportCsvModal(false);
          }}
        />
      )}

      {openImportSpreadsheetModal && (
        <ImportSpreadsheetModal
          open={openImportSpreadsheetModal}
          onClose={() => {
            dispatch(resetToInitialSpreadsheet());
            setOpenImportSpreadsheetModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ImportContactsDefaultTab;
