import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, {useContext, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RefreshIcon } from "../Icons";
import "../importContactV2.css";
import Utils from "../../../helpers/Utils";
import {HistoryProvider} from "../../../App";
import {
  selectImportSpreadsheetContactState
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import importSpreadsheetContactService
  from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactService";
import {loadSpreadSheetFileDetails} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import {SPREADSHEET_SUMMARY_STATUS} from "../../../constants/CoreConstants";
import BootstrapTooltip from "../../globals/BootstrapTooltip";

const userTimezone = Utils.getAccountData("userTimezoneRaw");

const useStyles = makeStyles((theme) => ({
  root: {
    "& .main_content_topbar": {
      padding: 0,
      marginBottom: "16px"
    },
    "& .main_content_topbar_content": {
      "& h6": {
        margin: 0,
        fontSize: "1.5rem",
        marginBottom: "4px"
      },
      "& p": {
        margin: 0
      },
      "& .icon_import_report": {
        height: "unset !important",
        width: "unset !important",
        "& img": {
          height: "40px !important"
        }
      },
      "& .main_content_container_button": {
        padding: "6px 16px"
      }
    },
    "& .MuiTableContainer-root": {
      maxHeight: "556px",
      "& .MuiTableCell-head": {
        "&:first-child": {
          borderTopLeftRadius: "10px"
        },
        "&:last-child": {
          borderTopRightRadius: "10px"
        }
      },
      "& .MuiTableRow-head": {
        background: "#fff !important"
      }
    },
  },
  viewReportButton: {
    color: 'var(--light_blue)',
    height:'35px',
    textTransform: 'capitalize',
    border: '1px solid var(--light_blue)',
    "&:focus,&:hover": {
      background: 'transparent',
      color: 'var(--light_blue)',
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: 12
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 24
  },
  tableSection: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0",
    borderRadius: "0 0 10px 10px",
    marginBottom: "20px"
  },
  listSection: {
    minHeight: "unset",
    maxWidth: "100%",
    width: "100%",
    margin: "-40px auto 0",
    background: "var(--gray_light)",
    borderRadius: "10px",
    padding: "0 22px 0 18px"
  },
  listTable: {
    "& table": {
      borderCollapse: "initial",
      borderSpacing: "0 20px",

      "& thead": {
        backgroundColor: "#fff",
        "& tr th": {
          color: "var(--dark_blue)",
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: 400,
          padding: "14px 0 14px",
          letterSpacing: ".01em",
          textAlign: "center",
          "&:first-child": {
            borderRadius: "10px 0 0 10px"
          },
          "&:last-child": {
            borderRadius: "0 10px 10px 0",
            width: "330px"
          }
        },
        "& div": {
          height: "45px",
          borderRight: "3px solid var(--gray_light)",
          fontSize: "16px",
        },
        "& div.bks_001": {
          paddingLeft: "20px"
        },
        "& div.bks_002": {

        },
        "& div.bks_003": {
          "& span": {
            fontSize: "14px"
          },
          "& .bks_colorBlue": {
            color: "var(--light_blue)"
          },
          "& .bks_slash": {
            color: "#acb7c5"
          },
          "& .bks_colorGreen": {
            color: "var(--green2)"
          }
        },
        "& div.bks_004": {
          "& .bks_slash": {
            color: "#acb7c5"
          },
          "& p": {
            padding: 0,
            margin: 0,
            fontSize: "13px",
            lineHeight: "24px",
            color: "#667993"
          }
        },
        "& div.bks_005": {
          borderRight: "none"
        }
      },
      "& tbody": {
        "& tr": {
          height: "80px",
          backgroundColor: "#fff"
        },
        "& tr td": {
          padding: 0,
          "&:first-child": {
            borderRadius: "5px 0 0 5px"
          }
        },
        "& .brk_001 div": {
          textAlign: "left",
          paddingLeft: "10px",

          "& p": {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "22px",
            letterSpacing: "0.01em",
            color: "var(--dark_blue)",
            width: "200px",
            margin: 0,
          },
          "& span": {
            fontSize: "14px",
            lineHeight: "24px",
            color: "var(--gray)"
          }
        },
        "& .brk_003": {
          "& span": {
            fontSize: "14px"
          },
          "& .brk_colorBlue": {
            color: "var(--light_blue)"
          },
          "& .brk_slash": {
            color: "#acb7c5"
          },
          "& .brk_colorGreen": {
            color: "var(--green2)"
          }
        },
        "& .brk_002": {
          "& span": {
            fontSize: "14px",
            marginLeft: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px"
          },
          "& .colorOrange": {
            color: "var(--orange)"
          },
          "& .colorGreen": {
            color: "var(--green2)"
          },
          "& .colorRed": {
            color: "var(--red)"
          }
        },
        "& .brk_004": {
          "& h6": {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "0.01em",
            margin: 0
          },
          "& p": {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "11px",
            lineHeight: "22px",
            letterSpacing: "0.01em",
            margin: 0
          },
          "& .emailBox": {
            color: "var(--dark_blue)"
          },
          "& .numberBox": {
            color: "var(--purple)"
          },
          "& .contactsBox": {
            color: "var(--red)"
          }
        },
        "& .brk_005": {
          "& button": {
            background: "#fff",
            borderRadius: "5px",
            padding: "6px 10px",
            fontFamily: "var(--poppins)",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            minWidth: 95,
            marginRight: 8,
            border: "2px solid #6c6c6c",
            color: "#6c6c6c",
            cursor: "pointer"
          },
        }
      },
      "& tr": {
        borderBottom: "none"
      }
    },
  },
  dFlex: {
    display: "flex"
  },
  alignCenter: {
    alignItems: "center"
  },
  justifyCenter: {
    justifyContent: "center"
  },
  justifyAround: {
    justifyContent: "space-around"
  },
  circleButton: {
    width:"30px",
    height:"30px",
    borderRadius:"50%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    border:"1px solid var(--light_blue)",
    marginRight:"15px",
    cursor:"pointer"
  },
  circleDeleteButton : {
    width:"30px",
    height:"30px",
    borderRadius:"50%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    border: "1px solid var(--red)",
    marginRight:"15px",
    cursor:"pointer"
  },
  "@keyframes rotate": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  rotatingIcon: {
    animation: `$rotate 2s linear infinite`,
  },
}));

const Summary = ({ onCloseModal }) => {
  const history = useContext(HistoryProvider);
  const dispatch = useDispatch();
  const { spreadSheetFilesDetails } = useSelector(selectImportSpreadsheetContactState);
  const classes = useStyles();
  const [refreshing, setRefreshing] = useState(false);

  const RenderStatus = (props) => {
    let statusSVG = "";
    let text = "";
    let classText = "";

    if (props.status === SPREADSHEET_SUMMARY_STATUS.QUEUE) {
      statusSVG = (
          <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.274 10.7a7.618 7.618 0 0 1 .256-3.025c.92-3.139 3.807-5.447 7.23-5.488V.135c0-.114.143-.175.237-.106l4.222 3.103c.07.053.07.158 0 .207l-4.218 3.103c-.098.069-.236.008-.236-.106V4.288a5.527 5.527 0 0 0-5 3.387 5.453 5.453 0 0 0 .778 5.566 1.05 1.05 0 0 1-.2 1.506 1.06 1.06 0 0 1-1.457-.203A7.528 7.528 0 0 1 2.274 10.7zm12.19-3.94a5.437 5.437 0 0 1 1.204 3.146 5.49 5.49 0 0 1-.427 2.419c-.826 1.962-2.748 3.355-5 3.387v-2.048c0-.114-.142-.175-.236-.106l-4.222 3.103c-.069.053-.069.159 0 .207l4.218 3.103c.098.069.236.008.236-.106v-2.052c3.424-.037 6.315-2.345 7.231-5.488a7.693 7.693 0 0 0 .256-3.025 7.49 7.49 0 0 0-1.608-3.844 1.06 1.06 0 0 0-1.457-.203 1.054 1.054 0 0 0-.196 1.506z" fill="#F2994A"/>
          </svg>
      );
      text = "Queued";
      classText = "colorOrange";
    } else if (props.status === SPREADSHEET_SUMMARY_STATUS.PROCESSING) {
      statusSVG = (
          <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.274 10.7a7.618 7.618 0 0 1 .256-3.025c.92-3.139 3.807-5.447 7.23-5.488V.135c0-.114.143-.175.237-.106l4.222 3.103c.07.053.07.158 0 .207l-4.218 3.103c-.098.069-.236.008-.236-.106V4.288a5.527 5.527 0 0 0-5 3.387 5.453 5.453 0 0 0 .778 5.566 1.05 1.05 0 0 1-.2 1.506 1.06 1.06 0 0 1-1.457-.203A7.528 7.528 0 0 1 2.274 10.7zm12.19-3.94a5.437 5.437 0 0 1 1.204 3.146 5.49 5.49 0 0 1-.427 2.419c-.826 1.962-2.748 3.355-5 3.387v-2.048c0-.114-.142-.175-.236-.106l-4.222 3.103c-.069.053-.069.159 0 .207l4.218 3.103c.098.069.236.008.236-.106v-2.052c3.424-.037 6.315-2.345 7.231-5.488a7.693 7.693 0 0 0 .256-3.025 7.49 7.49 0 0 0-1.608-3.844 1.06 1.06 0 0 0-1.457-.203 1.054 1.054 0 0 0-.196 1.506z" fill="#F2994A"/>
          </svg>
      );
      text = "Processing";
      classText = "colorGreen";
    } else if (props.status === SPREADSHEET_SUMMARY_STATUS.PROCESSED) {
      statusSVG = (
          <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM6.6 9.602a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm7.8-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-4.4 7c2.72 0 4.066-2.4 4.4-3.6H5.6c.333 1.2 1.68 3.6 4.4 3.6z" fill="#27AE60"/>
          </svg>
      );
      text = "Processed";
      classText = "colorGreen";
    } else if (props.status === SPREADSHEET_SUMMARY_STATUS.FAILED) {
      statusSVG = (
          <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.047 2.954A9.966 9.966 0 1 0 2.954 17.047 9.967 9.967 0 1 0 17.047 2.954zm-3.844 4.91a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zm-6.406 0a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zM10 11.425c2.02 0 3.726 1.312 4.258 3.106.045.157.104.453.104.453H5.64s.058-.298.104-.453c.524-1.795 2.233-3.106 4.256-3.106z" fill="#FF264A"/>
          </svg>
      );
      text = "Failed";
      classText = "colorRed";
    } else if (props.status === SPREADSHEET_SUMMARY_STATUS.DISCONNECTED) {
      statusSVG = (
          <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.047 2.954A9.966 9.966 0 1 0 2.954 17.047 9.967 9.967 0 1 0 17.047 2.954zm-3.844 4.91a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zm-6.406 0a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zM10 11.425c2.02 0 3.726 1.312 4.258 3.106.045.157.104.453.104.453H5.64s.058-.298.104-.453c.524-1.795 2.233-3.106 4.256-3.106z" fill="#FF264A"/>
          </svg>
      );
      text = "Disconnected";
      classText = "colorRed";
    }

    return (
        <div className={`${classes.dFlex} ${classes.justifyCenter} ${classes.alignCenter}`}>
          {statusSVG}
          <span className={classText}>{text}</span>
        </div>
    );
  };

  const refreshFile = async () => {
    try{
      setRefreshing(true);
      const resp = await importSpreadsheetContactService.singleImportFile({ userConnectedFileId: spreadSheetFilesDetails.id });

      if (resp && resp.success){
        dispatch(loadSpreadSheetFileDetails(resp.data));
      }
    }catch (err){
      console.log(err);
    }finally {
      setRefreshing(false);
    }
  };

  const handleViewPastClick = () => {
    history.push("/import-spreadsheet-contact-summary");
  };

  return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <img
                src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/upload_4052950.svg`}
                alt=""
                height={40}
            />

            <Typography variant={"h5"} color="textPrimary" style={{fontWeight: 600, height: "unset !important"}}>
              Your file has been uploaded. Here are the results!
            </Typography>
          </div>

          <div className={classes.headerRight}>
            <Button
                variant="contained"
                color="primary"
                disableElevation
                startIcon={<RefreshIcon className={refreshing ? classes.rotatingIcon : ""}/>}
                onClick={refreshFile}
            >
              Refresh Results
            </Button>
          </div>
        </div>

        <div className={classes.tableSection}>
          <div className={classes.listSection}>
            <div className={classes.listTable}>
              <table>
                <thead>
                <tr>
                  <th>
                    <div className={`${classes.dFlex} ${classes.alignCenter} bks_001`}>File Name</div>
                  </th>
                  <th>
                    <div
                        className={`${classes.dFlex} ${classes.alignCenter} ${classes.justifyCenter} bks_002`}>Status
                    </div>
                  </th>
                  <th>
                    <div className={`${classes.dFlex} ${classes.alignCenter} ${classes.justifyCenter} bks_003`}>
                      <span className="bks_colorBlue">Total</span>
                      <span className="bks_slash">/</span>
                      <span className="bks_colorGreen">Imported</span>
                    </div>
                  </th>
                  <th>
                    <div className="bks_004">
                      Import Errors<br/>
                      <p>Duplicate Email <span className="bks_slash">/</span>Duplicate Number <span
                          className="bks_slash">/</span> Invalid Contact</p>
                    </div>
                  </th>
                  <th>
                    <div
                        className={`${classes.dFlex} ${classes.alignCenter} ${classes.justifyCenter} bks_005`}>Action
                    </div>
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr>
                  <td className="brk_001">
                    <div className={`${classes.dFlex} ${classes.alignCenter}`}>
                      <div>
                        <BootstrapTooltip title={spreadSheetFilesDetails.provider_file_name} arrow placement="top">
                          <p className="truncate">{spreadSheetFilesDetails.provider_file_name}</p>
                        </BootstrapTooltip>

                        <span>
                            {window.globalTimezoneConversionToDifferentTimezone(
                                spreadSheetFilesDetails?.createdAt,
                                "UTC",
                                userTimezone,
                                "MMM D, YYYY h:mm A",
                                false
                            )}
                          </span>
                      </div>
                    </div>
                  </td>
                  <td className="brk_002">
                    <RenderStatus status={spreadSheetFilesDetails?.processing_status}/>
                  </td>
                  <td className="brk_003">
                    <div className={`${classes.dFlex} ${classes.alignCenter} ${classes.justifyCenter}`}>
                      <div>
                         <span className="brk_colorBlue">{spreadSheetFilesDetails?.more_informations?.totalContact || 0}</span>
                        <span className="brk_slash">/</span>
                        <span className="brk_colorGreen">{spreadSheetFilesDetails?.more_informations?.totalContactUploaded || 0}</span>
                      </div>
                    </div>
                  </td>
                  <td className="brk_004">
                    <div className={`${classes.dFlex} ${classes.justifyAround} ${classes.alignCenter}`}>
                      <div className="emailBox">
                        <h6>{spreadSheetFilesDetails?.more_informations?.emailDuplicateCount || 0}</h6>
                        <p>Email</p>
                      </div>
                      <div className="numberBox">
                        <h6>{spreadSheetFilesDetails?.more_informations?.numberDuplicateCount || 0}</h6>
                        <p>Number</p>
                      </div>
                      <div className="contactsBox">
                        <h6>{spreadSheetFilesDetails?.more_informations?.invalidCount || 0}</h6>
                        <p>Contact</p>
                      </div>
                    </div>
                  </td>
                  <td className="brk_005">
                    <div className={`${classes.dFlex} ${classes.justifyCenter} ${classes.alignCenter}`}>
                      <button onClick={() => {
                        history.push(`/import-spreadsheet-contact-details?userConnectedFileId=${spreadSheetFilesDetails.id}`);
                      }}>
                        View Log
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className={classes.footer}>
          <Button className={classes.viewReportButton} disableElevation onClick={handleViewPastClick}>
            View Past Imports
          </Button>
          <Button variant="contained" color="primary" disableElevation onClick={onCloseModal}
                  style={{minWidth: 120}}>
            Exit
          </Button>
        </div>
      </div>
  );
};

export default Summary;
