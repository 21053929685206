import HttpRequest from "../../api/HttpRequest";
import importContactV2Api from "../../api/importContactV2Api";

class importContactService {
    uploadCsvFile (body) {
        return HttpRequest.multipart(importContactV2Api.uploadCsvFile, body);
    }

    loadInitialData (params) {
        return HttpRequest.get(importContactV2Api.loadInitialData, params);
    }

    loadPipelineData (params) {
        return HttpRequest.get(importContactV2Api.loadPipelineData, params);
    }

    loadStageData (params) {
        return HttpRequest.get(importContactV2Api.loadStageData, params);
    }

    loadCampaignData (params) {
        return HttpRequest.get(importContactV2Api.loadCampaignData, params);
    }

    loadCustomFieldData () {
        return HttpRequest.get(importContactV2Api.loadCustomFieldData);
    }

    uploadCsvFileData (body) {
        return HttpRequest.multipart(importContactV2Api.uploadCsvFileData, body);
    }

    singleImportFile (params) {
        return HttpRequest.get(importContactV2Api.singleImportFile, params);
    }
}

const importContactServiceInst = new importContactService();

export default importContactServiceInst;