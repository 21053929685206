import { Button, styled, Tab, Tabs, Typography } from "@material-ui/core";
import React, {useState} from "react";
import { TabLabel } from "../importContactsV2Style";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { selectImportSpreadsheetContactState } from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import { updateMappedFields } from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import FieldMapper from "../FieldMapper";
import {
  IMPORT_CONTACT_MAPPING_ALL,
  IMPORT_CONTACT_MAPPING_MAPPED,
  IMPORT_CONTACT_MAPPING_UNMAPPED
} from "../../../constants/CoreConstants";

const MappingWrapper = styled("div")(({ theme }) => ({
  "& .mappingTabs": {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
      height: 2,
      minWidth: "fit-content !important",
    },
  },
  "& .mappingTabItem": {
    minWidth: "fit-content !important",

    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },

    "&:focus": {
      backgroundColor: "transparent !important",
    },
  },
}));

export const MapFieldTable = styled("table")(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  maxHeight: 460,
  "& thead": {
    display: "table",
    width: "100%",
    tableLayout: "fixed"
  },
  "& tbody": {
    display: "block",
    height: "calc(100vh - 415px)",
    overflow: "auto",
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 transparent',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
    "& tr": {
      display: "table",
      width: "100%",
      tableLayout: "fixed"
    },

    "& td": {
      overflow: "auto",
      scrollbarWidth: 'thin',
      scrollbarColor: '#888 transparent',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
    }
  },
  "& th": {
    padding: theme.spacing(2, 0),
    "& span": {
      fontSize: 12,
    },
  },
}));

const Mapping = ({ moveToNextStep, moveToPreviousStep }) => {
  const { mappingFields, mappedFields, alreadyMappedFields, mappedRows, unmappedRows } = useSelector(selectImportSpreadsheetContactState);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(IMPORT_CONTACT_MAPPING_ALL);

  const mappedFieldUpdate = (value, index) => {
    let mf = { ...mappedFields };

    let prevValue = mf[index];
    mf[index] = value;

    let am = [...alreadyMappedFields];

    const filterUnmapped = unmappedRows.filter((item) => item.index !== index);
    const filterMapped = unmappedRows.find((item) => item.index === index);

    let tempMappedRows = [...mappedRows];

    if (filterMapped){
      tempMappedRows.push(filterMapped);
    }

    if (prevValue) {
      let i = alreadyMappedFields.findIndex((x) => x === prevValue);

      if (i >= 0) {
        am.splice(i, 1);
        dispatch(updateMappedFields({ mappedFields: mf, alreadyMappedFields: [...am, value], mappedRows: tempMappedRows, unmappedRows: filterUnmapped }));
      } else {
        dispatch(updateMappedFields({ mappedFields: mf, alreadyMappedFields: [...am, value], mappedRows: tempMappedRows, unmappedRows: filterUnmapped }));
      }
    } else {
      dispatch(updateMappedFields({ mappedFields: mf, alreadyMappedFields: [...am, value], mappedRows: tempMappedRows, unmappedRows: filterUnmapped }));
    }
  }

  const handleChange = (event, newValue) => {
      setActiveTab(newValue);
  };

  const handleMoveToNextStep = () => {
    let alreadyMappedFields = Object.values(mappedFields);
    if (!alreadyMappedFields.includes('email') && !alreadyMappedFields.includes('number')) {
      window.showNotification('ERROR', 'Email or Phone must be selected');
      return;
    }

    moveToNextStep();
  };

  const filteredMappedFields = (i) => {
    return mappingFields.map((item) => {
      if(item.options){
        const filteredOptions = item.options.filter(option => (!alreadyMappedFields.includes(option.value) || mappedFields[i] === option.value));
        
        if (filteredOptions.length > 0) {
          return { ...item, options: filteredOptions };
        }

        return null;
      }else {
        if ((!alreadyMappedFields.includes(item.value) || mappedFields[i] === item.value || item.value === "")) {
          return { ...item };
        }
        return null;
      }
    }).filter(Boolean);
  };

  return (
    <MappingWrapper>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant={"h5"} color={"text.primary"} style={{ fontWeight: 600, marginBottom: 16 }}>
          Mapping
        </Typography>

        <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
          <Button style={{color:"#000"}} variant={"outlined"} disableElevation onClick={moveToPreviousStep}>
            Back
          </Button>
          <Button variant='contained' color='primary' disableElevation onClick={handleMoveToNextStep}>
            Next
          </Button>
        </div>
      </div>

      <Tabs style={{ marginTop: "10px !important" }} value={activeTab} className='mappingTabs' onChange={handleChange}>
        <Tab className='mappingTabItem' label={<TabLabel title={"All"} value={mappedRows.length + unmappedRows.length} isSelected={activeTab === IMPORT_CONTACT_MAPPING_ALL}/>} />
        <Tab className='mappingTabItem' label={<TabLabel title={"Mapped"} value={mappedRows.length} isSelected={activeTab === IMPORT_CONTACT_MAPPING_MAPPED}/>} />
        <Tab className='mappingTabItem' label={<TabLabel title={"Unmapped"} value={unmappedRows.length} isSelected={activeTab === IMPORT_CONTACT_MAPPING_UNMAPPED}/>} />
      </Tabs>

      <MapFieldTable>
        <thead>
        <tr>
          <th width={"16.4%"}>
            <Typography variant={"overline"} style={{ color: "#757f8e" }}>
              mapped
            </Typography>
          </th>
          <th width={"61%"}>
            <Typography variant={"overline"} style={{ color: "#757f8e" }}>
              column data
            </Typography>
          </th>
          <th style={{ width: "280px" }}>
            <Typography variant={"overline"} style={{ color: "#757f8e" }}>
              list field
            </Typography>
          </th>
        </tr>
        </thead>

        <tbody>

        {
            (activeTab === IMPORT_CONTACT_MAPPING_ALL || activeTab === IMPORT_CONTACT_MAPPING_MAPPED) &&
            mappedRows.map((item) => {
              return <tr style={{ padding: "10px 10px" }}>
                <td style={{ padding: "10px 0px", width: "16.4%" }}>
                  <CheckCircleIcon style={{ color: "#008951" }} />
                </td>
                <td style={{ padding: "10px", width: "61%" }}>
                  <div>
                    {
                      item.fields.map((field)=>(
                          <Typography style={{ fontWeight: 600 }}>
                            {`${field}`}
                          </Typography>
                      ))
                    }
                  </div>
                </td>
                <td style={{ padding: "10px" }}>
                  <FieldMapper
                      value={mappedFields[item.index] ?? ""}
                      options={filteredMappedFields(item.index)}
                      onChange={(event) => mappedFieldUpdate(event.target.value, item.index)}
                  />
                </td>
              </tr>
            })
        }

        {
            (activeTab === IMPORT_CONTACT_MAPPING_ALL || activeTab === IMPORT_CONTACT_MAPPING_UNMAPPED) &&
            unmappedRows.map((item) => {
              return <tr style={{ padding: "10px 10px" }}>
                <td style={{ padding: "10px 0px", width: "16.4%" }}>
                  <InfoOutlinedIcon style={{ color: "#ffa500" }} />
                </td>
                <td style={{ padding: "10px", width: "61%" }}>
                  <div>
                    {
                      item.fields.map((field)=>(
                          <Typography style={{ fontWeight: 600 }}>
                            {`${field}`}
                          </Typography>
                      ))
                    }
                  </div>
                </td>
                <td style={{ padding: "10px" }}>
                  <FieldMapper
                      value={mappedFields[item.index] ?? ""}
                      options={filteredMappedFields(item.index)}
                      onChange={(event) => mappedFieldUpdate(event.target.value, item.index)}
                  />
                </td>
              </tr>
            })
        }
        </tbody>
      </MapFieldTable>
    </MappingWrapper >
  )
};

export default Mapping;
