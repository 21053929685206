import React, {useState} from "react";
import { Button, Chip, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {SPREADSHEET_STATUS_COLORS} from "../../constants/CoreConstants";
import Utils from "../../helpers/Utils";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import ImportSpreadsheetModal from "../importContactsV2/ImportGoogleSheet/ImportSpreadSheetModal";
import importSpreadsheetContactService from "../../reducers/importSpreadsheetContact/importSpreadsheetContactService";
import mapCSVImportHelper from "../importContactsV2/mapCSVImportHelper";
import {
  loadInitialDataStart,
  updateDetails,
  updateMappedFields
} from "../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import {useDispatch} from "react-redux";
const userTimezone = Utils.getAccountData("userTimezoneRaw");

const useStyles = makeStyles((theme) => ({
  tableCellBody: {
    border: "1px solid #ddd",
    fontWeight: 500,
    fontSize: "12px",
    padding: "10px 20px",
  },
  tableCellActionBody: {
    border: "1px solid #ddd",
    padding: "10px 20px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    gap: 5,
  },
  customButton: {
    borderRadius: 20,
    borderColor: "#346FEF",
    padding: "5px 10px",
    lineHeight: " 1px",
    height: "25px",
    "&:hover": {
      borderColor: "#346FEF",
      backgroundColor: "white",
    },
    "&:focus": {
      borderColor: "#346FEF",
      backgroundColor: "white",
    },
  },
  customIconButton: {
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      backgroundColor: "white",
    },
  },
  stickyFirstColumn: {
    position: "sticky",
    left: 0,
    backgroundColor: "#ffffff",
    border: "1px solid #ddd",
    fontWeight: 500,
    fontSize: "16px",
    padding: "10px 20px",
    textTransform: "uppercase",
    "& .titleWrapper": {
      maxWidth: 290,
      fontSize: 13,
      fontWeight: 600,
      color: "#133159",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      cursor: "pointer"
    },
    "& .dateWrapper": { color: "#546376", fontSize: 12 },
  },
  chip: (props) => ({
    backgroundColor: props.color,
    color: "#fff",
    height: "25px",
    textTransform: "capitalize",
  }),
}));

const SummaryTableItem = ({ item, refreshPage, handleAssignCampaign, handleDelete, handleSettingDetails }) => {
  const dispatch = useDispatch();
  const [openImportSpreadsheetModal, setOpenImportSpreadsheetModal] = useState(false);
  const history = useHistory();
  const color = SPREADSHEET_STATUS_COLORS[item?.processing_status] || "#000";
  const classes = useStyles({ color });
  const [resyncing, setResyncing] = useState(false);

  const renderStatusChip = () => {
    return <Chip size="small" label={item?.processing_status?.toLowerCase()} className={classes.chip} />;
  };

  const worksheetName = () => {
    if (item.worksheet_info) {
      const parsedData = JSON.parse(item.worksheet_info);
      return parsedData.title;
    } else {
      return "";
    }
  };

  const getMappedFields = () => {
    const transformed = {};

    if (item.mapping) {
      const parsedData = JSON.parse(item.mapping);

      Object.entries(parsedData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((pin) => {
            transformed[pin] = key;
          });
        } else {
          transformed[value] = key;
        }
      });
    }

    if (item.custom_mapping) {
      const parsedData = JSON.parse(item.custom_mapping);
      Object.entries(parsedData).forEach(([key, value]) => {
        transformed[value] = `custom_${key}`;
      });
    }

    return transformed;
  };

  const handleResync = async () => {
    setResyncing(true);
    try {
      const mappedField = getMappedFields();

      dispatch(loadInitialDataStart({ userId: Utils.getAccountData("userId") }));

      const reFreshTokenResponse = await importSpreadsheetContactService.processRefreshToken({ email: item.email });
      if (reFreshTokenResponse.success) {
        const resyncResponse = await importSpreadsheetContactService.readSpreadsheet({
          email: item.email,
          spreadsheetId: item.provider_file_id,
          worksheetName: worksheetName(),
        });

        if (resyncResponse.success) {
          const { mappedRows, unmappedRows } = mapCSVImportHelper.mapData(resyncResponse.data);

          let mappedData = [...mappedRows];
          const mappedFieldsSet = new Set(Object.keys(mappedField).map(Number));

          const unmappedData = unmappedRows.filter((unmappedRow) => {
            if (mappedFieldsSet.has(unmappedRow.index)) {
              mappedData.push(unmappedRow);
              return false;
            }
            return true;
          });

          dispatch(
              updateMappedFields({
                mappedRows: mappedData,
                unmappedRows: unmappedData,
                mappedFields: mappedField,
                alreadyMappedFields: Object.values(mappedField)
              })
          );
          setOpenImportSpreadsheetModal(true);
          const additionalInfo = JSON.parse(item.additional_infos);

          dispatch(
              updateDetails({
                leadSourceId: additionalInfo.source_id ? additionalInfo.source_id : "",
                pipelineId: additionalInfo.pipeline_id ? additionalInfo.pipeline_id : "",
                stageId: additionalInfo.stage_id ? additionalInfo.stage_id : "",
                tagIds: additionalInfo.tag_ids?.[0] ? additionalInfo.tag_ids : [""],
                ignoreFirstRow: additionalInfo.ignore_first_row,
                userConsent: additionalInfo.user_consent,
                spreadSheetName: item.provider_file_name ? item.provider_file_name : "",
                spreadSheetId: item.id,
              })
          );
        } else {
          window.showNotification("error", resyncResponse.message);
        }
      } else {
        window.showNotification("error", reFreshTokenResponse.message);
      }
    } catch (error) {
      console.log(error);
      window.showNotification("error", "Something went wrong! Please try again.");
    }finally {
      setResyncing(false);
    }
  };

  return (
    <TableRow key={item.id}>
      <TableCell className={`${classes.stickyFirstColumn}`} style={{ minWidth: 300, zIndex: 1 }}>
        <div className='titleWrapper' onClick={() => history.push(`/import-spreadsheet-contact-details?userConnectedFileId=${item.id}`)}>
          {item?.provider_file_name}
        </div>

        <div className='dateWrapper'>
          {window.globalTimezoneConversionToDifferentTimezone(
            item.created_at,
            "UTC",
            userTimezone,
            "MMM D, YYYY h:mm A",
            false
          )}
        </div>
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {renderStatusChip()}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item?.more_informations?.totalContact || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item?.more_informations?.totalContactUploaded || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item?.more_informations?.totalContactUpdated || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item?.more_informations?.relatedContact || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item?.more_informations?.emailDuplicateCount || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item?.more_informations?.numberDuplicateCount || 0}
      </TableCell>
      <TableCell className={classes.tableCellBody} align='center'>
        {item?.more_informations?.invalidCount || 0}
      </TableCell>
      <TableCell className={classes.tableCellActionBody}>
        <div className={classes.actionContainer}>
          <Button
            className={classes.customButton}
            variant='outlined'
            size='small'
            onClick={() => history.push(`/import-spreadsheet-contact-details?userConnectedFileId=${item.id}`)}
          >
            <span style={{ color: "#346FEF" }}>View Log</span>
          </Button>

          <Button
              className={classes.customButton}
              variant='outlined'
              color='primary'
              size='small'
              onClick={() => handleSettingDetails(item.id)}
          >
            <span style={{ color: "#346FEF" }}>Settings Details</span>
          </Button>

          <Button
              className={classes.customButton}
              variant='outlined'
              color='primary'
              size='small'
              onClick={handleResync}
              disabled={resyncing}
          >
            <span style={{ color: "#346FEF" }}>{ !resyncing ? "Sync" : "Syncing" }</span>
          </Button>

          {/*<BootstrapTooltip title='Assign To Campaign' placement='top' arrow>*/}
          {/*  <IconButton*/}
          {/*    className={classes.customIconButton}*/}
          {/*    variant='outlined'*/}
          {/*    size='small'*/}
          {/*    onClick={() => handleAssignCampaign(item.id)}*/}
          {/*  >*/}
          {/*    {icons.add}*/}
          {/*  </IconButton>*/}
          {/*</BootstrapTooltip>*/}

          {/*<BootstrapTooltip title='Delete File' placement='top' arrow>*/}
          {/*  <IconButton*/}
          {/*    className={classes.customIconButton}*/}
          {/*    variant='outlined'*/}
          {/*    size='small'*/}
          {/*    onClick={() => handleDelete(item)}*/}
          {/*  >*/}
          {/*    {icons.delete}*/}
          {/*  </IconButton>*/}
          {/*</BootstrapTooltip>*/}

          {openImportSpreadsheetModal && (
              <ImportSpreadsheetModal
                  open={openImportSpreadsheetModal}
                  onClose={() => setOpenImportSpreadsheetModal(false)}
              />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SummaryTableItem;
